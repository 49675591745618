<mat-card>
  <mat-card-content>

    <ct-form [configuration]="configuration"></ct-form>

    <div fxLayout="row wrap" fxLayoutAlign="left">

      <div *ngIf="dataSource?.CreatedBy" style="padding: 0 0.5rem;">
        Creato da: <strong>{{dataSource?.CreatedBy.Name}} {{dataSource?.CreatedBy.Surname}}</strong>
      </div>


      <div *ngIf="dataSource?.UpdatedBy" style="padding: 0 3rem;">
        Aggiornato da: <strong>{{dataSource?.UpdatedBy .Name}} {{dataSource?.UpdatedBy.Surname}}</strong>
      </div>

    </div>

    <mat-card-actions fxLayout="row wrap" fxLayoutAlign="space-between">

      <ct-button [configuration]="cancelButton"></ct-button>

      <ct-button [configuration]="submitButton"></ct-button>

    </mat-card-actions>

  </mat-card-content>

</mat-card>
