import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {CatalogEditComponent} from "./catalog-edit/catalog-edit.component";
import {CatalogDetailComponent} from "./catalog-detail/catalog-detail.component";
import {CatalogListComponent} from "./catalog-list/catalog-list.component";
import {CatalogService} from "./catalog.service";
import {CatalogListModule} from "./catalog-list/catalog-list.module";
import {CatalogEditModule} from "./catalog-edit/catalog-edit.module";
import {CatalogDetailModule} from "./catalog-detail/catalog-detail.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CatalogListModule,
    CatalogEditModule,
    CatalogDetailModule,
    RouterModule.forChild([
      {
        path: ":type",
        children: [
          {
            path: "",
            redirectTo: "list",
            pathMatch: "full"
          },
          {
            path: "create",
            component: CatalogEditComponent
          },
          {
            path: "edit/:id",
            component: CatalogEditComponent
          },
          {
            path: "detail/:id",
            component: CatalogDetailComponent
          },
          {
            path: "list",
            component: CatalogListComponent
          }
        ]
      }

    ])
  ],
  providers: [CatalogService]
})
export class CatalogModule {
}
