import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompanyContractComponent} from './company-contract.component';
import {CtModelModule} from "@ctsolution/ct-framework";
import {TranslateModule} from "@ngx-translate/core";
import { WorkOrderService } from '../../work-order/work-order.service';

@NgModule({
  declarations: [
    CompanyContractComponent
  ],
  exports: [
    CompanyContractComponent
  ],
  imports: [
    CommonModule,
    CtModelModule,
    TranslateModule
  ],
  providers: [WorkOrderService]
})
export class CompanyContractModule {
}
