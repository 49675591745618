<ng-container [ngSwitch]="configuration?.buttonType">

  <ng-container *ngSwitchCase="'mat-mini-fab'">

    <button
      [matMenuTriggerFor]="menu"
      mat-mini-fab
      [color]="configuration?.color">
      <mat-icon> {{ configuration?.icon }}</mat-icon>
    </button>

  </ng-container>

  <ng-container *ngSwitchDefault>

    <button
      [matMenuTriggerFor]="menu"
      mat-icon-button
      [color]="configuration.color">
      <mat-icon> {{ configuration.icon }}</mat-icon>
    </button>

  </ng-container>

</ng-container>

<mat-menu #menu="matMenu">
  <button mat-menu-item *ngFor="let lang of configuration?.languages" (click)="onDownload.emit(lang.code)">
    <i class="flag-icon flag-icon-{{ lang.icon }} m-r-10"></i>
    <span>{{ lang.language }}</span>
  </button>
</mat-menu>
