import { Injectable } from "@angular/core";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import { NoteDialogComponent } from "./note-dialog.component";
import { NoteDialogConfiguration } from "../note-dialog.configuration";

@Injectable()

  export class NoteDialogService{

    constructor(private dialog: MatDialog) {
    }
  
    open = (data: NoteDialogConfiguration) => this.dialog
      .open(NoteDialogComponent, {
        data,
        panelClass: "note-dialog-container",
      });
  
  }
    