import {Injectable} from "@angular/core";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {ModuleAddItemComponent} from "./module-add-item.component";
import {ItemHandling} from "../../../../../_core/classes/item-handling";

@Injectable()
export class ModuleAddItemService {
  constructor(private dialog: MatDialog) {
  }

  open = (data: ItemHandling) => this.dialog.open(ModuleAddItemComponent, {
    data,
    minWidth: 500,
    panelClass: "module-add-item-container"
  });

}
