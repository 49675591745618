<div class="blank-container">
  <div class="blank-container-box">
    <mat-card>
      <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="text-center">
            <img alt="homepage" src="assets/images/logo/logo.png" height="55"/>
            <h4 class="m-t-0">  {{ "AUTHENTICATION.enter" | translate:{projectName: viewModel.projectName} }}</h4>
          </div>

          <div fxLayout="row wrap">
            <!-- col full-->
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-form-field>
                <input matInput placeholder="Email" [formControl]="form?.get('Email')"/>
              </mat-form-field>
              <small
                *ngIf="form?.get('Email')?.hasError('required') && form?.get('Email')?.touched"
                class="text-danger support-text">* {{ 'CT_ERROR.required' | translate }}</small>
            </div>
            <!-- col full-->
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" class="password-cntr">
              <mat-form-field>
                <input
                  matInput
                  id="passwordControl"
                  type="Password"
                  placeholder="Password"
                  [formControl]="form?.get('Password')"/>
                <ct-toggle-password-visibility-button
                  matSuffix
                  [configuration]="passwordControlVisibilityConfiguration"></ct-toggle-password-visibility-button>
              </mat-form-field>
              <small *ngIf="form?.get('Password')?.hasError('required') && form?.get('Password')?.touched"
                     class="text-danger support-text">* {{ 'CT_ERROR.required' | translate }}</small>
            </div>
            <!-- col half-->
            <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex.xs="100">
              <mat-checkbox color="warn"
                            formControlName="RememberMe"> {{ "AUTHENTICATION.remember-me" | translate}}</mat-checkbox>
            </div>
            <!-- col half-->
            <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex.xs="100" class="text-right">
              <a [routerLink]="['/authentication/forgot']" class="link"
              > {{ "AUTHENTICATION.password-forgotten?" | translate}}</a
              >
            </div>
            <!-- col full-->
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <button
                mat-raised-button
                color="primary"
                class="btn-block btn-lg m-t-20 m-b-20"
                type="submit"
                [disabled]="!form.valid"
              >
                {{"AUTHENTICATION.login" | translate}}
              </button>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
