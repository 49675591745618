import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MovementManagerAddItemComponent} from './movement-manager-add-item/movement-manager-add-item.component';
import {
  MovementManagerSearchItemComponent
} from './movement-manager-search-item/movement-manager-search-item.component';
import {MatLegacyDialogModule} from "@angular/material/legacy-dialog";
import {CtButtonModule, CtFormModule} from "@ctsolution/ct-framework";
import {TranslateModule} from "@ngx-translate/core";
import {
  ItemSelectionModule
} from "../../../../../handling/order-handling-wizard/order-handling-wizard-step2/item-selection/item-selection.module";
import {FlexModule} from "@angular/flex-layout";
import {MovementManagerAddItemService} from "./movement-manager-add-item.service";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  declarations: [
    MovementManagerAddItemComponent,
    MovementManagerSearchItemComponent
  ],
  imports: [
    CommonModule,
    MatLegacyDialogModule,
    CtButtonModule,
    TranslateModule,
    ItemSelectionModule,
    FlexModule,
    CtFormModule,
    MatButtonModule,
    MatIconModule
  ],
  providers: [MovementManagerAddItemService]
})
export class MovementManagerAddItemModule {
}
