import {Component, Input} from '@angular/core';
import {
  CtButtonConfiguration, CtButtonRouterLink,
  CtModelConfiguration,
  CtModelDatatableOperators, DeleteConfirmService, MAT_RAISED_PRIMARY, SnackbarService
} from "@ctsolution/ct-framework";
import {CtWebapiGenericResponse, CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";
import {CTManagerLocation} from "../../../../_core/classes/ct-manager-location";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-temporary-area-list',
  templateUrl: './temporary-area-list.component.html',
  styleUrls: ['./temporary-area-list.component.scss']
})
export class TemporaryAreaListComponent {

  @Input() configuration: CtModelConfiguration<CTManagerLocation> | null = null;

  list: Array<CTManagerLocation> = [];

  loading = true;
  automaticRefreshIsToggled = true;
  intervalId: any;

  createRouterLink: CtButtonRouterLink = CtButtonRouterLink
    .create()
    .setRouterLink(['..', 'create']);

  createButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("newSortingAreaLabel")
    .setRouterLinkConfiguration(this.createRouterLink)
    .setMatherialOptions(MAT_RAISED_PRIMARY)
    .setIcon('add')

  constructor(
    private _webapi: CtWebapiService,
    private route: ActivatedRoute,
    private router: Router,
    private _snackbar: SnackbarService,
    private deleteConfirm: DeleteConfirmService) {
  }

  ngAfterViewInit() {

    this.fetchData();

    this.startInterval();

  }

  toggleSlideToggle() {

    if (this.automaticRefreshIsToggled) {

      this.startInterval();

    } else {

      this.stopInterval();

    }

  }

  startInterval() {

    this.intervalId = setInterval(() => this.fetchData(), 15000);

  }

  stopInterval() {

    clearInterval(this.intervalId);

  }

  edit(element: any) {

    if (this.configuration?.onEdit) {

      this.configuration?.onEdit(element);
      return;

    }

    const key: string | null = this.configuration?.getCTFieldInfosDTOKey() ?? null;

    if (!key) return;

    this.router.navigate(['..', 'edit', element[key]], {relativeTo: this.route});

  }

  detail(element: any) {

    if (this.configuration?.onDetail) {

      this.configuration.onDetail(element);
      return;

    }

    const key: string | null = this.configuration?.getCTFieldInfosDTOKey() ?? null;

    if (!key) return;

    this.router.navigate(['..', 'detail', element[key]], {relativeTo: this.route});


  }

  delete(element: any) {

    const key: string | null = this.configuration?.getCTFieldInfosDTOKey() ?? null;

    if (!key) return;

    this.deleteConfirm
      .open()
      .afterClosed()
      .subscribe((result?: boolean) => {

        if (result) {

          const controller: string | undefined | null = this.configuration?.RouteData?.controller;

          if (!controller) return;

          const request: DataRequest = new DataRequest()
            .setController([controller, 'Delete'])
            .setAction(element[key]);

          this._webapi
            .delete(request)
            .subscribe(() => {

              this.fetchData();
              this._snackbar.open('CT_MESSAGES.success_operation')

            });

        }

      })


  }

  fetchData() {

    if (!this.configuration) {

      this.loading = false;
      return;

    }

    const body: CtModelDatatableOperators = CtModelDatatableOperators.create();

    if (this.configuration.filterValues) {

      if (!body.Filters) body.setFilters([]);

      body
        .setFilters([...body.Filters, ...this.configuration.filterValues.constants]);

    }

    const request: DataRequest = new DataRequest()
      .setController([this.configuration.RouteData?.controller!])
      .setAction('List')
      .setQueryParams({hideSpinner: true})
      .setBody(body)

    this._webapi
      .post<CtModelConfiguration<CTManagerLocation>>(request)
      .subscribe({
        next: (result: CtWebapiGenericResponse<CtModelConfiguration<CTManagerLocation>>) => {

          this.list = result.Result.DataSource as Array<CTManagerLocation>;
          this.loading = false;

        },
        error: () => this.loading = false
      });

  }

  ngOnDestroy() {

    this.stopInterval();

  }

}
