import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {CommonModule} from "@angular/common";
import {PagesRoutes} from "./pages.routing";
import {DashboardModule} from "./dashboard/dashboard.module";

@NgModule({
  imports: [
    CommonModule,
    DashboardModule,
    RouterModule.forChild(PagesRoutes)
  ],
  declarations: []
})
export class PagesModule {
}
