import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeadInformationComponent } from './lead-information.component';
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import { CtButtonModule } from '@ctsolution/ct-framework';
import {FlexModule} from "@angular/flex-layout";


@NgModule({
    imports: [
        CommonModule,
        MatCardModule,
        CtButtonModule,
        FlexModule
    ],
  declarations: [LeadInformationComponent],
  exports : [LeadInformationComponent]
})
export class LeadInformationModule { }
