<mat-card>

  <mat-card-content [formGroup]="form" style="padding-top: 0px; padding-bottom: 0px;">

    <mat-radio-group class="movement-manager-insert-mode-container"
                     formControlName="insertMode">

    <ng-container *ngFor="let mode of getEnumKeys()">

      <div [fxFlex]="fxFlexValue" fxFlex.sm="100" class="p-10" style="width: 100%;"
           *ngIf="insertModeDetailsMap[insertModes[mode]]?.visible">

        <button
          mat-raised-button
          (click)="control.setValue(insertModes[mode])"
          >
          <button mat-mini-fab color="primary">
            <mat-icon>{{ insertModeDetailsMap[insertModes[mode]]?.icon ?? '' }}</mat-icon>
          </button>
          {{ insertModeDetailsMap[insertModes[mode]]?.description ?? '' | translate }}
        </button>

      </div>
    </ng-container>
    </mat-radio-group>

  </mat-card-content>

</mat-card>
