import { Injectable } from "@angular/core";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { UserInfoWorkDataEditComponent } from "./user-info-work-data-edit/user-info-work-data-edit.component";
import { UserInfoWorkDataEditConfiguration } from "./user-info-work-data-edit/user-info-work-data-edit.configuration";

@Injectable()
export class UserInfoWorkDataService{


    constructor( private dialog: MatLegacyDialog){}

    openForm(param: UserInfoWorkDataEditConfiguration | null = null): Promise<void> {
        return new Promise((resolve, reject) => {
          const dialogRef = this.dialog.open(UserInfoWorkDataEditComponent, {
            data: param
          });
    
          dialogRef.afterClosed().subscribe(result => {
            resolve();
          }, error => {
            reject(error);
          });
        });
      }
    
}