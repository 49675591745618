import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGeneratorDataEditComponent } from './form-generator-data-edit.component';
import { CtButtonModule, CtFormModule, CtModelModule } from '@ctsolution/ct-framework';
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import { FlexModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    CtModelModule,
    CtFormModule,
    MatCardModule,
    CtButtonModule,
    FlexModule,
    TranslateModule
  ],
  declarations: [FormGeneratorDataEditComponent],
  exports: [FormGeneratorDataEditComponent]
})
export class FormGeneratorDataEditModule { }
