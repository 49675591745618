import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompanyContactComponent} from './company-contact.component';
import {CtModelModule} from "@ctsolution/ct-framework";
import {MatDividerModule} from "@angular/material/divider";
import {TranslateModule} from "@ngx-translate/core";
import {CompanyContactRelationModule} from "./company-contact-relation/company-contact-relation.module";

@NgModule({
  declarations: [
    CompanyContactComponent
  ],
  exports: [
    CompanyContactComponent
  ],
  imports: [
    CommonModule,
    CtModelModule,
    MatDividerModule,
    TranslateModule,
    CompanyContactRelationModule
  ]
})
export class CompanyContactModule {
}
