import {ChangeDetectorRef, Component} from '@angular/core';
import {
  CtControlConfiguration,
  CtControlTypes,
  CtControlValidator,
  CtRangeControlOptions,
  CtThemeTypes
} from "@ctsolution/ct-framework";

@Component({
  selector: 'bs-year-control',
  template: `
    <ct-control [configuration]="control"></ct-control>`
})
export class YearControlComponent {

  control: CtControlConfiguration = CtControlConfiguration
    .create()
    .setLabel('year')
    .setName('Year')
    .setTheme(CtThemeTypes.MATERIAL)
    .setType(CtControlTypes.COUNTER)
    .setOptions(
      CtRangeControlOptions
        .create()
        .setStep(1)
    )
    .setValidators([
      CtControlValidator.create({name: "required"} as CtControlValidator),
      CtControlValidator.create({name: "min", value: 1970} as CtControlValidator)
    ]);

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {

    this.setup();

  }

  setup() {

    this.cdr.detectChanges();

  }

}
