export class CatalogCloneItemConfiguration{

  get codeValue(): string | null {
    return this._codeValue;
  }

  private set codeValue(value: string | null) {
    this._codeValue = value;
  }

  get title(): string | null {
    return this._title;
  }

  private set title(value: string | null) {
    this._title = value;
  }

  get itemOid(): number | null {
    return this._itemOid;
  }

  private set itemOid(value: number | null) {
    this._itemOid = value;
  }

  get content(): string | null {
    return this._content;
  }

  private set content(value: string | null) {
    this._content = value;
  }

  get submitLabel(): string | null {
    return this._submitLabel;
  }

  private set submitLabel(value : string | null){
    this._submitLabel = value;
  }

  get cancelLabel(): string | null {
    return this._cancelLabel;
  }

  private set cancelLabel(value : string | null){
    this._cancelLabel = value;
  }


  private _cancelLabel : string | null = null;
  private _submitLabel : string | null = null;
  private _content : string | null = null;
  private _codeValue : string | null = null;
  private _itemOid : number | null = null;
  private _title : string | null = null;


  constructor(){}

  public static create = () : CatalogCloneItemConfiguration => new CatalogCloneItemConfiguration();

  setCodeValue(value : string) : CatalogCloneItemConfiguration {

    this.codeValue = value;
    return this;

  }

  setTitle(value : string) : CatalogCloneItemConfiguration {

    this.title = value;
    return this;

  }

  setContent(value : string) : CatalogCloneItemConfiguration {

    this.content = value;
    return this;

  }

  setSubmitLabel(value : string) : CatalogCloneItemConfiguration{

    this.submitLabel = value;
    return this;

  }


  setCancelLabel(value : string) : CatalogCloneItemConfiguration{

    this.cancelLabel = value;
    return this;

  }

  setItemOid(value : number) : CatalogCloneItemConfiguration{

    this.itemOid = value;
    return this;

  }

}
