import { CTBase } from "@ctsolution/ct-base";
import { StatusFormModuleData } from "../enum/status-form-module-data";

export class FormModuleData extends CTBase<FormModuleData>{

  Oid : number | null = null;
  JSONContent : string | null = null;
  Status : StatusFormModuleData | null = null;
  FormTemplate : { Oid: number | null} | null = null;
  CreatedBy : { Oid: number | null} | null = null;
  UpdatedBy  : { Oid: number | null} | null = null;
  FormModuleTemplate : { Oid: number | null} | null = null;
  Files : any = null;

  private constructor();
  private constructor(model?: FormModuleData);
  private constructor(model?: FormModuleData) {

    super();

    if (model) this.getClass(model);

  }

  public static create = (model?: FormModuleData) => new FormModuleData(model);

  setJSONContent(value: string | null): FormModuleData {

    this.JSONContent = value;
    return this;

  }

  setStatus(value : StatusFormModuleData | null): FormModuleData {

    this.Status = value;
    return this;

  }

  setFormTemplate(value : { Oid: number | null} | null): FormModuleData {

    this.FormTemplate = value;
    return this;

  }

  setCreatedBy(value : { Oid: number | null} | null): FormModuleData {

    this.CreatedBy = value;
    return this;

  }

  setUpdatedBy (value : { Oid: number | null} | null): FormModuleData {

    this.UpdatedBy  = value;
    return this;

  }

  setFormModuleTemplate(value : { Oid: number | null} | null): FormModuleData {

    this.FormModuleTemplate = value;
    return this;

  }

  setFiles(value : any){

    this.Files = value;
    return this;

  }

}

