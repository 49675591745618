import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferEditComponent } from './offer-edit.component';
import { CtModelModule } from '@ctsolution/ct-framework';
import { OfferFeeModule } from './offer-fee/offer-fee.module';

@NgModule({
  imports: [
    CommonModule,
    CtModelModule,
    OfferFeeModule
  ],
  declarations: [OfferEditComponent],
  exports : [OfferEditComponent]
})
export class OfferEditModule { }
