import {Injectable} from '@angular/core';
import {MatLegacyDialog} from "@angular/material/legacy-dialog";
import {
  MovementManagerSearchItemComponent
} from "./movement-manager-search-item/movement-manager-search-item.component";
import {MovementManagerAddItemComponent} from "./movement-manager-add-item/movement-manager-add-item.component";
import {ComponentType} from "@angular/cdk/overlay";
import {CTManagerItem} from "../../../../../../_core/classes/ct-manager-item";
import {MovementManagerAddItemConfiguration} from "./movement-manager-add-item/movement-manager-add-item.configuration";
import {CTWorkOrderRowUnionType} from "../../../../../../_core/classes/ct-work-order.row-union";
import {MovementManagerConfiguration} from "../movement-manager.configuration";
import {CtWorkOrderRowItem} from "../../../../../../_core/classes/ct-work-order.row-item";

@Injectable()
export class MovementManagerAddItemService {

  constructor(private dialog: MatLegacyDialog) {
  }

  openDialogAndAddItem<TResult>(component: ComponentType<unknown>, config = {}): Promise<TResult | null | undefined> {
    const dialogRef = this.dialog.open(component, config);

    return new Promise<TResult | null | undefined>((resolve, reject) => {
      dialogRef.afterClosed().subscribe(result => resolve(result), error => reject(error));
    });
  }

  async searchItem(configuration: MovementManagerConfiguration | null) {

    const item = await this.openDialogAndAddItem<CTManagerItem>(MovementManagerSearchItemComponent, {minWidth: 600});

    if (!item) return;

    return this.manageRow(configuration, <CtWorkOrderRowItem>{Item: item})

  }

  private openDialogAndModifyItem<T>(configuration: MovementManagerAddItemConfiguration) {
    return this.openDialogAndAddItem<T>(MovementManagerAddItemComponent, {
      minWidth: 500,
      data: configuration,
      disableClose: true
    });
  }

  manageRow(managerConfig: MovementManagerConfiguration | null, value: CTWorkOrderRowUnionType | null = null) {

    const configuration: MovementManagerAddItemConfiguration = MovementManagerAddItemConfiguration
      .create()
      .setValues(value)
      .setWorkOrderOid(managerConfig?.WorkOrder?.Oid ?? -1);

    return this.openDialogAndModifyItem<CTWorkOrderRowUnionType>(configuration);

  }

  async searchOffer(configuration: MovementManagerConfiguration | null) {

    const item = await this.openDialogAndAddItem<CTManagerItem>(MovementManagerSearchItemComponent, {minWidth: 600});

    if (!item) return;

    return this.manageRow(configuration, <CtWorkOrderRowItem>{Item: item})

  }

}
