import {Injectable} from '@angular/core';
import { CtModelService, CtAttachmentManagerService, CtAttachmentManagerConfiguration, CtModelConfiguration, CtModelDatatableOperators, CTModelDatatableFilter, CtBinaryOperator } from '@ctsolution/ct-framework';
import { WorkOrderFile } from 'projects/ct-manager/src/app/_core/classes/work-orde-file';
import { WorkOrderFileController } from 'projects/ct-manager/src/app/_core/controllers/work-order-file.controller';
import { WorkOrderFileRouteData } from 'projects/ct-manager/src/app/_core/route-data/work-order-file.route-data';


@Injectable()
export class WorkOrderAttachmentsService {

  constructor(
    private modelService: CtModelService<any>,
    private workOrderFileController: WorkOrderFileController,
    private attachmentManagerService: CtAttachmentManagerService) {
  }

  async open(workOrder: any) {

    const configuration: CtAttachmentManagerConfiguration<WorkOrderFile> = CtAttachmentManagerConfiguration
      .create<WorkOrderFile>()
      .setTitle(`Allegati commessa`)
      .setEmptyDataLabel('workOrderEmptyAttachmentLabel')
      .setNoteLabel('itemAttachmentNoteLabel')
      .enableOperations(true) // TODO: i file li devono poter caricare tutti?
      .setFileDownloader((file: WorkOrderFile) => {

        this.workOrderFileController
          .getWorkOrderFile(file, true)
          ?.subscribe((blob: any) => {

            if (blob) {

              const fileURL = URL.createObjectURL(blob);
              window.open(fileURL);

            }

          })

      })
      .setGetter(() => new Promise((resolve) => {

        const configuration: CtModelConfiguration<any> = CtModelConfiguration
          .create()
          .setRouteData(WorkOrderFileRouteData())

        const operators: CtModelDatatableOperators = CtModelDatatableOperators
          .create()
          .setFilters([
            CTModelDatatableFilter
              .create()
              .setField("WorkOrder.Oid")
              .setValue(workOrder.Oid)
              .setOperatorType(CtBinaryOperator.Equal)
          ])

        this.modelService
          .getList(configuration, operators)
          .subscribe(response => resolve((response.Result?.DataSource ?? []) as Array<WorkOrderFile>));

      }))
      .setFileDeleteFunc((file: WorkOrderFile) => this.workOrderFileController.delete(file) ?? null)
      .setFileUploadFunc((file: File) => this.workOrderFileController.create(file, workOrder))
      .setFileNameMapper((file: WorkOrderFile) => file.Name)

    return this.attachmentManagerService
      .open<WorkOrderFile>(configuration);

  }

}
