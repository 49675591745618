import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WorkOrderHandlingsComponent} from './work-order-handlings.component';
import {CtModelModule} from "@ctsolution/ct-framework";

@NgModule({
  declarations: [
    WorkOrderHandlingsComponent
  ],
  imports: [
    CommonModule,
    CtModelModule
  ],
  exports: [
    WorkOrderHandlingsComponent
  ]
})
export class WorkOrderHandlingsModule {
}
