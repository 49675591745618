import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserInfoWorkDataEditComponent } from './user-info-work-data-edit.component';
import { CtButtonModule, CtFormModule, CtModelModule } from '@ctsolution/ct-framework';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { FlexModule } from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    CtModelModule,
    FormsModule,
    ReactiveFormsModule,
    CtFormModule,
    CtButtonModule,
    MatCardModule,
    FlexModule
  ],
  declarations: [UserInfoWorkDataEditComponent],
  exports : [UserInfoWorkDataEditComponent]
})
export class UserInfoWorkDataEditModule { }
