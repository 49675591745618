import {Component, Input, TemplateRef, ViewChild} from '@angular/core';
import {
  CTModelDatatableFilter,
  CtBinaryOperator,
  CtModelConfiguration,
  CtModelOperator,
} from '@ctsolution/ct-framework';
import {WorkOrderRouteData} from '../../../_core/route-data/work-order.route-data';
import {Router} from '@angular/router';
import {CTMWorkOrderViewType} from "../../../_core/enum/work-order-type";
import {WorkOrderService} from "../../work-order/work-order.service";

@Component({
  selector: 'app-lead-work-order-list',
  template: `
    <div *ngIf="configuration">

      <h5> {{ 'lead.workorder' | translate }} </h5>

      <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>

    </div>`,

  providers: [WorkOrderService]
})
export class LeadWorkOrderListComponent {

  @Input() masterConfiguration: CtModelConfiguration<any> | null = null;
  configuration: CtModelConfiguration<any> | null = null;

  constructor(private router: Router, private workOrderService: WorkOrderService) {
  }

  ngOnInit() {

    if (this.masterConfiguration?.RouteData?.id) {

      this.setup()

    }

  }

  setup() {

    const configuration = CtModelConfiguration
      .create()
      .setRouteData(WorkOrderRouteData())
      .setDisableAutoQueryFilter(true)
      .setOnEdit(event => {

        let workOrderType = CTMWorkOrderViewType.Standard;

        if (event.Quotation) workOrderType = CTMWorkOrderViewType.Quotation;

        const controllerRoute = this.workOrderService.getRoutePathController(workOrderType);

        const path = ['/', ...controllerRoute, 'edit', event.Oid];

        this.router.navigate(path);

      })

    configuration
      .initialized()
      .subscribe(() => this.configuration?.setOperations([CtModelOperator.Update]))


    configuration
      .filterValues
      .addConstantFilter(CTModelDatatableFilter
        .create()
        .setField('Lead.Oid')
        .setValue(this.masterConfiguration?.RouteData?.id)
        .setOperatorType(CtBinaryOperator.Equal))

    this.configuration = configuration;

  }

}
