import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentsListComponent } from './payments-list.component';
import { CtModelModule } from '@ctsolution/ct-framework';

@NgModule({
  imports: [
    CommonModule,
    CtModelModule
  ],
  declarations: [PaymentsListComponent],
  exports : [PaymentsListComponent]
})
export class PaymentsListModule { }
