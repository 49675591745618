<mat-card>

  <mat-card-header>

    <mat-card-title fxLayout="row">
      <div>{{ "Gestione documento" | translate }}</div>
      <div fxFlex></div>
      <div>
        <button mat-dialog-close mat-mini-fab>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-card-title>

  </mat-card-header>
  <mat-card-content>

    <app-work-order-documents-form
      [configuration]="data?.Document"
      (onSubmit)="submit($event)"
      (onCancel)="cancel()"
      #workOrderDocumentsForm></app-work-order-documents-form>

  </mat-card-content>

</mat-card>
