import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LangDownloadFileComponent} from './lang-download-file.component';
import {MatLegacyMenuModule} from "@angular/material/legacy-menu";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {MatIconModule} from "@angular/material/icon";


@NgModule({
  declarations: [
    LangDownloadFileComponent
  ],
  imports: [
    CommonModule,
    MatLegacyMenuModule,
    MatLegacyButtonModule,
    MatIconModule
  ],
  exports: [LangDownloadFileComponent]
})
export class LangDownloadFileModule {
}
