<h1 mat-dialog-title> {{ "notesRegisterLabel" | translate }}</h1>
<form mat-dialog-content [formGroup]="form">
  <app-user-working-hours-notes-control></app-user-working-hours-notes-control>
</form>
<div mat-dialog-actions>

  <ct-button [configuration]="cancelButton"></ct-button>

  <ct-button [configuration]="submitButton"></ct-button>

</div>
