import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeadEditFormComponent } from './lead-edit-form.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import { CtFormModule, CtButtonModule, CtModelModule } from '@ctsolution/ct-framework';
import { LeadCreateWorkorderModule } from '../../lead-create-workorder/lead-create-workorder.module';
import { CompanyInformationModule } from '../../../company/company-information/company-information.module';
import { LeadInformationModule } from '../lead-information/lead-information.module';

@NgModule({
  imports: [
    CommonModule,
    CtFormModule,
    MatCardModule,
    CtButtonModule,
    FlexLayoutModule,
    LeadCreateWorkorderModule,
    CtModelModule,
    CompanyInformationModule,
    LeadInformationModule
  ],
  declarations: [LeadEditFormComponent],
  exports:[LeadEditFormComponent]
})
export class LeadEditFormModule { }
