export class WorkOrderLocationConfiguration {

  WorkOrder: { Oid: number } | null = null;
  visible : boolean = true;

  private constructor() {
  }

  public static create = () => new WorkOrderLocationConfiguration();

  setWorkOrderOid(value: number | null) {

    this.WorkOrder = value ? {Oid: value} : null;
    return this;

  }

}
