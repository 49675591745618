export class StateLeadBulletConfiguration {

  labelPrefix: string | null = null;

  private constructor(public state: string) {
  }

  public static create = (state: string) => new StateLeadBulletConfiguration(state);

  setLabelPrefix(value: string | null) {

    this.labelPrefix = value;
    return this;

  }

}
