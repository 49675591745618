import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SelectedItemsComponent} from "./selected-items.component";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {CtButtonModule, CtFrameworkModule, DisableArrowKeysModule} from "@ctsolution/ct-framework";
import {MatDividerModule} from "@angular/material/divider";
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";
import {MatIconModule} from "@angular/material/icon";
import {BarcodeTrackingComponent} from './barcode-tracking/barcode-tracking.component';
import {MatLegacyDialogModule} from "@angular/material/legacy-dialog";
import {MatLegacyFormFieldModule} from "@angular/material/legacy-form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatLegacyInputModule} from "@angular/material/legacy-input";
import {MatLegacyProgressBarModule} from "@angular/material/legacy-progress-bar";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {MatLegacyChipsModule} from "@angular/material/legacy-chips";
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";
import {TableModule} from "primeng/table";
import {DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {
  StatusToggleComponentModule
} from "../../order-handling-wizard-components/status-toggle-component/status-toggle-component.module";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {MatLegacyMenuModule} from "@angular/material/legacy-menu";

@NgModule({
  declarations: [
    SelectedItemsComponent,
    BarcodeTrackingComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    CtButtonModule,
    MatDividerModule,
    MatListModule,
    MatIconModule,
    MatLegacyDialogModule,
    MatLegacyFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyInputModule,
    MatLegacyProgressBarModule,
    MatLegacyButtonModule,
    MatLegacyChipsModule,
    FlexModule,
    FlexLayoutModule,
    CtFrameworkModule,
    TableModule,
    DropdownModule,
    InputTextModule,
    StatusToggleComponentModule,
    ButtonModule,
    RippleModule,
    MatLegacyMenuModule,
    DisableArrowKeysModule
  ],
  exports: [
    SelectedItemsComponent
  ]
})
export class SelectedItemsModule {
}
