import {Component, Inject, Optional, ViewChild} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from "@angular/material/legacy-dialog";
import {
  UserWorkingHoursWizardFormComponent
} from "./user-working-hours-wizard-form/user-working-hours-wizard-form.component";
import {UserWorkingHoursWizardDialogData} from "./user-working-hours-wizard.service";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-user-working-hours-wizard',
  templateUrl: './user-working-hours-wizard.component.html',
  styleUrls: ['./user-working-hours-wizard.component.scss'],
  animations: [
    trigger('highlightAnimation', [
      state('true', style({transform: 'scale(1.1)', fontWeight: 'bold'})),
      state('false', style({transform: 'scale(1)', fontWeight: 'normal'})),
      transition('false => true', [
        animate('200ms'),
      ]),
      transition('true => false', [
        animate('200ms'),
      ]),
    ]),
  ],
})
export class UserWorkingHoursWizardComponent {

  @ViewChild(UserWorkingHoursWizardFormComponent) userworkinghoursWizardFormComponent: UserWorkingHoursWizardFormComponent | null = null;

  currentDate: Date | null = null;
  highlighted = false;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public dialogConfiguration: UserWorkingHoursWizardDialogData | undefined) {

    this.currentDate = this.setupStandardDateValue(this.dialogConfiguration?.data?.Date ? new Date(this.dialogConfiguration?.data?.Date) : null);

  }

  ngAfterViewInit() {

    setTimeout(() => {

      if (this.dialogConfiguration?.data?.UserInfo?.Oid) {

        this.userworkinghoursWizardFormComponent
          ?.form
          .get('UserInfo')
          ?.setValue(this.dialogConfiguration.data.UserInfo.Oid)

      }

    })

  }

  private setupStandardDateValue(date: Date | null = new Date()) {

    if (!date) date = new Date();

    date
      .setHours(0, 0, 0, 0);

    return date;

  }

  updateDate(state: DateUpdateState) {

    let increment = 1;

    switch (state) {

      case DateUpdateState.previous:
        increment = -1;
        break;

    }

    this.currentDate
      ?.setDate(this.currentDate?.getDate() + increment);

    this.currentDate = this.setupStandardDateValue(new Date(this.currentDate?.getTime() ?? 0));

    this.highlighted = true;

  }

  animationDone(event: any) {

    if (event.toState) {

      // Disattiva l'animazione
      this.highlighted = false;

    }

  }

  protected readonly DateUpdateState = DateUpdateState;

}


enum DateUpdateState {

  previous,
  next

}
