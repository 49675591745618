import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LeadWorkOrderListComponent} from './lead-work-order-list.component';
import {CtFrameworkModule, CtModelModule} from '@ctsolution/ct-framework';
import { StateLeadBulletModule } from '../lead-list/state-lead-bullet/state-lead-bullet.module';

@NgModule({
  imports: [
    CommonModule,
    CtModelModule,
    CtFrameworkModule,
    StateLeadBulletModule
  ],
  declarations: [LeadWorkOrderListComponent],
  exports: [LeadWorkOrderListComponent]
})
export class LeadWorkOrderListModule {
}
