import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ModuleEditItemComponent} from "./module-edit-item.component";
import {ModuleEditItemService} from "./module-edit-item.service";
import {CtModelModule} from "@ctsolution/ct-framework";
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";

@NgModule({
  declarations: [
    ModuleEditItemComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    CtModelModule
  ],
  exports: [
    ModuleEditItemComponent
  ],
  providers: [
    ModuleEditItemService
  ]
})
export class ModuleEditItemModule {
}
