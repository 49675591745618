import { Component, OnInit } from '@angular/core';
import { CtBinaryOperator, CtModelConfiguration, CTModelDatatableFilter, CtModelOperator } from '@ctsolution/ct-framework';
import { UserInfoWorkDataRouteData } from '../../../_core/route-data/user-work-hour.route-data';
import { DEFAULT_DATATABLE_CONFIGURATION } from '../../../_core/constants';
import { ActivatedRoute } from '@angular/router';
import { CTMGeneralService } from '../../../_core/lib/general.service';
import { UserInfoWorkDataService } from './user-info-work-data.service';
import { UserInfoWorkDataEditConfiguration } from './user-info-work-data-edit/user-info-work-data-edit.configuration';

@Component({
  selector: 'app-user-info-work-data',
  templateUrl: './user-info-work-data.component.html',
  styleUrls: ['./user-info-work-data.component.scss']
})
export class UserInfoWorkDataComponent implements OnInit {

  configuration: CtModelConfiguration<UserInfoWorkDataComponent> | null = null;

  constructor(private route: ActivatedRoute,private general : CTMGeneralService, private userInfoWorkDataService : UserInfoWorkDataService) { }

  ngOnInit() {

    const pId: string | null = this.route.snapshot.paramMap.get("id");
    if (pId == null) return;

    const configuration = CtModelConfiguration.create<UserInfoWorkDataComponent>()
      .setRouteData(UserInfoWorkDataRouteData())
      .setCTDatatableConfiguration(DEFAULT_DATATABLE_CONFIGURATION())
      .setOnCreate(() => this.userInfoWorkDataService.openForm(UserInfoWorkDataEditConfiguration.create().setUserOid(+pId)))
      .setOnEdit(event => this.userInfoWorkDataService.openForm(UserInfoWorkDataEditConfiguration.create().setOid(event.Oid).setUserOid(+pId)));

    configuration
      .filterValues
      .addConstantFilter(
        CTModelDatatableFilter
          .create()
          .setField('UserInfo.Oid')
          .setValue(pId)
          .setOperatorType(CtBinaryOperator.Equal)
      )



    this.configuration = configuration;



  }

}
