import { Component, Input, OnInit } from '@angular/core';
import { WorkOrderDocumentsConfiguration } from '../work-order-documents.configuration';
import { CtWebapiGenericResponse, CtWebapiService, DataRequest } from '@ctsolution/ct-webapi';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { CtGenericDialogComponent, CtGenericDialogConfiguration } from '@ctsolution/ct-framework';

@Component({
  selector: 'app-work-order-mandatory-documents-allert',
  templateUrl: './work-order-mandatory-documents-allert.component.html',
  styleUrls: ['./work-order-mandatory-documents-allert.component.scss']
})
export class WorkOrderMandatoryDocumentsAllertComponent implements OnInit {

  showAllert  = false;
  mandatoryList : Array<DocumentsMandatoryAllertDTO> = [];

  @Input() configuration: WorkOrderDocumentsConfiguration | null = null;

  constructor(private _webapi : CtWebapiService,private dialog: MatLegacyDialog,) { }

  ngOnInit() {

    this.getMissingMandatoryDocuments()
  }

  getAllert(){


    this
      .dialog
      .open(CtGenericDialogComponent, {
        data: {
          configuration:
            CtGenericDialogConfiguration.create()
              .setContent(this.setContentAllert())
              .setTitle("alert")
              .setSubmitLabel("close"),
        }, maxWidth: '500px', minWidth: '300px'
      })
      .afterClosed()
      .subscribe(res => {

        this.dialog.closeAll();

      });

  }

  setContentAllert(){

    const title = "Attenzione, mancano queste tipologie di documenti obbligatorie: ";

    // Estrai i nomi e concatenali in una stringa separata da virgole
    const names = this.mandatoryList.map(mandatory => mandatory.Name).join(", ");
  
    // Costruisci e restituisci la stringa finale
    return title + names;

  }


  getMissingMandatoryDocuments() {

    const url = '/WorkOrder/GetMissingMandatoryFileTypes/' + this.configuration?.WorkOrder.Oid; 

    const request: DataRequest = DataRequest
    .create()
    .setUrl(url)

  this._webapi
    .get(request)
    .subscribe((result :  CtWebapiGenericResponse<Array<DocumentsMandatoryAllertDTO>>) => {

      if (result.Result.length > 0) {

        this.showAllert = true;
        this.mandatoryList = result.Result;  // prendo la lista dei file mancanti
      
      }

      else {
        this.showAllert = false;
      }
    })

  }

}


export class DocumentsMandatoryAllertDTO {
  
  constructor(public Name : string){

  }

}