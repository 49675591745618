import {SelectedItemsConfiguration} from "../../selected-items/selected-items.configuration";
import {HandlingOriginEnum} from "../../../../../../_core/enum/handling-origin.enum";
import {CTModelDatatableFilter, CtSelectControlValue} from "@ctsolution/ct-framework";
import {SelectionType} from "@swimlane/ngx-datatable";

export class ItemSelectionConfiguration {

  get selectionType(): SelectionType {
    return this._selectionType;
  }

  private set selectionType(value: SelectionType) {
    this._selectionType = value;
  }

  get importEnabled(): boolean {
    return this._importEnabled;
  }

  private set importEnabled(value: boolean) {
    this._importEnabled = value;
  }

  get defaultFilters(): CTModelDatatableFilter[] | null {
    return this._defaultFilters;
  }

  private set defaultFilters(value: CTModelDatatableFilter[] | null) {
    this._defaultFilters = value;
  }

  get searchText(): string | null {
    return this._searchText;
  }

  private set searchText(value: string | null) {
    this._searchText = value;
  }

  get selectedItemsConfiguration(): SelectedItemsConfiguration | null {
    return this._selectedItemsConfiguration;
  }

  private set selectedItemsConfiguration(value: SelectedItemsConfiguration | null) {
    this._selectedItemsConfiguration = value;
  }

  get origin(): HandlingOriginEnum | null {
    return this._origin;
  }

  private set origin(value: HandlingOriginEnum | null) {
    this._origin = value;
  }

  get from(): CtSelectControlValue | null {
    return this._from;
  }

  private set from(value: CtSelectControlValue | null) {
    this._from = value;
  }

  private _from: CtSelectControlValue | null = null;
  private _origin: HandlingOriginEnum | null = null;
  private _selectedItemsConfiguration: SelectedItemsConfiguration | null = null;
  private _searchText: string | null = null;
  private _defaultFilters: CTModelDatatableFilter[] | null = new Array<CTModelDatatableFilter>();
  private _importEnabled: boolean = true;
  private _selectionType: SelectionType = SelectionType.multiClick;

  private constructor() {
  }

  public static create = (): ItemSelectionConfiguration => new ItemSelectionConfiguration();

  setFrom(value: CtSelectControlValue | null): ItemSelectionConfiguration {

    this.from = value;
    return this;

  }

  setSelectedItemsConfiguration(value: SelectedItemsConfiguration | null): ItemSelectionConfiguration {

    this.selectedItemsConfiguration = value;
    return this;

  }

  setOrigin(value: HandlingOriginEnum | null): ItemSelectionConfiguration {

    this.origin = value;
    return this;

  }

  setSearchText(value: string | null): ItemSelectionConfiguration {

    this.searchText = value;
    return this;

  }

  setDefaultFilters(value: CTModelDatatableFilter[] | null): ItemSelectionConfiguration {

    this.defaultFilters = value;
    return this;

  }

  setImportEnabled(value: boolean) {

    this.importEnabled = value;
    return this;

  }

  setSelectionType(value: SelectionType) {

    this.selectionType = value;
    return this;

  }

}
