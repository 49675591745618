import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeadEditModule } from './lead-edit/lead-edit.module';
import { LeadEditComponent } from './lead-edit/lead-edit.component';
import { RouterModule } from '@angular/router';
import { LeadListComponent } from './lead-list/lead-list.component';
import { LeadListModule } from './lead-list/lead-list.module';

@NgModule({
  imports: [
    CommonModule,
    LeadEditModule,
    LeadListModule,
    RouterModule.forChild([
      {
        path: "",
        children: [
          {
            path: "",
            redirectTo: "list",
            pathMatch: "full"
          },
          {
            path: "create",
            component: LeadEditComponent
          },
          {
            path: "edit/:id",
            component: LeadEditComponent
          },
          {
            path: "list",
            component: LeadListComponent
          }
        ]
      }
    ])
  ],
  declarations: []
})
export class LeadModule { }
