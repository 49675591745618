export enum LocationMacroType {
  /**
   * nessuna particolarità
   * */
  Standard = 0,
  /**
   * abilita la possibilità di definire un alert temporaneo come sopra descritto
   * */
  Temporary = 1,
  /**
   *  E' possibile spostarle creando una movimentazione, e non sarà necessario riepilogare gli articoli movimentati
   *  (la movimentazione servirà come log per modificare il deposito padre della scatola).
   *  I depositi di tipo box devono sempre avere come padre un deposito, e non potranno mai avere depositi figli se non altre scatole.
   * */
  Box = 2
}
