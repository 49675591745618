import {Component} from "@angular/core";
import {
  CtBinaryOperator,
  CtModelConfiguration,
  CTModelDatatableFilter,
  CtModelOperator
} from "@ctsolution/ct-framework";
import {ActivatedRoute} from "@angular/router";
import {WarehouseService} from "../warehouse.service";
import {CtFieldCustomColumn} from "@ctsolution/ct-framework/lib/ct-datatable/_core/ct-field-custom.column";
import {CTMGeneralService} from "../../../_core/lib/general.service";
import {DEFAULT_DATATABLE_CONFIGURATION} from "../../../_core/constants";
import {LocationMacroType} from "../../../_core/enum/location.macro-type";
import {WarehouseRouteData} from "../../../_core/route-data/warehouse.route-data";

@Component({
  selector: "app-warehouse-list",
  template: `
    <ng-container *ngIf="configuration">

      <ng-container [ngSwitch]="listParameter.MacroType">

        <app-temporary-area-list
          *ngSwitchCase="LocationMacroType.Temporary"
          [configuration]="configuration"></app-temporary-area-list>

        <ct-model *ngSwitchDefault [configuration]="configuration"></ct-model>

      </ng-container>

    </ng-container>`
})
export class WarehouseListComponent {

  configuration: CtModelConfiguration<WarehouseListComponent> | null = null;
  listParameter: WhListParameter | null = null;

  constructor(
    private general: CTMGeneralService,
    private route: ActivatedRoute,
    private whService: WarehouseService) {

  }

  ngAfterViewInit() {

    this.setup();

  }

  private async setup() {

    this.listParameter = await this.constantType();

    const routeValue: string = this.whService.getLocationRouteValueByMacroType(this.listParameter.MacroType);
    const basePath: string[] = ["location", routeValue];

    const configuration = CtModelConfiguration
      .create<WarehouseListComponent>()
      .setOperations([CtModelOperator.Detail])
      .setRouteData(WarehouseRouteData())
      .setCTDatatableConfiguration(DEFAULT_DATATABLE_CONFIGURATION())
      .setOnCreate(() => this.general.navigateOnCTModelRouter(basePath, "create"))
      .setOnDetail(event => this.general.navigateOnCTModelRouter(basePath, "detail", {
        value: event,
        configuration: this.configuration!,
        qpKeys: ["Name"]
      }))
      .setOnEdit(event => this.general.navigateOnCTModelRouter(basePath, "edit", {
        value: event,
        configuration: this.configuration!
      }));

    const fieldsCustomTemplates: CtFieldCustomColumn[] = [
      {
        prop: "ParentLocation",
        visible: this.listParameter.MacroType === LocationMacroType.Box
      }
    ];

    if (this.listParameter.WorkOrder?.Oid) { // se ho una workorder settata vuol dire che devo vedere tutti i depositi per quella workorder

      configuration
        .setOperations(null)
        .setFilterable(false)
        .filterValues
        .addConstantFilter(
          CTModelDatatableFilter
            .create()
            .setField('WorkOrder.Oid')
            .setValue(this.listParameter.WorkOrder?.Oid)
            .setOperatorType(CtBinaryOperator.Equal)
        )

      fieldsCustomTemplates
        .push({prop: "WorkOrder", visible: false});

    } else {

      configuration
        .filterValues
        .addConstantFilter(
          CTModelDatatableFilter
            .create()
            .setField("Type.MacroType")
            .setValue(this.listParameter.MacroType)
            .setOperatorType(CtBinaryOperator.Equal)
        )

      fieldsCustomTemplates
        .push({prop: "MacroType", filterable: false, visible: false})

    }

    if(this.listParameter.MacroType === LocationMacroType.Box)
      configuration
      .initialized()
      .subscribe(() => this.configuration?.setOperations([CtModelOperator.Update,CtModelOperator.Create,CtModelOperator.Delete,CtModelOperator.Detail]))

    configuration
      .setFieldsCustomTemplates(fieldsCustomTemplates);

    this.configuration = configuration;

  }

  constantType(): Promise<WhListParameter> {

    return new Promise<WhListParameter>((resolve) => {

      const macroTypePathValue: string | null = this.route.snapshot.paramMap.get("macrotype");
      const returnValue: WhListParameter = new WhListParameter(this.whService.getLocationMacroTypeByRouteValue(macroTypePathValue));

      this.route
        .queryParams
        .subscribe(qp => {

          if (qp["WorkOrder"]) {

            returnValue.WorkOrder = new WhListWorkOrderParameter(+qp["WorkOrder"]);

            if (qp["WorkOrderName"]) returnValue.WorkOrder.Name = qp["WorkOrderName"];

          }

          this
            .route
            .data
            .subscribe(data => {

              let title: string;

              switch (returnValue.MacroType) {
                case LocationMacroType.Temporary:
                  title = "CT_MENU.sorting-areas";
                  break;
                case LocationMacroType.Box:
                  title = "CT_MENU.box";
                  break;
                default:
                  title = "CT_MENU.stores";
                  break;

              }

              if (!returnValue.WorkOrder?.Name) {

                data['title'] = title;

              } else {

                data['title'] = 'workorder.locations';
                if (returnValue.WorkOrder?.Name) data['extraString'] = returnValue.WorkOrder?.Name ?? 'WorkOrder';

              }

              data["urls"] = [{title}];
              resolve(returnValue);

            });

        });

    });

  }

  protected readonly LocationMacroType = LocationMacroType;
}

export class WhListParameter {
  WorkOrder: WhListWorkOrderParameter | null = null;

  constructor(public MacroType: LocationMacroType | null) {
  }

}

class WhListWorkOrderParameter {
  Name: string | null = null;

  constructor(public Oid: number) {
  }

}
