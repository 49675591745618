import { Component, Input, OnInit } from '@angular/core';
import { WorkOrderSummaryConfiguration } from './work-order-summary.configuration';
import { CtWebapiGenericResponse, CtWebapiService } from '@ctsolution/ct-webapi';
import { qtyStatusList } from 'projects/ct-manager/src/app/_core/route-data/work-order.route-data';
import { ActivatedRoute } from '@angular/router';
import { Table } from 'primeng/table';

@Component({
  selector: 'ctm-work-order-summary',
  templateUrl: './work-order-summary.component.html',
  styleUrls: ['./work-order-summary.component.scss']
})
export class WorkOrderSummaryComponent implements OnInit {

  @Input() configuration: WorkOrderSummaryConfiguration | null = null;

  items : Array<ItemStatusDTO> = [];
  filterPanelEnabled =  false;

  cols = [
    {field: 'ItemCode', header: 'ItemCode'}, // width: 200px
    {field: 'ItemName', header: 'ItemName'},
    {field: 'WorkOrderQty', header: 'WorkOrderQty'}, // width: 100px
    {field: 'StockQty', header: 'StockQty'}, // width: 100px
    {field: 'DifferenceQty', header: 'DifferenceQty'}, // width: 100px
    {field: 'ArrivingQty', header: 'ArrivingQty'}, // width: 100px

  ]


  constructor(private webApiService : CtWebapiService, private route : ActivatedRoute) { }

  ngOnInit() {

    const pId = this.route.snapshot.paramMap.get("id");
    if(pId == null) return;

    const queryParams = {
      workOrderOid : pId
    }

    this.webApiService.get(qtyStatusList(pId)).subscribe((response: CtWebapiGenericResponse<any>) => {

      if(response.Result == null) return;
      this.items = response.Result

    });

  }

  toggleFilters(table: Table) {

    this.filterPanelEnabled = !this.filterPanelEnabled;

    if (!this.filterPanelEnabled) {

      table.clear();

    }

  }

}

export class ItemStatusDTO {

    DifferenceQty : number | null = null;
    ItemCode : string | null = null;
    ItemName : string | null = null;
    ArrivingQty : number | null = null;
    StockQty : number | null = null;
    WorkOrderQty : number | null = null;

    constructor(){}

}