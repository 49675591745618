<ng-container *ngIf="configuration ; else errorTemplate">

  <h1 mat-dialog-title *ngIf="configuration.title">

    {{configuration.title | translate}}

  </h1>

  <div mat-dialog-content *ngIf="configuration.content">

    <p> {{ configuration.content | translate }}</p>

  </div>

  <form [formGroup]="form">

    <ct-control [configuration]="codeControl"></ct-control>

  </form>


  <div mat-dialog-actions class="mat-dialog-actions">

    <ct-button [configuration]="cancelButton"></ct-button>
    <ct-button [configuration]="submitButton"></ct-button>

  </div>

</ng-container>

<ng-template #errorTemplate>

  <h1 mat-dialog-title>

    Error

  </h1>

</ng-template>
