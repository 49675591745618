import {UserWorkingHours} from "../../../../../_core/classes/user-working-hours";

export class UserWorkingHoursPicturesConfiguration {

  userWorkingHours: UserWorkingHours | null = null;

  private constructor() {
  }

  public static create = (): UserWorkingHoursPicturesConfiguration => new UserWorkingHoursPicturesConfiguration();

  setUserWorkingHours(value: UserWorkingHours | null): UserWorkingHoursPicturesConfiguration {

    this.userWorkingHours = value;
    return this;

  }

}
