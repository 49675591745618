import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WorkOrderDocumentsWizardComponent} from './work-order-documents-wizard.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {CtButtonModule, CtControlModule, CtFileUploaderModule, CtModelModule} from '@ctsolution/ct-framework';
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {MatLegacyDialogModule} from "@angular/material/legacy-dialog";
import {TranslateModule} from "@ngx-translate/core";
import {WorkOrderDocumentsFormComponent} from './work-order-documents-form/work-order-documents-form.component';
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    CtButtonModule,
    FlexLayoutModule,
    CtModelModule,
    MatIconModule,
    MatLegacyButtonModule,
    MatLegacyDialogModule,
    TranslateModule,
    ReactiveFormsModule,
    CtControlModule,
    CtFileUploaderModule
  ],
  declarations: [WorkOrderDocumentsWizardComponent, WorkOrderDocumentsFormComponent],
  exports: [WorkOrderDocumentsWizardComponent]
})
export class WorkOrderDocumentsWizardModule {
}
