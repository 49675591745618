import {Component} from '@angular/core';
import {CtButtonConfiguration, MAT_RAISED_WARN} from "@ctsolution/ct-framework";
import {MatLegacyDialogRef} from "@angular/material/legacy-dialog";

@Component({
  selector: 'app-access-denied-dialog',
  template: `
    <h1 mat-dialog-title> {{ "warning" | translate }} </h1>
    <div mat-dialog-content>
      <p> {{ "accessDeniedMessage" | translate }}</p>
    </div>
    <div mat-dialog-actions>
      <ct-button [configuration]="cancelButton"></ct-button>
    </div>
  `
})
export class AccessDeniedDialogComponent {

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("close")
    .setAction(() => this.onCancel())
    .setMatherialOptions(MAT_RAISED_WARN);

  constructor(private dialogRef: MatLegacyDialogRef<any>) {
  }

  onCancel() {

    this.dialogRef
      .close();

  }

}
