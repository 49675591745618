import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyCardModule} from "@angular/material/legacy-card";
import {CompanyPlaceComponent} from './company-place/company-place.component';
import {CtButtonModule, CtModelModule} from "@ctsolution/ct-framework";
import {MatDividerModule} from "@angular/material/divider";
import {TranslateModule} from "@ngx-translate/core";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {CompanyPlaceListComponent} from "./company-place-list.component";


@NgModule({
  declarations: [
    CompanyPlaceListComponent,
    CompanyPlaceComponent
  ],
  exports: [
    CompanyPlaceListComponent
  ],
  imports: [
    CommonModule,
    MatLegacyCardModule,
    CtButtonModule,
    CtModelModule,
    MatDividerModule,
    TranslateModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class CompanyPlaceListModule {
}
