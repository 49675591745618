import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CatalogDetailComponent} from "./catalog-detail.component";
import {MatLegacyTabsModule as MatTabsModule} from "@angular/material/legacy-tabs";
import {ModuleItemsModule} from "./module-items/module-items.module";
import {CtButtonModule, CtFrameworkModule} from "@ctsolution/ct-framework";
import {ModuleCreateModule} from "./module-create/module-create.module";
import {ModuleStocksModule} from "./module-stocks/module-stocks.module";
import {ModuleHandlingsModule} from "./module-handlings/module-handlings.module";

@NgModule({
  declarations: [
    CatalogDetailComponent
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    ModuleItemsModule,
    CtButtonModule,
    ModuleCreateModule,
    ModuleStocksModule,
    ModuleHandlingsModule,
    CtFrameworkModule
  ]
})
export class CatalogDetailModule {
}
