import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StateLeadBulletComponent } from './state-lead-bullet.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule
  ],
  declarations: [StateLeadBulletComponent],
  exports : [StateLeadBulletComponent]
})
export class StateLeadBulletModule { }
