import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StatusToggleComponentComponent} from './status-toggle-component.component';
import {CtFrameworkModule} from "@ctsolution/ct-framework";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";


@NgModule({
  declarations: [
    StatusToggleComponentComponent
  ],
  exports: [
    StatusToggleComponentComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    CtFrameworkModule,
    MatIconModule
  ]
})
export class StatusToggleComponentModule {
}
