import {AfterViewInit, Component} from '@angular/core';
import {
  CtControlConfiguration, CtControlValidator,
  CTFieldInfos,
  CtFormContainerHelper,
  CtSelectControlOptions, CtSelectControlRemoteDefaults, CtSortOrderDescriptorParameter,
  CtThemeTypes
} from "@ctsolution/ct-framework";
import {environment} from "../../../../../../environments/environment";
import {JwtService} from "../../../../../_core/lib/jwt.service";

const UserWorkingHoursUserInfoControl: CTFieldInfos = CTFieldInfos.create({
  IsDTO: true,
  Field: "UserInfo",
  Type: "UserInfoDTO",
  IsNullable: false,
  ControlType: "Lookup",
  LookupInfos: {
    RemoteEndpoint: `${environment.dev.REST_API_SERVER}/User/Lookup`,
    CodeField: "Code",
    DescriptionField: "Description",
    IncrementalSearch: false,
  },
} as CTFieldInfos);

@Component({
  selector: 'app-user-working-hours-user-info-control',
  template: `
    <ct-control [configuration]="control"></ct-control>`
})
export class UserWorkingHoursUserInfoControlComponent implements AfterViewInit {

  control: CtControlConfiguration = CtControlConfiguration
    .create(
      CtFormContainerHelper
        .create()
        .getCTFormControlByCTFieldInfo(UserWorkingHoursUserInfoControl))
    .setTheme(CtThemeTypes.MATERIAL)
    .setValidators([CtControlValidator.create({name: 'required'} as CtControlValidator)])
    .configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        .setRemoteDefaults(
          CtSelectControlRemoteDefaults
            .create()
            .setSortOrder(CtSortOrderDescriptorParameter.create('Surname'))
        )

    })

  constructor(private _jwt: JwtService) {
  }

  ngAfterViewInit() {

    this.setup()

  }

  private async setup() {

    const oid = await this._jwt.getUserInfoOid();

    if (!this.control.control?.value && oid) {

      this.control
        .setValue(+oid)

    }

  }

}
