<div class="company-contact-relation-edit-container">

  <h1 mat-dialog-title> {{ "companyContactRelationEditTitle" | translate }} </h1>

  <div mat-dialog-content>

    <form [formGroup]="form">

      <div [fxLayout]="'row wrap'">

        <div [fxFlex]="50"
             [fxFlex.sm]="100"
             [fxFlex.xs]="100"
             class="p-10">

          <ct-control [configuration]="nameControl"></ct-control>

        </div>

        <div [fxFlex]="50"
             [fxFlex.sm]="100"
             [fxFlex.xs]="100"
             class="p-10">

          <ct-control [configuration]="surnameControl"></ct-control>

        </div>

      </div>

      <div class="slider-toggle-container">

        <div class="slider-toggle-panel">
          <mat-label class="labelBeforeSlide">{{ 'Ex' | translate }}</mat-label>
          <mat-slide-toggle color="primary" formControlName="CurrentRole">{{ 'current' | translate }}</mat-slide-toggle>
        </div>

      </div>

    </form>

  </div>

  <div mat-dialog-actions>

    <div>

      <ct-button [configuration]="cancelButton"></ct-button>

    </div>

    <div>

      <ct-button [configuration]="editButton"></ct-button>

      &nbsp;

      <ct-button [configuration]="submitButton"></ct-button>

    </div>

  </div>

</div>
