import {ChangeDetectorRef, Component, TemplateRef, ViewChild} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {CtButtonConfiguration, MAT_RAISED_PRIMARY} from "@ctsolution/ct-framework";
import {ModuleCreateService} from "./module-create/module-create.service";
import {ModuleItemsComponent} from "./module-items/module-items.component";
import {ModuleCreationRequestParameter} from "../../../_core/classes/module-creation-request-parameter";

@Component({
  selector: "app-catalog-detail",
  templateUrl: "./catalog-detail.component.html",
  styleUrls: ["./catalog-detail.component.scss"]
})
export class CatalogDetailComponent {

  @ViewChild("headerActions") headerActions: TemplateRef<any> | null = null;
  @ViewChild(ModuleItemsComponent) moduleItemsComponent: ModuleItemsComponent | null = null;

  pId: string | null = null;
  createButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("generateModulesLabel")
    .setIcon("auto_fix_normal")
    .setAction(() => this.createModule())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  constructor(
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private moduleCreateService: ModuleCreateService) {

    this.pId = this.route.snapshot.paramMap.get("id");

  }

  ngAfterViewInit() {

    this.route
      .queryParams
      .subscribe(qp => {

        const name = qp['Name'] ?? null;

        this.route
          .data
          .subscribe(data => {

            data['title'] = 'CT_PAGE.BREADCRUMBS.detail';

            if (name) data['extraString'] = name;

            data['headerActions'] = this.headerActions;
            data['urls'] = [{title: ''}];

            this.cdr.detectChanges();

          })

      });

  }

  createModule() {

    if (!this.pId) return;

    const parameter: ModuleCreationRequestParameter = ModuleCreationRequestParameter
      .create()
      .setItemModuleOid(+this.pId);

    this.moduleCreateService
      .open(parameter)
      ?.afterClosed()
      .subscribe(result => {

        if (result) window.location.reload(); // dovrei usare la navigazione sul router, però non sta funzionando correttamente ... per ora facciamo refresh forzato

      });
  }

}
