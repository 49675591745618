import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WorkOrderImportFileConfigurationDialogComponent} from './work-order-import-file-configuration-dialog.component';
import {WorkOrderImportFileConfigurationDialogService} from "./work-order-import-file-configuration-dialog.service";
import {MatLegacyDialogModule} from "@angular/material/legacy-dialog";
import {TranslateModule} from "@ngx-translate/core";
import {CtButtonModule, CtControlModule} from "@ctsolution/ct-framework";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FlexModule} from "@angular/flex-layout";

@NgModule({
  declarations: [
    WorkOrderImportFileConfigurationDialogComponent
  ],
  imports: [
    CommonModule,
    MatLegacyDialogModule,
    TranslateModule,
    CtButtonModule,
    FormsModule,
    ReactiveFormsModule,
    CtControlModule,
    FlexModule
  ],
  exports: [
    WorkOrderImportFileConfigurationDialogComponent
  ],
  providers: [
    WorkOrderImportFileConfigurationDialogService
  ]
})
export class WorkOrderImportFileConfigurationDialogModule {
}
