import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CtButtonModule, CtModelModule} from '@ctsolution/ct-framework';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {MatLegacyMenuModule} from "@angular/material/legacy-menu";
import { LeadDocumentsComponent } from './lead-documents.component';
import { LeadDocumentsService } from './lead-documents.service';
import { LeadDocumentsWizardModule } from './lead-documents-wizard/lead-documents-wizard.module';

@NgModule({
  imports: [
    CommonModule,
    LeadDocumentsWizardModule,
    CtButtonModule,
    CtModelModule,
    MatIconModule,
    MatLegacyButtonModule,
    MatLegacyMenuModule,
  ],
  declarations: [LeadDocumentsComponent],
  exports : [LeadDocumentsComponent],
  providers : [LeadDocumentsService]
})
export class LeadDocumentsModule { }
