<mat-card>

  <mat-card-header
    fxLayout="row wrap"
    fxLayoutAlign="space-between center"
    style="padding-bottom: 0px">

    <mat-card-title>{{ 'Summary' | translate }}</mat-card-title>
    <mat-card-subtitle>{{'SummarySubTitle' | translate}}</mat-card-subtitle>

  </mat-card-header>

  <mat-card-content class="summary-rows-table" style="padding-top: 0px">

    <div style="display: flex; flex-direction: row; justify-content: space-between; margin-bottom: 5px">

      <div></div>

      <div>

        <button
          mat-raised-button
          style="margin-right: 0px"
          (click)="toggleFilters(table)">
          <mat-icon
            [color]="filterPanelEnabled ? 'primary' : 'accent'"> {{ filterPanelEnabled ? 'filter_alt_off' : 'filter_alt' }}
          </mat-icon>
        </button>

      </div>

    </div>

    <p-table
      #table
      dataKey="Oid"
      [value]="items"
      [columns]="cols"
      [paginator]="true"
      [resizableColumns]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      autoLayout="true"
      [currentPageReportTemplate]="items.length ? 'totale {totalRecords}' : ''">
      <!-- [reorderableColumns]="false" non so che cazzo succede, non funzica! -->

      <ng-template pTemplate="header" let-columns>

        <tr>

          <th
            style="min-width: 80px;"
            *ngFor="let col of columns"
            pResizableColumn
            [pSortableColumn]="col.field"
          > {{ col.header | translate }}
            <p-sortIcon [field]="col.field"/>
          </th> <!-- pReorderableColumn -->

          <th style="width: 80px"></th>

        </tr>

        <tr [hidden]="!filterPanelEnabled">

          <th style="padding: 0.5rem;">
            <p-columnFilter
              [showMenu]="false"
              [showClearButton]="false"
              type="text"
              field="ItemCode"
              placeholder="Cerca per codice"/>
          </th>

          <th style="padding: 0.5rem;">
            <p-columnFilter
              [showMenu]="false"
              [showClearButton]="false"
              type="text"
              field="ItemName"
              placeholder="Cerca per nome"/>
          </th>

          <th style="padding: 0.5rem;">
            <p-columnFilter
              [showMenu]="false"
              [showClearButton]="false"
              type="text"
              field="WorkOrderQty"
              placeholder="Cerca per quantità commessa"/>
          </th>

          <th style="padding: 0.5rem;">
            <p-columnFilter
              [showMenu]="false"
              [showClearButton]="false"
              type="text"
              field="StockQty"
              placeholder="Cerca per quantità in stock"/>
          </th>

          <th style="padding: 0.5rem;">
            <p-columnFilter
              [showMenu]="false"
              [showClearButton]="false"
              type="text"
              field="DifferenceQty"
              placeholder="Cerca per differenza"/>
          </th>

          <th style="padding: 0.5rem;">
            <p-columnFilter
              [showMenu]="false"
              [showClearButton]="false"
              type="text"
              field="OpenOrders"
              placeholder="Cerca per quantità in arrivo"/>
          </th>

          <th></th>

        </tr>

      </ng-template>

      <ng-template pTemplate="body" let-item let-ri="rowIndex">

        <tr>

          <td>
            {{ item.ItemCode }}

          </td>

          <td>

            <span class="post-desc"> {{ item.ItemName }}</span>

          </td>

          <td >

            {{ item.WorkOrderQty }}

          </td>
          
          <td >

            {{ item.StockQty }}

          </td>

          <td >

            {{ item.DifferenceQty }}

          </td>

          <td >

            {{ item.ArrivingQty }}

          </td>
        </tr>

      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>

        <p> {{ 'CT_MESSAGES.no_available_data' | translate }}</p>

      </ng-template>

    </p-table>

  </mat-card-content>

</mat-card>
