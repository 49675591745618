<mat-card class="user-working-hours-selected-list-container">

  <mat-card-header>

    <mat-card-title> {{ "activitySummaryLabel" | translate }}</mat-card-title>

  </mat-card-header>

  <mat-card-content>

    <ng-container
      *ctSkeleton="loading;
           repeat: 1;
           className: 'activity-chip-skeleton';">

      <ng-container *ngIf="list.length; else emptyList">

        <mat-chip-list>

          <mat-chip
            [selectable]="false"
            [disableRipple]="true"
            *ngFor="let activity of list"
            [removable]="true"
            (removed)="remove(activity)">

            <button mat-icon-button matChipAvatar [class.no-data]="(activity.FilesCount ?? 0) <= 0"
                    (click)="pictures(activity)">
              <mat-icon>photo_camera</mat-icon>
            </button>

            <button mat-icon-button matChipAvatar (click)="notes(activity)"
                    [class.no-data]="((activity.Note ?? '').length) <= 0">
              <mat-icon>assignment_add</mat-icon>
            </button>

            <div class="chip-content">
              <div>{{ activity.Hours }}H {{ activity.ActivityType.Name | translate }} </div>
              <div><small *ngIf="activity?.WorkOrder?.Name"> {{ "WorkOrder" | translate }}
                : {{ activity?.WorkOrder?.Name }}</small></div>
              <div><small
                *ngIf="activity?.DictionaryInfo?.Name"> {{"DictionaryInfo"| translate}}
                : {{ activity?.DictionaryInfo?.Name }}</small></div>
            </div>

            <mat-icon matChipRemove color="warn">cancel</mat-icon>

          </mat-chip>

        </mat-chip-list>

      </ng-container>

      <ng-template #emptyList><p style="min-height: 48px"> {{"noActivitiesRegisteredMessage"| translate}}.</p>
      </ng-template>

    </ng-container>

  </mat-card-content>

</mat-card>
