import {CTWorkOrderRowUnionType} from "../../../../../../../_core/classes/ct-work-order.row-union";

export class MovementManagerAddOfferConfiguration {

  Values: CTWorkOrderRowUnionType | null = null;
  WorkOrder: { Oid: number } | null = null;

  private constructor() {
  }

  public static create = () => new MovementManagerAddOfferConfiguration();

  setValues(value: CTWorkOrderRowUnionType | null) {

    this.Values = value;
    return this;

  }

  setWorkOrderOid(value: number) {

    this.WorkOrder = {Oid: value};
    return this;

  }

}
