import {Component, Input, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {SelectedItemsConfiguration} from "./selected-items/selected-items.configuration";
import {ItemSelectionConfiguration} from "./item-selection/item-selection/item-selection.configuration";
import {ItemSelectionService} from "./item-selection/item-selection.service";
import {DdtUploaderComponent} from "./ddt-uploader/ddt-uploader.component";


@Component({
  selector: 'app-order-handling-wizard-step2',
  templateUrl: './order-handling-wizard-step2.component.html',
  styleUrls: ['./order-handling-wizard-step2.component.scss']
})
export class OrderHandlingWizardStep2Component {

  @Input() viewModel: OrderHandlingWizardStepViewModel = OrderHandlingWizardStepViewModel.create();
  @ViewChild("DDTUploader", {static: false}) DDTUploader: DdtUploaderComponent | null = null;

  selectedItemsConfiguration: SelectedItemsConfiguration = SelectedItemsConfiguration
    .create()
    .setTableMode('itemhandling');

  itemSelectionConfiguration: ItemSelectionConfiguration = ItemSelectionConfiguration
    .create()
    .setSelectedItemsConfiguration(this.selectedItemsConfiguration);

  form: FormGroup;

  constructor(private formBuilder: FormBuilder, public itemSelectionService: ItemSelectionService) {

    this.form = this.formBuilder.group({});

  }

  setupDDTUploaderControls() {

    const ddtCodeControl = this.DDTUploader?.ddtControlConfiguration;

    if (ddtCodeControl) {

      this.form
        .addControl(ddtCodeControl.name!, ddtCodeControl.control)

    }

    this.form
      .addControl('ddtAttachments', this.DDTUploader?.CTFileUploaderConfiguration.control);

  }

}

export class OrderHandlingWizardStepViewModel {

  private constructor(
    public handlingIsDeliveredState: boolean = false,
    public handlingIsDraftState: boolean = false,
    public handlingIsCheckInState: boolean = false,
    public handlingIsCanceledState: boolean = false
  ) {
  }

  public static create = () => new OrderHandlingWizardStepViewModel();

}
