import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ModuleItemsComponent } from "./module-items.component";
import { CtModelModule } from "@ctsolution/ct-framework";
import { ModuleAddItemModule } from "./module-add-item/module-add-item.module";
import { ModuleEditItemModule } from "./module-edit-item/module-edit-item.module";

@NgModule({
  declarations: [
    ModuleItemsComponent
  ],
  imports: [
    CommonModule,
    CtModelModule,
    ModuleAddItemModule,
    ModuleEditItemModule
  ],
  exports: [
    ModuleItemsComponent
  ]
})
export class ModuleItemsModule {
}
