import {Component, ViewChild} from '@angular/core';
import {CtButtonConfiguration, MAT_RAISED_PRIMARY, MAT_RAISED_WARN, SnackbarService} from "@ctsolution/ct-framework";
import {FormBuilder, FormGroup} from "@angular/forms";
import {YearControlComponent} from "./year-control/year-control.component";
import {MatLegacyDialogRef} from "@angular/material/legacy-dialog";
import {MonthControlComponent} from "./month-control/month-control.component";
import {UserWorkingHoursExportParameter} from "../../../../_core/classes/user-working-hours-export.parameter";
import {UserWorkingHoursExportService} from "../user-working-hours-export.service";
import {environment} from "../../../../../environments/environment";
import {months} from "./month-control/month-control.component";

@Component({
  selector: 'app-user-working-hours-export',
  templateUrl: './user-working-hours-export.component.html'
})
export class UserWorkingHoursExportComponent {

  @ViewChild(YearControlComponent) yearControlComponent: YearControlComponent | null = null;
  @ViewChild(MonthControlComponent) monthControlComponent: MonthControlComponent | null = null;

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("close")
    .setAction(() => this.onCancel())
    .setMatherialOptions(MAT_RAISED_WARN);

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("export")
    .setAction(() => this.export())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  form: FormGroup;

  constructor(
    private dialogRef: MatLegacyDialogRef<UserWorkingHoursExportComponent>,
    private formBuilder: FormBuilder,
    private _snackbar: SnackbarService,
    private userWorkingHoursExportService: UserWorkingHoursExportService) {

    this.form = this.formBuilder.group({});

  }

  ngAfterViewInit() {

    this.setup();

  }

  setup() {

    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    // se esiste currentMonth (non ha indice 0, gennaio) allora presetto il mese precedente per l'export. Altrimenti devo mettere l'ultimo mese dell'anno precedente
    this.monthControlComponent
      ?.control
      .setFormParent(this.form)
      .setValue(currentMonth > 0 ? currentMonth - 1 : 11);

    this.yearControlComponent
      ?.control
      .setFormParent(this.form)
      .setValue(currentMonth > 0 ? currentYear : (currentYear - 1));

  }

  onCancel() {

    this.dialogRef
      .close();

  }

  export() {

    this.form
      .markAllAsTouched();

    if (this.form.valid) {

      const value = this.form.value;

      const {month, year} = {month: value.Month, year: value.Year};

      const exportDate = new Date(year, month, 1);

      if (exportDate.getTime() > new Date().getTime()) {

        this._snackbar.open('dati_non_validi');
        return;

      }

      const parameter: UserWorkingHoursExportParameter = UserWorkingHoursExportParameter
        .create()
        .setMonth(month + 1)
        .setYear(year);

      const literalMonth = months[month].split('.')[1];

      this.userWorkingHoursExportService.exportExcel(parameter, `${environment.projectName}${literalMonth.toUpperCase()}${year}`)

    }

  }

}
