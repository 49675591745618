export class WorkOrderHandlingsConfiguration {

  WorkOrder: { Oid: number } | null = null;

  private constructor() {
  }

  public static create = () => new WorkOrderHandlingsConfiguration();

  setWorkOrderOid(value: number | null) {

    this.WorkOrder = value ? {Oid: value} : null;
    return this;

  }

}
