import { AfterViewInit, Component } from '@angular/core';
import { CtModelConfiguration, CtModelDatatableOperators, CTModelDatatableFilter, CtBinaryOperator } from '@ctsolution/ct-framework';
import { ActivatedRoute } from '@angular/router';
import { WorkOrderPaymentRouteData } from 'projects/ct-manager/src/app/_core/route-data/work-order-payment.route-data';
import { CTMGeneralService } from 'projects/ct-manager/src/app/_core/lib/general.service';
import { CtWebapiGenericResponse, CtWebapiService, DataRequest } from '@ctsolution/ct-webapi';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent {

}
