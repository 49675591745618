import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WarehouseListComponent} from "./warehouse-list.component";
import {CtModelModule} from "@ctsolution/ct-framework";
import {TemporaryAreaListModule} from "./temporary-area-list/temporary-area-list.module";

@NgModule({
  declarations: [
    WarehouseListComponent
  ],
  imports: [
    CommonModule,
    CtModelModule,
    TemporaryAreaListModule
  ]
})
export class WarehouseListModule {
}
