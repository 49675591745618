import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";import { CtBinaryOperator, CtButtonConfiguration, CtControlConfiguration, CtControlTypes, CtControlValidator, CtModelConfiguration, CTModelDatatableFilter, CtModelDatatableOperators, CtModelService, CtThemeTypes, MAT_RAISED_PRIMARY, MAT_RAISED_WARN } from '@ctsolution/ct-framework';
import { NoteDialogConfiguration, FileOriginEnum } from '../note-dialog.configuration';
import { LeadFileNoteRouteData, UserWorkingHoursFileNoteRouteData, WorkOrderFileNoteRouteData } from '../../../route-data/user-working-hourse-file-note.route-data';

@Component({
  selector: 'app-note-dialog',
  templateUrl: './note-dialog.component.html',
  styleUrls: ['./note-dialog.component.scss']
})
export class NoteDialogComponent {

  control: CtControlConfiguration = CtControlConfiguration
  .create()
  .setType(CtControlTypes.TEXTAREA)
  .setTheme(CtThemeTypes.MATERIAL)
  .setLabel('Note');

  noteOid : number | null = null;

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<NoteDialogComponent>,
    private _model: CtModelService<any>,
    @Inject(MAT_DIALOG_DATA) public data: NoteDialogConfiguration) {

    this.form = this.fb.group({});

  }

  ngAfterViewInit() {

    setTimeout(() => this.setup()) // attende apertura modale

  }

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.cancel")
    .setAction(() => this.dialogRef.close())
    .setMatherialOptions(MAT_RAISED_WARN)

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setClass('ct-model-submit-button')
    .setAction(() => this.update())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  setup() {

    this.control
      .setName('Note')
      .setValidators([CtControlValidator.create({name: 'required'} as CtControlValidator)])
      .setFormParent(this.form)

      const configuration : CtModelConfiguration<any> = CtModelConfiguration.create()

      switch (this.data.Type)
      {

        case FileOriginEnum.WorkingHour:
          configuration.setRouteData(UserWorkingHoursFileNoteRouteData())
        break
        case FileOriginEnum.Lead:
          configuration.setRouteData(LeadFileNoteRouteData())
        break
        case FileOriginEnum.WorkOrder:
          configuration.setRouteData(WorkOrderFileNoteRouteData())
        break

      }



      const body: CtModelDatatableOperators = CtModelDatatableOperators
      .create()
      .setFilters([CTModelDatatableFilter.create().setField('File.Oid').setValue(this.data.FileOid).setOperatorType(CtBinaryOperator.Equal)]);

      this._model.getList(configuration,body).subscribe(res => {

        if(res.Result.DataSource.length > 0)
        {
          this.control.setValue(res.Result.DataSource[0].Content)
          this.noteOid = res.Result.DataSource[0].Oid
        }

      })


  }

  update() {

     this.form.markAllAsTouched();

     if (this.form.valid && this.data.FileOid) {

       const value = this.form.value;

       const param : NoteDialogDTO = new NoteDialogDTO(this.data.FileOid ,value.Note)

       const configuration : CtModelConfiguration<any> = CtModelConfiguration.create()

       switch (this.data.Type)
       {

         case FileOriginEnum.WorkingHour:
           configuration.setRouteData(UserWorkingHoursFileNoteRouteData())
         break
         case FileOriginEnum.Lead:
           configuration.setRouteData(LeadFileNoteRouteData())
         break
         case FileOriginEnum.WorkOrder:
           configuration.setRouteData(WorkOrderFileNoteRouteData())
         break

       }


       if(this.noteOid)
        configuration.RouteData?.setId(this.noteOid);

      this._model
       .putInfos(configuration, param)
        ?.subscribe(
           () => {
              this.dialogRef.close(true)
              if(this.data.Reload)
                window.location.reload()
            },
           (error) => console.error('An error occurred during execution:', error)
         );

   }

  }
}

export class NoteDialogDTO{

  public File? : {Oid : number}
  public Content? : string

  constructor(oid : number, content : string){
    this.File = {Oid : oid}
    this.Content = content;
  }

}
