import {Injectable} from '@angular/core';
import {CtLookupItem} from '@ctsolution/ct-framework/lib/_containers/ct-model/lib/ct-backoffice/lookups.ct-backoffice';
import {HttpClient} from "@angular/common/http";
import {catchError, Observable, of} from "rxjs";
import {map} from "rxjs/operators";

@Injectable()
export class CompanyInformationService {

  private dataUrl = `${window.location.origin}/assets/demo/company-information-type.json`;
  private cachedData: CtLookupItem[] | null = null;

  constructor(private http: HttpClient) {
  }

  getContactInformationTypes(): Observable<CtLookupItem[]> {

    if (this.cachedData) {

      // Se i dati sono già presenti nella cache, restituiscili direttamente
      return of(this.cachedData);

    } else {

      // Altrimenti, effettua la richiesta HTTP e memorizza i dati nella cache
      return this.http
        .get<CtLookupItem[]>(this.dataUrl)
        .pipe(
          map((data) => {
            this.cachedData = data;
            return data;
          }),
          catchError((error) => {
            console.error('Error loading data:', error);
            return of([]); // Restituisci un array vuoto in caso di errore
          })
        );
    }
  }
}
