import {Injectable} from '@angular/core';
import {CTManagerItem} from "../../../_core/classes/ct-manager-item";
import {
  CtAttachmentManagerConfiguration,
  CtAttachmentManagerService, CtBinaryOperator, CtModelConfiguration,
  CTModelDatatableFilter, CtModelDatatableOperators, CtModelService
} from "@ctsolution/ct-framework";
import {ItemFile} from "../../../_core/classes/item-file";
import {ItemFileController} from "../../../_core/controllers/item-file.controller";
import {ItemFileRouteData} from "../../../_core/route-data/item-file.route-data";

@Injectable()
export class ItemAttachmentsService {

  constructor(
    private modelService: CtModelService<any>,
    private itemFileController: ItemFileController,
    private attachmentManagerService: CtAttachmentManagerService) {
  }

  async open(item: CTManagerItem) {

    const configuration: CtAttachmentManagerConfiguration<ItemFile> = CtAttachmentManagerConfiguration
      .create<ItemFile>()
      .setTitle(`Allegati ${item.Name}`)
      .setEmptyDataLabel('itemEmptyAttachmentLabel')
      .setNoteLabel('itemAttachmentNoteLabel')
      .enableOperations(true) // TODO: i file li devono poter caricare tutti?
      .setFileDownloader((file: ItemFile) => {

        this.itemFileController
          .getItemFile(file, true)
          ?.subscribe((blob: any) => {

            if (blob) {

              const fileURL = URL.createObjectURL(blob);
              window.open(fileURL);

            }

          })

      })
      .setGetter(() => new Promise((resolve) => {

        const configuration: CtModelConfiguration<any> = CtModelConfiguration
          .create()
          .setRouteData(ItemFileRouteData())

        const operators: CtModelDatatableOperators = CtModelDatatableOperators
          .create()
          .setFilters([
            CTModelDatatableFilter
              .create()
              .setField("Item.Oid")
              .setValue(item.Oid)
              .setOperatorType(CtBinaryOperator.Equal)
          ])

        this.modelService
          .getList(configuration, operators)
          .subscribe(response => resolve((response.Result?.DataSource ?? []) as Array<ItemFile>));

      }))
      .setFileDeleteFunc((file: ItemFile) => this.itemFileController.delete(file) ?? null)
      .setFileUploadFunc((file: File) => this.itemFileController.create(file, item))
      .setFileNameMapper((file: ItemFile) => file.Name)

    return this.attachmentManagerService
      .open<ItemFile>(configuration);

  }

}
