import {Component, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {CtFileUploaderComponent, SnackbarService} from "@ctsolution/ct-framework";
import {CTManagerImportConfiguration} from "./ct-manager-import.configuration";
import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";

@Component({
  selector: "ct-manager-import",
  templateUrl: "./ct-manager-import.component.html",
  styleUrls: ["./ct-manager-import.component.scss"]
})
export class CTManagerImportComponent {
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Input() configuration: CTManagerImportConfiguration | null = null;
  @ViewChild("Uploader") fileUploader: CtFileUploaderComponent | null = null;

  viewModel = {
    loadingData: false
  };

  constructor(private _snackbar: SnackbarService) {
  }

  import<T>() {

    this.configuration?.CTFileUploaderConfiguration?.control.markAsTouched();

    if (!this.configuration?.CTFileUploaderConfiguration?.control.value) return;

    const file: File | null = (this.configuration?.CTFileUploaderConfiguration?.control.value ?? [])[0];

    if (!file || !this.configuration?.importSubscription) return;

    this.viewModel.loadingData = true;

    this.configuration
      .importSubscription(file)
      .subscribe({
        next: (response: CtWebapiGenericResponse<T>) => {

          this.viewModel.loadingData = false;
          this._snackbar.onSuccess();

          this.reset();

          if (this.configuration?.importSubscriptionCallBack) {

            this.configuration.importSubscriptionCallBack(response);

          }

        },
        error: () => this.viewModel.loadingData = false
      });

  }

  downloadTemplate() {

    if (!this.configuration?.template?.path) return;

    let link = document.createElement("a");
    link.setAttribute("type", "hidden");
    link.href = this.configuration.template.path;
    link.download = `${this.configuration.template.fileName ?? 'CTMTemplate'}.csv`;
    document.body.appendChild(link);
    link.click();
    link.remove();

  }

  reset() {

    this.fileUploader?.reset();
    this.fileUploader?.configuration.control.markAsUntouched();

  }

  ngOnDestroy() {

    this.reset();

  }

}
