import {Injectable} from "@angular/core";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {WorkOrderDocumentsWizardComponent} from "./work-order-documents-wizard/work-order-documents-wizard.component";
import {WorkOrderDocumentsConfiguration} from "./work-order-documents.configuration";

@Injectable()
export class WorkOrderDocumentsService {

  constructor(private dialog: MatDialog) {
  }

  open(parameter: WorkOrderDocumentsConfiguration | null) {

    return this.dialog
      .open(WorkOrderDocumentsWizardComponent, {
        data: parameter,
        maxWidth: 500,
        panelClass: 'work-order-file-upload-container'
      });

  }

}
