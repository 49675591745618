import {CTBase} from "@ctsolution/ct-base";

export class CTWorkOrderInformation<T> extends CTBase<CTWorkOrderInformation<T>> {

  Oid?: number;
  WorkOrder: { Oid: number } | any | null = null;
  private Content: string | null = null

  private constructor();
  private constructor(model?: CTWorkOrderInformation<T>)
  private constructor(model?: CTWorkOrderInformation<T>) {

    super();

    if (model) this.getClass(model);

  }

  public static create = <T>(model?: CTWorkOrderInformation<T>) => new CTWorkOrderInformation(model);

  setOid(value?: number) {

    this.Oid = value;
    return this;

  }

  setWorkOrderOid(value: number) {

    this.WorkOrder = {Oid: value};
    return this;

  }

  setContent<T>(value: T) {

    this.Content = JSON.stringify(value);
    return this;

  }

  getContent() {

    if (!this.Content) return null;

    return JSON.parse(this.Content) as T;

  }

}
