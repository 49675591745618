import {AfterViewInit, Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {
  CTModelDatatableFilter,
  CtBinaryOperator,
  CtDynamicFormGeneratorConfiguration,
  CtModelConfiguration,
  CtModelDatatableOperators,
  CtModelService, CTDynamicFormGeneratorComponent,
  CtDatatableContainerFilterValues,
} from '@ctsolution/ct-framework';
import {FormTemplateRouteData} from '../../../_core/route-data/form-template.route-data';
import {LeadDataRouteData} from '../../../_core/route-data/lead-data.route-data';
import {CtWebapiGenericResponse} from '@ctsolution/ct-webapi';
import { CustomerTypeEnum } from '../lead-edit/lead-edit-form/lead-edit-form.component';

@Component({
  selector: 'app-lead-dynamic-form',
  templateUrl: './lead-dynamic-form.component.html',
  styleUrls: ['./lead-dynamic-form.component.scss']
})
export class LeadDynamicFormComponent implements AfterViewInit {

  formConfigurations: Array<CtDynamicFormGeneratorConfiguration> = [];
  @Input() leadModelConfiguration: CtModelConfiguration<any> | null = null;
  @Input() datatableContainerFilterValues: CtDatatableContainerFilterValues | null = null;
  @Input() customerType : CustomerTypeEnum | null = null;
  @ViewChildren('formGenerator') formGeneratorComponents: QueryList<CTDynamicFormGeneratorComponent> | null = null;

  leadDataConfiguration = (): CtModelConfiguration<any> => CtModelConfiguration
    .create()
    .setRouteData(LeadDataRouteData());

  constructor(private modelService: CtModelService<any>) {
    
  }

  async ngAfterViewInit() {

    this.setup();

  }

  getValueFilterCodeLead(): string | null {

    let value : string | null = null;

    if (this.datatableContainerFilterValues) {

     value = this.datatableContainerFilterValues.constants.find((item) => item.Field === 'LeadType.Code')?.Value

    }

    return value

  }


  getValueFilterTypeCustomer(): string | null {

    let value : string | null = null;

    if (this.datatableContainerFilterValues) {

      value = this.datatableContainerFilterValues.constants.find((item) => item.Field?.includes('CustomerType.Type'))?.Value[0]
      //Do per scontato che venga filtrato solo per un code

      if(!value) value = this.datatableContainerFilterValues.constants.find((item) => item.Field?.includes('Customer.Type.Type'))?.Value[0]
      //Do per scontato che venga filtrato solo per un code
    }

    return value

  }

  setup(): Promise<void> {

    return new Promise<void>((resolve, reject) => {

      this.getLeadDynamicForms(this.getValueFilterTypeCustomer())
        .subscribe((response: CtWebapiGenericResponse<CtModelConfiguration<Array<any>>>) => {

          if (!response.Result.DataSource) {

            reject(new Error('Empty data source'));
            return;

          }

          (<Array<any>>response.Result.DataSource ?? [])
            .forEach(async (cnfg, idx) => {

              const dynamicFormConfiguration = CtDynamicFormGeneratorConfiguration
                .create()
                .setFormGeneratorModel(cnfg);

              let existingData = await this.getFormLeadDataValue(cnfg.Oid);

              dynamicFormConfiguration
                .setOnSubmit((filteredValue: any) => {

                  const parameter: LeadDynamicFormDataParameter = this.generateLeadDynamicFormDataParameter({
                    value: filteredValue.value,
                    formGeneratorOid: dynamicFormConfiguration?.formGeneratorModel?.Oid ?? -1,
                    leadOid: this.leadModelConfiguration?.RouteData?.id ?? -1
                  });

                  if (existingData?.Oid) {

                    parameter
                      .setOid(existingData.Oid)

                  }

                  this.insertLeadData(parameter)
                    ?.subscribe({
                      next: (response: CtWebapiGenericResponse<number | null>) => {

                        const updatedValue = response.Result ?? existingData?.Oid ?? -1;

                        if (!existingData) existingData = {} as LeadDynamicFormDataParameter
                        (<any>existingData)['Oid'] = updatedValue;

                      },
                      error: (err) => reject(err)
                    });

                });


              this.formConfigurations
                .push(dynamicFormConfiguration);

              if (existingData) {

                const parsedValue = JSON.parse(existingData.Content ?? '')

                setTimeout(() => this.setChildFormData(idx, parsedValue));

              }

            });

          resolve();

        });

    });

  }

  private setChildFormData(idx: number, value: any) {

    const references = this.formGeneratorComponents?.toArray() ?? [];

    const componentRef = references[idx];

    if (componentRef) {

      componentRef
        .form
        .patchValue(value);

    }

  }

  getFormLeadDataValue(formGeneratorOid: number): Promise<LeadDynamicFormDataParameter | null> {

    return new Promise((resolve, reject) => {

      this.getLeadData(formGeneratorOid)
        .subscribe((response: CtWebapiGenericResponse<CtModelConfiguration<Array<LeadDynamicFormDataParameter>>>) => {

            const result = (<LeadDynamicFormDataParameter[]>response.Result.DataSource ?? []);

            if (result.length) {

              const dataSource: LeadDynamicFormDataParameter = result[0];
              resolve(dataSource);

            }

            resolve(null);

          },
          (error) => reject(error)
        );
    });

  }

  getLeadData(formGeneratorOid: number) {

    const filters = CtModelDatatableOperators
      .create()
      .setFilters([
        CTModelDatatableFilter
          .create()
          .setField('Lead.Oid')
          .setValue(this.leadModelConfiguration?.RouteData?.id)
          .setOperatorType(CtBinaryOperator.Equal),
        CTModelDatatableFilter
          .create()
          .setField('FormTemplate.Oid')
          .setValue(formGeneratorOid)
          .setOperatorType(CtBinaryOperator.Equal)
      ]);

    return this.modelService
      .getList(this.leadDataConfiguration(), filters);

  }

  generateLeadDynamicFormDataParameter(params: { value: any, formGeneratorOid: number, leadOid: number }) {

    return LeadDynamicFormDataParameter
      .create()
      .setContent(JSON.stringify(params.value))
      .setFormGeneratorOid(params.formGeneratorOid)
      .setLead(params.leadOid);

  }

  insertLeadData(parameter: LeadDynamicFormDataParameter) {

    const leadConfiguration = this.leadDataConfiguration();

    if (parameter.Oid) {

      leadConfiguration
        .RouteData
        ?.setId(parameter.Oid);

    }

    return this.modelService
      .putInfos(leadConfiguration, parameter);

  }
  getLeadDynamicForms(valueCodeFilter: string | null) {

    const configuration: CtModelConfiguration<any> = CtModelConfiguration
      .create()
      .setRouteData(FormTemplateRouteData());

    const filters = CtModelDatatableOperators
      .create()
      .setFilters([
        CTModelDatatableFilter
          .create()
          .setField('Type')
          .setValue(1)
          .setOperatorType(CtBinaryOperator.Equal)
      ]);

      if(valueCodeFilter){

        const filter = CTModelDatatableFilter.create()
        .setField('Code')
        .setValue(valueCodeFilter)
        .setOperatorType(CtBinaryOperator.Equal);
        filters.Filters.push(filter);

      }

      else{

        filters.Filters.push(

          CTModelDatatableFilter
            .create()
            .setField("[Code] is null OR [Code] = ?")
            .setValue([ `${this.customerType == CustomerTypeEnum.DEFAULT ? null : this.customerType}` ])
            .setOperatorType(CtBinaryOperator.OrOperator)
        );

      }

    return this.modelService
      .getList(configuration, filters)

  }

}

export class LeadDynamicFormDataParameter {

  Oid?: number | null;
  FormTemplate: { Oid: number; } | null = null;
  Lead: { Oid: number; } | null = null;
  Content: string | null = null;

  public constructor() {
  }

  public static create = () => new LeadDynamicFormDataParameter();

  setOid(value: number) {

    this.Oid = value;
    return this;

  }

  setContent(value: string) {

    this.Content = value;
    return this;

  }

  setFormGeneratorOid(value: number) {

    this.FormTemplate = {
      Oid: value
    };
    return this;

  }

  setLead(value: number) {

    this.Lead = {
      Oid: value
    };
    return this;

  }

}
