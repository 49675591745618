import {Component, Input} from '@angular/core';
import {
  CTDatatablePaginationParameter,
  CTModelDatatableFilter,
  CtBinaryOperator,
  CtButtonConfiguration,
  CtModelConfiguration,
  MAT_RAISED_ACCENT
} from '@ctsolution/ct-framework';
import {CtWebapiGenericResponse} from '@ctsolution/ct-webapi';
import {CTMGeneralService} from 'projects/ct-manager/src/app/_core/lib/general.service';
import {CompanyRouteData} from 'projects/ct-manager/src/app/_core/route-data/company.route-data';

@Component({
  selector: 'app-lead-information',
  templateUrl: './lead-information.component.html',
  styleUrls: ['./lead-information.component.scss']
})
export class LeadInformationComponent {

  placeInformation: Array<any> = [];
  companyOid: number | null = null;
  @Input() leadOid: number | null = null;

  configurationButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("Aggiungi indirizzo")
    .setIcon("add")
    .setAction(() => this.goToCompany())
    .setMatherialOptions(MAT_RAISED_ACCENT);

  constructor(private generalService: CTMGeneralService) {
  }

  setup(companyOid: number | null) {

    if (companyOid == null) return;
    this.companyOid = companyOid;

    this.generalService.getList(
      {
        controller: "CompanyPlace",
        filters: [
          CTModelDatatableFilter
            .create()
            .setField("Company.Oid")
            .setValue(companyOid.toString())
            .setOperatorType(CtBinaryOperator.Equal)
        ],
        pagination: new CTDatatablePaginationParameter(1, 100)
      }
    ).subscribe((response: CtWebapiGenericResponse<any>) => {

      this.placeInformation = response.Result.DataSource;

    })


  }

  goToCompany() {

    const configuration = CtModelConfiguration.create().setRouteData(CompanyRouteData());

    this.generalService.navigateOnCTModelRouter(["customer", "private"], "edit", {

      value: {
        Oid: this.companyOid,
        LeadOid: this.leadOid,
      },
      configuration: configuration,
      qpKeys : ["LeadOid"],
      qpHandling : 'merge'

    });


  }


  reset() {

    this.placeInformation = [];

  }


  getContactTitle(type: ContactInformationTypeVO): string {

    switch (type) {
      case ContactInformationTypeVO.FirstPhone:
      case ContactInformationTypeVO.OtherPhone:
        return 'Telefono';
      case ContactInformationTypeVO.FirstCellPhone:
      case ContactInformationTypeVO.OtherCellPhone:
        return 'Cellulare';
      case ContactInformationTypeVO.FirstEmail:
      case ContactInformationTypeVO.OtherEmail:
        return 'Email';
      case ContactInformationTypeVO.PEC:
        return 'PEC';
      case ContactInformationTypeVO.SDI:
        return 'SDI';
      case ContactInformationTypeVO.ContactAssistent:
        return 'Assistente';
      case ContactInformationTypeVO.FAX:
        return 'FAX';
      case ContactInformationTypeVO.Ticket:
        return 'Ticket';
      default:
        return 'Informazione';
    }
  }

}

export class CompanyInformation {

  public constructor(public Name: string, public Surname: string, public Phone: string, public Email: string) {
  }

}

export enum ContactInformationTypeVO {
  NoSet = 0,
  FirstPhone = 1,
  OtherPhone = 2,
  FirstCellPhone = 5,
  OtherCellPhone = 6,
  ContactAssistent = 10,
  FAX = 11,
  Ticket = 12,
  FirstEmail = 15,
  OtherEmail = 16,
  PEC = 17,
  SDI = 18
}
