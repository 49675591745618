<div class="movement-manager-search-item-container">

  <div align="end">

    <button mat-mini-fab color="warn" (click)="close()">

      <mat-icon>close</mat-icon>

    </button>

  </div>

  <h1 mat-dialog-title>{{ 'search.offer' | translate }}</h1>

  <div mat-dialog-content>

    <app-offer-search
      [configuration]="offerSelectionConfiguration"
      (onSelected)="onSelect($event)"></app-offer-search>

  </div>

</div>
