import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CtBinaryOperator, CtCardConfiguration, CtCardIconConfiguration } from "@ctsolution/ct-framework";
import { CtManagerService } from "../../../_core/lib/ct-manager.service";
import { CompanyType } from "../../../_core/enum/company-type";

@Injectable()
export class DashboardCustomerHelper {

  constructor(private router: Router, private managerService : CtManagerService) { }
  getCustomerWidgets(cardList: CtCardConfiguration[]) {

    this.getCustomerBusinessWidgets(cardList);
    this.getCustomerPrivateWidgets(cardList);

  }

  private getCustomerBusinessWidgets(cardList: CtCardConfiguration[]) {

    this.managerService.getComponentOverride("dashboard", "contact_business", CONTACT_CARD_BUSINESS_CREATE).then((component) => {
        return;
    });

    const queryObject = {
      constants: [
        {
          Field: "Type.Type",
          OperatorType: CtBinaryOperator.Equal,
          Value: [CompanyType.Business]
        }
      ]
    };

    const queryString = JSON.stringify(queryObject);

    cardList
      .push(
        CONTACT_CARD_BUSINESS_CREATE
         .setAction(() => this.router.navigate(["/", "customer", "business","create"], { queryParams: { q: queryString } })))
  }

  private getCustomerPrivateWidgets(cardList: CtCardConfiguration[]) {

    this.managerService.getComponentOverride("dashboard", "contact", CONTACT_CARD_PRIVATE).then((component) => {
        return;
    });

    cardList
      .push(
        CONTACT_CARD_PRIVATE
         .setAction(() => this.router.navigate(["/", "customer", "private"])))
  }

}

export const CONTACT_CARD_BUSINESS_CREATE: CtCardConfiguration = CtCardConfiguration.create()
  .setTitle("dashboard.customerLabel")
  .setDescription("dashboard.contactsLabelDescription")
  .setIconSetting(
    CtCardIconConfiguration
      .create()
      .setClass("bg-info text-white")
      .setIcon("exposure_plus_1"));

export const CONTACT_CARD_PRIVATE: CtCardConfiguration = CtCardConfiguration.create()
  .setTitle("dashboard.customerLabel")
  .setDescription("dashboard.contactsLabelDescription")
  .setIconSetting(
    CtCardIconConfiguration
      .create()
      .setClass("bg-info text-white")
      .setIcon("exposure_plus_1"));
