import { Component, OnDestroy, ViewEncapsulation, ChangeDetectorRef } from "@angular/core";
import { LoaderService } from "@ctsolution/ct-webapi";

@Component({
  selector: "app-spinner",
  template: `
    <div class="preloader" [hidden]="!isSpinnerVisible">
      <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
    </div>`,
  encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnDestroy {

  public isSpinnerVisible = true;

  constructor(
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {

    this.loaderService
      .isLoading
      .subscribe((v) => {

        this.isSpinnerVisible = v;
        this.cdr.detectChanges();

      });

  }

  ngOnDestroy(): void {

    this.isSpinnerVisible = false;

  }

}
