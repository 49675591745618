<div class="company-contact-relation-container">

  <h1 mat-dialog-title> {{ "companyContactRelationTitle" | translate }} </h1>

  <div mat-dialog-content>

    <p> {{ "companyContactRelationDescription" | translate }}</p>

    <form [formGroup]="form">

      <ct-control [configuration]="control"></ct-control>

    </form>

  </div>

  <div mat-dialog-actions>

    <ct-button [configuration]="cancelButton"></ct-button>

    <ct-button [configuration]="addButton"></ct-button>

  </div>

</div>
