import {CtModelOperator} from "@ctsolution/ct-framework";
import {CtFieldCustomColumn} from "@ctsolution/ct-framework/lib/ct-datatable/_core/ct-field-custom.column";

export class CompanyContactConfiguration {

  CompanyContactFilter: CompanyContactFilter | null = null;
  Filterable = false;
  Operations: CtModelOperator[] | null = null;
  OnCreate: ((event: any) => void) | null = null;
  OnEdit: ((event: any) => void) | null = null;
  FieldsCustomTemplates: CtFieldCustomColumn[] | null = null;

  private constructor() {
  }

  public static create = () => new CompanyContactConfiguration();

  setCompanyContactFilter(value: CompanyContactFilter | null) {

    this.CompanyContactFilter = value;
    return this;

  }

  setFilterable(value: boolean) {

    this.Filterable = value;
    return this;

  }

  setOperations(value: CtModelOperator[] | null) {

    this.Operations = value;
    return this;

  }

  setOnCreate(value: ((event: any) => void) | null) {

    this.OnCreate = value;
    return this;

  }

  setOnEdit(value: ((event: any) => void) | null) {

    this.OnEdit = value;
    return this;

  }

  setFieldsCustomTemplates(value: CtFieldCustomColumn[] | null) {

    this.FieldsCustomTemplates = value;
    return this;

  }

}

export class CompanyContactFilter {

  Field: string | null = null;
  Value: number | null = null;

  private constructor() {
  }

  public static create = () => new CompanyContactFilter();

  setField(value: string | null) {

    this.Field = value;
    return this;

  }

  setValue(value: number | null) {

    this.Value = value;
    return this;

  }

}
