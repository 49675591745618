import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferFeeComponent } from './offer-fee.component';
import { CtModelModule } from '@ctsolution/ct-framework';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    CtModelModule,
    TranslateModule
  ],
  declarations: [OfferFeeComponent],
  exports : [OfferFeeComponent]
})
export class OfferFeeModule { }
