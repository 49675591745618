import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserWorkingHoursExportComponent} from './user-working-hours-export/user-working-hours-export.component';
import {MatLegacyDialogModule} from "@angular/material/legacy-dialog";
import {CtButtonModule, CtControlModule, CtFrameworkModule} from "@ctsolution/ct-framework";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {UserWorkingHoursExportService} from "./user-working-hours-export.service";
import {MonthControlComponent} from './user-working-hours-export/month-control/month-control.component';
import {YearControlComponent} from './user-working-hours-export/year-control/year-control.component';


@NgModule({
  declarations: [
    UserWorkingHoursExportComponent,
    MonthControlComponent,
    YearControlComponent
  ],
    imports: [
        CommonModule,
        MatLegacyDialogModule,
        CtButtonModule,
        ReactiveFormsModule,
        FormsModule,
        CtControlModule,
        CtFrameworkModule
    ],
  providers: [
    UserWorkingHoursExportService
  ]
})
export class UserWorkingHoursExportModule {
}
