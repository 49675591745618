<mat-card>

  <mat-card-content>

    <h3 style="margin-left: 10px;">{{"payment" | translate}}</h3>

    <ct-form [configuration]="formConfiguration"></ct-form>

    <mat-card-actions fxLayout="row wrap" fxLayoutAlign="space-between">

      <ct-button [configuration]="cancelButton"></ct-button>

      <ct-button [configuration]="submitButton"></ct-button>

    </mat-card-actions>

  </mat-card-content>

</mat-card>
