import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeadDynamicFormComponent } from './lead-dynamic-form.component';
import {CtFormGeneratorModule, CtModelModule} from '@ctsolution/ct-framework';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
    imports: [
        CommonModule,
        CtFormGeneratorModule,
        CtModelModule,
        TranslateModule

    ],
  declarations: [LeadDynamicFormComponent],
  exports:[LeadDynamicFormComponent]
})
export class LeadDynamicFormModule { }
