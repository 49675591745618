import {CtFileUploaderConfiguration} from "@ctsolution/ct-framework";
import {Validators} from "@angular/forms";

export const CT_MANAGER_IMPORT_CONFIGURATION = (): CtFileUploaderConfiguration => CtFileUploaderConfiguration
  .create()
  .setLimit(1)
  .setAcceptedFormats([
    ".csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel"
  ])
  .setControlValidators(Validators.required);
