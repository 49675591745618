export class WorkOrderPackagingConfiguration {

    WorkOrder: { Oid: number } | any | null = null;

    private constructor() {
    }
  
    public static create = () => new WorkOrderPackagingConfiguration();
  
    setWorkOrderOid(value: number | null) {
  
      this.WorkOrder = value ? {Oid: value} : null;
      return this;
  
    }
  

}