import {CTBase} from "@ctsolution/ct-base";
import {Injectable} from "@angular/core";

@Injectable({providedIn: 'root'})
export class UserCompanySettings extends CTBase<UserCompanySettings> {

  UserHasNotesEnabled: boolean | null = null;
  UserHasTranslationsEnabled: boolean | null = null;
  /**
   * TODO: Temporaneo per AB#9344, sarà poi da gestire lato API
   * */
  HandlingManagementDisabled: boolean | null = null;

  constructor();
  constructor(model?: UserCompanySettings)
  constructor(model?: UserCompanySettings) {

    super();

    if (model) this.getClass(model);

  }

  public static create = (model?: UserCompanySettings): UserCompanySettings => new UserCompanySettings(model);

}
