import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  CompanyContactRelationAddComponent
} from './company-contact-relation-add/company-contact-relation-add.component';
import {CompanyContactRelationService} from "./company-contact-relation.service";
import {MatLegacyDialogModule} from "@angular/material/legacy-dialog";
import {CtButtonModule, CtControlModule, CtFrameworkModule} from "@ctsolution/ct-framework";
import {ReactiveFormsModule} from "@angular/forms";
import {
  CompanyContactRelationEditComponent
} from './company-contact-relation-edit/company-contact-relation-edit.component';
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatLegacySlideToggleModule as MatSlideToggleModule} from "@angular/material/legacy-slide-toggle";

@NgModule({
  declarations: [
    CompanyContactRelationAddComponent,
    CompanyContactRelationEditComponent
  ],
  imports: [
    CommonModule,
    MatLegacyDialogModule,
    CtButtonModule,
    CtFrameworkModule,
    ReactiveFormsModule,
    CtControlModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatSlideToggleModule
  ],
  providers: [
    CompanyContactRelationService
  ]
})
export class CompanyContactRelationModule {
}
