import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppBlankComponent } from "./blank.component";
import { MatSidenavModule } from "@angular/material/sidenav";
import { RouterOutlet } from "@angular/router";

@NgModule({
  declarations: [AppBlankComponent],
  imports: [
    CommonModule,
    MatSidenavModule,
    RouterOutlet
  ],
  exports: [AppBlankComponent]
})
export class BlankModule {
}
