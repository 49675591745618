export class CompanyInformation {

  Oid?: number;
  CompanyOid: number | null = null;
  Content: any | null = null;
  Type: string | null = null;
  Sequence = 0;

  private constructor() {
  }

  public static create = () => new CompanyInformation();

  setCompanyOid(value: number | null) {

    this.CompanyOid = value;
    return this;

  }

  setContent(value: any | null) {

    this.Content = value;
    return this;

  }

  setType(value: string | null) {

    this.Type = value;
    return this;

  }

}
