<ng-container *ngIf="(model?.Handlings ?? []).length; else errorTemplate">

  <div class="module-create-handlings-cntr">

    <ng-container *ngIf="handlings.length">

      <p> {{ "ModuleCreateHandlings.MovementGenerationMessage" | translate }}: </p>

      <app-module-item-order #moduleItemOrder [handlings]="handlings"></app-module-item-order>

    </ng-container>

    <ng-container *ngIf="orders.length">

      <p> {{ "ModuleCreateHandlings.OrderGenerationMessage" | translate }}: </p>

      <app-module-item-order #moduleItemOrder [model]="model" [handlings]="orders"></app-module-item-order>

      <mat-divider></mat-divider>

      <small> {{ "ModuleCreateHandlings.NoteAfterOrders" | translate }} </small>

    </ng-container>

  </div>

  <p> {{ "confirmProceedMessage" | translate }}</p>

</ng-container>

<ng-template #errorTemplate>

  <p> {{ "moduleCreateErrors" | translate }} </p>

  <ng-container *ngIf="(model?.Errors ?? []).length">

    <ul>

      <li *ngFor="let error of model?.Errors"> {{ error }}</li>

    </ul>

  </ng-container>

</ng-template>
