import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentsComponent } from './payments.component';
import { RouterModule } from '@angular/router';
import { PaymentsListComponent } from './payments-list/payments-list.component';
import { PaymentsListModule } from './payments-list/payments-list.module';
import { PaymentsEditModule } from './payments-edit/payments-edit.module';
import { PaymentsEditComponent } from './payments-edit/payments-edit.component';

@NgModule({
  imports: [
    CommonModule,
    PaymentsListModule,
    PaymentsEditModule,
    RouterModule.forChild([
      {
        path: "",
        children: [
          {
            path: "edit/:id",
            component: PaymentsEditComponent
          },
          {
            path: "list",
            component: PaymentsListComponent
          },
          {
            path: "",
            redirectTo: "list",
            pathMatch: "full"
          }
        ]
      }
    ])
  ],
  declarations: [PaymentsComponent],
  exports : [PaymentsComponent]
})
export class PaymentsModule { }
