import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";
import { CtBinaryOperator, CtCardConfiguration, CtCardIconConfiguration } from "@ctsolution/ct-framework";
import { CompanyType } from "../../../_core/enum/company-type";
import { LeadStatusEnum } from "../../../components/lead/lead-edit/lead-edit-form/lead-edit-form.component";
import { CtManagerService } from "../../../_core/lib/ct-manager.service";

@Injectable()
export class DashboardLeadHelper {

  constructor(private router: Router, private managerService: CtManagerService) { }
  getLeadWidgets(cardList: CtCardConfiguration[]) {

    this.getCompletesWidget(cardList);
    this.getProspectsWidget(cardList);
    this.getPrivateWidget(cardList);
    this.getGeneralLeadWidget(cardList);

  }

  private getGeneralLeadWidget(cardList: CtCardConfiguration[]) {

    this.managerService.getComponentOverride("dashboard", "lead_list", LEAD_LIST_CARD).then((component) => {
      return;
    });

    this.managerService.getComponentOverride("dashboard", "lead_create", LEAD_CREATE_CARD).then((component) => {
      return;
    });

      cardList.push(LEAD_LIST_CARD.setAction(() => this.router.navigate(["/", "lead"])));
      cardList.push(LEAD_CREATE_CARD.setAction(() => this.router.navigate(["/", "lead", "create"])));

  }

  private getProspectsWidget(cardList: CtCardConfiguration[]) {

    this.managerService.getComponentOverride("dashboard", "lead_list_prospect", LEAD_LIST_PROSPECT_CARD).then((component) => {
      return;
    });

    this.managerService.getComponentOverride("dashboard", "lead_create_business", LEAD_CREATE_BUSINESS_CARD).then((component) => {
      return;
    });

    const queryObject = {
      constants: [
        {
          Field: "[CustomerType.Type] is not null AND [CustomerType.Type] = ?",
          OperatorType: CtBinaryOperator.OrOperator,
          Value: [CompanyType.Business]
        },
        {
          Field: "[Customer.Type.Type] is not null AND [Customer.Type.Type] = ?",
          OperatorType: CtBinaryOperator.OrOperator,
          Value: [CompanyType.Business]
        },
        {
          Field: "LeadStatus.Type",
          OperatorType: CtBinaryOperator.InOperator,
          Value: [LeadStatusEnum.IN_GESTIONE]
        }
      ]
    };

    const queryString = JSON.stringify(queryObject);
    const urlTree: UrlTree = this.router.createUrlTree(['/lead/list'], { queryParams: { q: queryString } });

    // Convertiamo l'UrlTree in una stringa e apriamo una nuova scheda
    const fullUrl = this.router.serializeUrl(urlTree);

      cardList.push(LEAD_LIST_PROSPECT_CARD.setAction(() => window.open(fullUrl, '_blank')));

      cardList.push(LEAD_CREATE_BUSINESS_CARD.setAction(() => this.router.navigate(["/", "lead", "create"], { queryParams: { q: queryString } })));

  }

  private getCompletesWidget(cardList: CtCardConfiguration[]) {

    this.managerService.getComponentOverride("dashboard", "lead_list_complete", LEAD_LIST_COMPLETE_CARD).then((component) => {
      return;
    });

    const queryObject = {
      constants: [
        {
          Field: "[CustomerType.Type] is not null AND [CustomerType.Type] = ?",
          OperatorType: CtBinaryOperator.OrOperator,
          Value: [CompanyType.Business]
        },
        {
          Field: "[Customer.Type.Type] is not null AND [Customer.Type.Type] = ?",
          OperatorType: CtBinaryOperator.OrOperator,
          Value: [CompanyType.Business]
        },
        {
          Field: "LeadStatus.Type",
          OperatorType: CtBinaryOperator.InOperator,
          Value: [LeadStatusEnum.COMPLETATO]
        }
      ]
    };

    const queryString = JSON.stringify(queryObject);
    const urlTree: UrlTree = this.router.createUrlTree(['/lead/list'], { queryParams: { q: queryString } });

    // Convertiamo l'UrlTree in una stringa e apriamo una nuova scheda
    const fullUrl = this.router.serializeUrl(urlTree);

      cardList.push(LEAD_LIST_COMPLETE_CARD.setAction(() => window.open(fullUrl, '_blank')));

  }

  private getPrivateWidget(cardList: CtCardConfiguration[]) {

    this.managerService.getComponentOverride("dashboard", "lead_create_private", LEAD_CREATE_PRIVATE_CARD).then((component) => {
      return;
    });

    const queryObject = {
      constants: [
        {
          Field: "[CustomerType.Type] is not null AND [CustomerType.Type] = ?",
          OperatorType: 53,
          Value: ["1"]
        },
        {
          Field: "[Customer.Type.Type] is not null AND [Customer.Type.Type] = ?",
          OperatorType: 53,
          Value: ["1"]
        }
      ]
    };
    const queryString = JSON.stringify(queryObject);

      cardList.push(LEAD_CREATE_PRIVATE_CARD.setAction(() => this.router.navigate(["/", "lead", "create"], { queryParams: { q: queryString } })));

  }

}

export const LEAD_LIST_PROSPECT_CARD: CtCardConfiguration = CtCardConfiguration.create()
  .setTitle("dashboard.prospect")
  .setVisible(false)
  .setDescription("dashboard.transitToLeadProspectList")
  .setIconSetting(
    CtCardIconConfiguration
      .create()
      .setClass("bg-ct-manager-alt text-white")
      .setIcon("build"));

      export const LEAD_LIST_COMPLETE_CARD: CtCardConfiguration = CtCardConfiguration.create()
  .setTitle("dashboard.leadComplete")
  .setVisible(false)
  .setDescription("dashboard.transitToLeadCompleteList")
  .setIconSetting(
    CtCardIconConfiguration
      .create()
      .setClass("bg-ct-manager-alt text-white")
      .setIcon("build"));

export const LEAD_LIST_CARD: CtCardConfiguration = CtCardConfiguration.create()
  .setTitle("dashboard.leadList")
  .setDescription("dashboard.leadListDescription")
  .setIconSetting(
    CtCardIconConfiguration
      .create()
      .setClass("bg-ct-manager-alt text-white")
      .setIcon("build"));

export const LEAD_CREATE_CARD: CtCardConfiguration = CtCardConfiguration.create()
  .setTitle("dashboard.leadCreate")
  .setDescription("dashboard.leadCreateDescription")
  .setIconSetting(
    CtCardIconConfiguration
      .create()
      .setClass("bg-ct-manager-alt text-white")
      .setIcon("build"));

export const LEAD_CREATE_BUSINESS_CARD: CtCardConfiguration = CtCardConfiguration.create()
  .setTitle("dashboard.leadCreateBusiness")
  .setVisible(false)
  .setDescription("dashboard.insertNewLeadBusiness")
  .setIconSetting(
    CtCardIconConfiguration
      .create()
      .setClass("bg-ct-manager-alt text-white")
      .setIcon("exposure_plus_1"));

export const LEAD_CREATE_PRIVATE_CARD: CtCardConfiguration = CtCardConfiguration.create()
  .setTitle("dashboard.leadCreatePrivate")
  .setVisible(false)
  .setDescription("dashboard.insertNewLeadPrivate")
  .setIconSetting(
    CtCardIconConfiguration
      .create()
      .setClass("bg-ct-manager-alt text-white")
      .setIcon("exposure_plus_1"));
