import {ComponentRef, Injectable} from "@angular/core";
import {CtWebapiGenericResponse, CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";
import {
  CtMenuConfiguration,
  CtThemeMenuConfiguration
} from "../../layouts/full/menu/_classes/ct-theme-menu.configuration";
import {ChildrenItems, Menu} from "../../layouts/full/menu/_classes/menu-item.interface";

const configurationMenu: Menu = {
  state: "ct-model",
  name: "configurations",
  type: "sub",
  icon: "admin_panel_settings",
  children: []
}

@Injectable({
  providedIn: 'root'
})
export class CtManagerService {

  private menuCache: Map<string, CtThemeMenuConfiguration> = new Map();
  private isFetching: boolean = false;
  private fetchQueue: (() => void)[] = [];

  constructor(private _webapi: CtWebapiService) {
  }

  async getMenuItems(): Promise<Menu[]> {

    const configuration = await this.getCtManagerConfiguration();

    let menuItems: Menu[] = [];
    let configurationItems: ChildrenItems[] = [];

    configuration
      .menu
      .sort((a: CtMenuConfiguration, b: CtMenuConfiguration) => (a.priority ?? -1) - (b.priority ?? -1))
      .forEach(menu => {

        menuItems.push(this.getSeparator((menu.code ?? '')));
        menuItems = menuItems.concat(menu.items);

        configurationItems = configurationItems.concat(menu.configurations);

      });

    if (configurationItems.length) {

      configurationMenu
        .children = configurationItems;

      menuItems
        .push(configurationMenu);

    }

    return menuItems;

  }

  private getSeparator(name: string) {

    return {
      state: "",
      name,
      type: "separator"
    } as Menu

  }

  private async getCtManagerConfiguration(): Promise<CtThemeMenuConfiguration> {

    const request: DataRequest = new DataRequest()
      .setController(['SiteConfiguration'])
      .setAction("MenuJson");

    const url = request.compose();

    if (this.menuCache.has(url)) return this.menuCache.get(url)!;

    // Se una richiesta è già in corso, attendi il completamento
    if (this.isFetching) {

      await new Promise<void>((resolve) => this.fetchQueue.push(resolve));
      return this.menuCache.get(url)!;

    }

    this.isFetching = true;

    try {

      const response: CtWebapiGenericResponse<any> = await this._webapi
        .get(request)
        .toPromise();

      const configuration: CtThemeMenuConfiguration = JSON.parse(response.Result) as CtThemeMenuConfiguration; // Parsa la risposta

      this.menuCache.set(url, configuration);

      // Risolvi tutte le promesse in coda
      this.fetchQueue.forEach((resolve) => resolve());
      this.fetchQueue = [];

      return configuration;

    } finally {

      this.isFetching = false;

    }

  }

  getComponentOverride = async (componentName: string, elementName: string, element: any) => {

    const configuration = await this.getCtManagerConfiguration();
    
    try{

      const component = configuration.configurations[componentName];

      if (!component) return null;

      const elementOverride = component[elementName];

      if (!elementOverride) return null;

      Object.keys(element).forEach(key => {
        key = key.replace("_", "");
        if (elementOverride[key] != null || elementOverride[key] != undefined) {
          element[key] = elementOverride[key];
        }
      });

      return element;

  }
  catch{

    return null;

  }
};

  hasEnabled = {
    CRM: () => this.menuIncludes(CtManagerModule.CRM),
    monitoring: () => this.menuIncludes(CtManagerModule.Monitoring),
    warehouse: () => this.menuIncludes(CtManagerModule.Warehouse),
    calendar: () => this.menuIncludes(CtManagerModule.Calendar)
  }

  private async menuIncludes(module: CtManagerModule) {
    const configuration = await this.getCtManagerConfiguration();

    return configuration
      .menu
      .map(elm => elm.code?.toLowerCase())
      .includes(module.toLowerCase());
  }

}

export enum CtManagerModule {

  Dashboard = "Dashboard",
  Billing = "Billing",
  CRM = "CRM",
  Monitoring = "Monitoring",
  Warehouse = "Warehouse",
  ControlPanel = "ControlPanel",
  Catalog = "Catalog",
  Calendar = "Calendar"

}
