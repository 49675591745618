import {Injectable} from "@angular/core";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import { LeadDocumentsConfiguration } from "./lead-documents.configuration";
import { WorkOrderDocumentsWizardComponent } from "../../work-order/work-order-edit/work-order-default-edit/work-order-documents/work-order-documents-wizard/work-order-documents-wizard.component";
import { LeadDocumentsWizardComponent } from "./lead-documents-wizard/lead-documents-wizard.component";

@Injectable()
export class LeadDocumentsService {

  constructor(private dialog: MatDialog) {
  }

  open(parameter: LeadDocumentsConfiguration | null) {

    return this.dialog
      .open(LeadDocumentsWizardComponent, {
        data: parameter,
        maxWidth: 500,
        panelClass: 'lead-file-upload-container'
      });

  }

}
