import { ItemsSupplierSelections } from "../controllers/item.controller";
import { ItemSuppliersTransfer } from "./item-suppliers-transfer";

export class TransferItemsRequestParameter {

  WorkOrderOid: number | null = null;
  LocationOid: number | null = null;
  Finalize: boolean = false;
  ItemSuppliers : ItemSuppliersTransfer[] = [];
  ItemsSupplierSelections : ItemsSupplierSelections[] = [];


  private constructor() {
  }

  public static create = (): TransferItemsRequestParameter => new TransferItemsRequestParameter();

  setWorkOrderOid(value: number): TransferItemsRequestParameter {

    this.WorkOrderOid = value;
    return this;

  }

  setLocationOid(value: number) {

    this.LocationOid = value;
    return this;

  }

  setFinalize(value: boolean) {

    this.Finalize = value;
    return this;

  }

  setItemSuppliers(value: ItemSuppliersTransfer[]) {

    this.ItemSuppliers = value;
    return this;

  }

  setItemsSupplierSelections(value : ItemsSupplierSelections[]) {

    this.ItemsSupplierSelections = value;
    return this;

  }


}
