import {Injectable} from "@angular/core";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {OfferSelectionComponent} from "./offer-selection/offer-selection.component";
import {OfferSelectionConfiguration} from "./offer-selection/offer-selection.configuration";
import { CTManagerOffer } from "../../../_core/classes/ct-manager-offer";

@Injectable()
export class OfferSelectionService {

  constructor(private dialog: MatDialog) {
  }

  open = (data: OfferSelectionConfiguration | null = null) => this.dialog.open(OfferSelectionComponent, {
    disableClose: true,
    data,
    minWidth: "80vw"
  });

}
