<div class="barcode-tracking-container">

  <h1 mat-dialog-title
      fxLayout="row wrap"
      fxLayoutAlign="space-between center"> {{ "barcodeTrackingLabel" | translate }}
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
  </h1>

  <div mat-dialog-content>

    <div [hidden]="mode === null && !data.selected">

      <p> {{ data.selected ? ('barcodeTracking.itemDetailLabel' | translate) : ('barcodeTracking.itemsToScanLabel' | translate)}}
        : </p>

      <ul>

        <li *ngFor="let item of items">

          <p>{{item.qtyControl.value}} x {{ item.CTManagerItemStock.ItemName }}
            <mat-icon *ngIf="item.barcodes.length === item.qtyControl.value" color="primary">check</mat-icon>
          </p>

          <mat-chip-list>

            <mat-chip
              *ngFor="let elm of item.barcodes; let i = index;"
              color="primary"> {{elm}} </mat-chip>

          </mat-chip-list>

        </li>

      </ul>

    </div>

    <div [hidden]="mode !== null">

      <p> {{"barcodeTracking.selectScanModeLabel" | translate }}: </p>

      <div
        fxLayout="row"
        fxLayoutAlign="space-between center">

        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="50" class="p-10">

          <button mat-flat-button color="primary"
                  (click)="selectMode(ScannerMode.Insert)"> {{ "barcodeTracking.insertionLabel" | translate }}
            <mat-icon>add_task</mat-icon>
          </button>

        </div>

        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="50" class="p-10">

          <button mat-flat-button color="warn" [disabled]="!barcodesCount" (click)="selectMode(ScannerMode.Remove)">
            {{ "barcodeTracking.removalLabel" | translate }}
            <mat-icon>backspace</mat-icon>
          </button>

        </div>

      </div>

    </div>

    <div [hidden]="mode === null">

      <mat-form-field>
        <mat-label>{{ "barcodeTracking.insertBarcodesLabel" | translate }}:</mat-label>
        <textarea
          #textarea
          matInput
          id="textarea"
          rows="10"
          [formControl]="control"
          (input)="onInput($event)"></textarea>
      </mat-form-field>

    </div>

  </div>

  <div mat-dialog-actions *ngIf="mode !== null && !!textarea.value">

    <ct-button [configuration]="previewButton"></ct-button>

  </div>

</div>
