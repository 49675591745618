import { Component, Input } from '@angular/core';
import { WorkOrderLocationConfiguration } from './work-order-location.configuration';
import { CtBinaryOperator, CtModelConfiguration, CTModelDatatableFilter, CtModelOperator, CtModelType } from '@ctsolution/ct-framework';
import { LocationRouteData } from 'projects/ct-manager/src/app/_core/route-data/location.route-data';
import { LocationMacroType } from 'projects/ct-manager/src/app/_core/enum/location.macro-type';
import { CTMGeneralService } from 'projects/ct-manager/src/app/_core/lib/general.service';

@Component({
  selector: 'ctm-work-order-location',
  templateUrl: './work-order-location.component.html',
  styleUrls: ['./work-order-location.component.scss']
})
export class WorkOrderLocationComponent {

  @Input() configuration: WorkOrderLocationConfiguration | null = null;
  boxConfiguration: CtModelConfiguration<any> | null = null;
  locationConfiguration: CtModelConfiguration<any> | null = null;


  constructor(private general : CTMGeneralService) {
  }

  ngAfterViewInit() {

    this.setup();

  }

  setup() {

    const basePathBox: string[] = ["location", "box"];

    const boxConfiguration = CtModelConfiguration
      .create<any>()
      .setRouteData(LocationRouteData().setModelType(CtModelType.DATATABLE))
      .setOnDetail(event => this.general.navigateOnCTModelRouter( basePathBox ,"detail", {
        value: event,
        configuration: this.boxConfiguration!,
        qpKeys: ["Name"]
      }))

    boxConfiguration
      .initialized()
      .subscribe(() => boxConfiguration?.setOperations([CtModelOperator.Detail, CtModelOperator.Delete]));

    boxConfiguration
      .filterValues
      .addConstantFilter(
        CTModelDatatableFilter
          .create()
          .setField("WorkOrder.Oid")
          .setValue(this.configuration?.WorkOrder?.Oid)
          .setOperatorType(CtBinaryOperator.Equal)
      )

    boxConfiguration
      .filterValues
      .addConstantFilter(
        CTModelDatatableFilter
          .create()
          .setField("Type.MacroType")
          .setValue(LocationMacroType.Box)
          .setOperatorType(CtBinaryOperator.Equal)
      )

    boxConfiguration
      .setFieldsCustomTemplates([
        { prop: "WorkOrder", visible: false }
      ])


    this.boxConfiguration = boxConfiguration;

    const basePathLocation: string[] = ["location", "stores"];

    const locationConfiguration = CtModelConfiguration
      .create<any>()
      .setRouteData(LocationRouteData().setModelType(CtModelType.DATATABLE))
      .setOnDetail(event => this.general.navigateOnCTModelRouter( basePathLocation ,"detail", {
        value: event,
        configuration: this.locationConfiguration!,
        qpKeys: ["Name"]
      }))

      .setAutoSetupBreadcrumbs(false);

    locationConfiguration
      .initialized()
      .subscribe(() => locationConfiguration?.setOperations([CtModelOperator.Detail]));

    locationConfiguration
      .filterValues
      .addConstantFilter(
        CTModelDatatableFilter
          .create()
          .setField("WorkOrder.Oid")
          .setValue(this.configuration?.WorkOrder?.Oid)
          .setOperatorType(CtBinaryOperator.Equal)
      )

    locationConfiguration
      .filterValues
      .addConstantFilter(
        CTModelDatatableFilter
          .create()
          .setField("Type.MacroType")
          .setValue(LocationMacroType.Box)
          .setOperatorType(CtBinaryOperator.NotEqual)
      )

    locationConfiguration
      .setFieldsCustomTemplates([
        { prop: "ParentLocation", visible: false },
        { prop: "WorkOrder", visible: false },

      ])

    this.locationConfiguration = locationConfiguration;

  }

}
