import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SupplierChangeConfirmComponent} from './supplier-change-confirm.component';
import {MatLegacyDialogModule} from "@angular/material/legacy-dialog";
import {CtButtonModule, CtFrameworkModule} from "@ctsolution/ct-framework";
import {SupplierChangeConfirmService} from "./supplier-change-confirm.service";

@NgModule({
  declarations: [
    SupplierChangeConfirmComponent
  ],
    imports: [
        CommonModule,
        MatLegacyDialogModule,
        CtButtonModule,
        MatLegacyDialogModule,
        CtFrameworkModule
    ],
  providers: [
    SupplierChangeConfirmService
  ]
})
export class SupplierChangeConfirmModule {
}
