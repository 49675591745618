<h1 mat-dialog-title> {{ "Note" | translate }}</h1>
<form mat-dialog-content [formGroup]="form">
  <ct-control [configuration]="control"></ct-control>
</form>
<div mat-dialog-actions>

  <ct-button [configuration]="cancelButton"></ct-button>

  <ct-button [configuration]="submitButton"></ct-button>

</div>
