import {DataRequest} from "@ctsolution/ct-webapi";
import {Injectable} from "@angular/core";
import {CTManagerFile} from "../classes/ct-manager-file";
import {UserWorkingHoursFileRouteData} from "../route-data/user-working-hours-file.route-data";
import {FileService} from "../lib/file.service";

const CONTROLLER_REQUEST = (): DataRequest => new DataRequest().setController([UserWorkingHoursFileRouteData().controller!]);

@Injectable()
export class UserWorkingHoursFileController {

  constructor(private fileService: FileService,) {
  }

  create(file: CTManagerFile) {

    return this.fileService.create<CTManagerFile>(CONTROLLER_REQUEST(), file);

  }

  delete(file: CTManagerFile) {

    return this.fileService.delete<CTManagerFile>(CONTROLLER_REQUEST().controller!, file);

  }

}
