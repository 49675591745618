import {Injectable} from "@angular/core";
import {CtSerializerHelperService, CtWebapiService, DataRequest, MethodEnum} from "@ctsolution/ct-webapi";
import {WorkOrderImportParameter} from "../classes/work-order-import.parameter";
import {WorkOrderRouteData} from "../route-data/work-order.route-data";
import {WorkOrderFileImportParameter} from "../classes/work-order-xml-import.parameter";
import {TransferItemsRequestParameter} from "../classes/transfer-items-request-parameter";

const CONTROLLER_REQUEST = (): DataRequest => new DataRequest().setController([WorkOrderRouteData().controller!]);

@Injectable()
export class WorkOrderController {

  constructor(private _webapi: CtWebapiService, private serializerHelperService: CtSerializerHelperService) {
  }

  importItems(parameter: WorkOrderImportParameter) {

    const request: DataRequest = CONTROLLER_REQUEST()
      .setAction("ImportItems")
      .setMethod(MethodEnum.POST)
      .setQueryParams({
        hideSpinner: true
      })
      .setBody(this.serializerHelperService.objectToFormData(parameter));

    return this._webapi
      .request(request);

  }

  transferItemsToLocation(parameter: TransferItemsRequestParameter) {

    const request: DataRequest = CONTROLLER_REQUEST()
      .setAction('TransferItemsToLocation')
      .setMethod(MethodEnum.POST)
      .setBody(parameter);

    return this._webapi
      .request(request);

  }

  importItemsFromFile(parameter: WorkOrderFileImportParameter) {

    const request: DataRequest = CONTROLLER_REQUEST()
      .setAction("ImportItems")
      .setBody(this.serializerHelperService.objectToFormData(parameter))
      .setMethod(MethodEnum.POST);

    return this._webapi
      .request(request);

  }

}
