import {Injectable} from '@angular/core';
import {
  CompanyContactRelationAddComponent
} from "./company-contact-relation-add/company-contact-relation-add.component";
import {MatLegacyDialog} from "@angular/material/legacy-dialog";
import {
  CtModelConfiguration,
  CtModelOnFlyCreateService,
  CtModelRouteData,
  CtModelService,
  CtModelType
} from "@ctsolution/ct-framework";
import {ContactRouteData} from "../../../_core/route-data/contact.route-data";
import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";
import {ContactCompanyRouteData} from "../../../_core/route-data/contact-company.route-data";
import {ContactCompany} from "../contact-company";
import {
  CompanyContactRelationEditComponent
} from "./company-contact-relation-edit/company-contact-relation-edit.component";

@Injectable()
export class CompanyContactRelationService {

  constructor(private dialog: MatLegacyDialog, private onFlyCreateHelper: CtModelOnFlyCreateService, private modelService: CtModelService<any>) {
  }

  /**
   *
   * @returns {Promise<number>} A Promise that resolves with a positive integer if the contact exists,
   *                           -1 if a new contact needs to be created, or undefined if no action is taken.
   */
  openBinder(): Promise<number> {

    return new Promise<number>((resolve) => {

      this
        .dialog
        .open(CompanyContactRelationAddComponent, {maxWidth: '500px'})
        .afterClosed()
        .subscribe(result => resolve(result))

    })

  }

  createContact(): Promise<any | null> {

    const routeData: CtModelRouteData = CtModelRouteData
      .create()
      .setController(ContactRouteData().controller)
      .setModelType(CtModelType.FORM);

    const configuration: CtModelConfiguration<any> | null = CtModelConfiguration
      .create()

    configuration
      .setRouteData(routeData)

    return new Promise<any | null>(resolve => {

      this.onFlyCreateHelper
        .openOnFlyCreate(configuration)
        .afterClosed()
        .subscribe((response: CtWebapiGenericResponse<number> | null) => {

          const result = {...configuration.form?.value, Oid: response?.Result};
          resolve(result)

        });

    })

  }

  companyContactRelationInsert(value: ContactCompany) {

    const routeData = ContactCompanyRouteData()
      .setModelType(CtModelType.FORM);

    if (value.Oid) {

      routeData
        ?.setId(value.Oid);

    }

    const companyContactConfiguration: CtModelConfiguration<any> = CtModelConfiguration
      .create()
      .setRouteData(routeData);

    return this.modelService
      .putInfos(companyContactConfiguration, value);

  }

  companyContactRelationEdit(data: ContactCompany) {

    return new Promise<number>((resolve) => {

      this
        .dialog
        .open(CompanyContactRelationEditComponent, {data, maxWidth: '500px'})
        .afterClosed()
        .subscribe(result => resolve(result))

    })

  }

}
