<form [formGroup]="form" [fxLayout]="'row wrap'">

  <div
    class="control-cntr"
    [fxFlex]="100"
    [fxFlex.sm]="100"
    [fxFlex.xs]="100"
    [hidden]="this.configuration">

    <ct-file-uploader
      #Uploader
      [configuration]="CTFileUploaderConfiguration"></ct-file-uploader>

  </div>

 <div
    class="control-cntr"
    style="margin-bottom: 5px"
    [fxFlex]="100"
    [fxFlex.sm]="100"
    [fxFlex.xs]="100">

    <ct-control [configuration]="noteControl"></ct-control>

  </div> 

  <mat-card-actions
    [fxFlex]="100"
    [fxFlex.sm]="100"
    [fxFlex.xs]="100"
    fxLayout="row wrap" fxLayoutAlign="space-between">

    <ct-button [configuration]="cancelButton"></ct-button>

    <ct-button [configuration]="submitButton"></ct-button>

  </mat-card-actions>

</form>
