<form
  style="margin-bottom: 15px"
  (keydown.enter)="onSearch()"
  [formGroup]="form">

  <ct-control [configuration]="offerSearchControl"></ct-control>

  <div
    fxLayout="row wrap"
    fxLayoutAlign="space-between center">

    <div>

      <ct-button [configuration]="searchButton"></ct-button>

    </div>

  </div>

</form>
