import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {CompanyEditComponent} from "./company-edit/company-edit.component";
import {CompanyListComponent} from "./company-list/company-list.component";
import {CompanyListModule} from "./company-list/company-list.module";
import {CompanyEditModule} from "./company-edit/company-edit.module";
import {CompanyService} from "./company.service";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    CompanyListModule,
    CompanyEditModule,
    RouterModule.forChild([
      {
        path: ":type",
        children: [
          {
            path: "",
            redirectTo: "list",
            pathMatch: "full"
          },
          {
            path: "create",
            component: CompanyEditComponent
          },
          {
            path: "edit/:id",
            component: CompanyEditComponent
          },
          {
            path: "list",
            component: CompanyListComponent
          }
        ]
      }
    ])
  ],
  providers: [CompanyService]
})
export class CompanyModule {
}
