import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { LeadFileCreateParameter } from '../lead-documents-wizard.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CtFileUploaderConfiguration, CtButtonConfiguration, MAT_RAISED_WARN, MAT_RAISED_ACCENT, CtControlTypes, CtThemeTypes } from '@ctsolution/ct-framework';
import { CTMGeneralService } from 'projects/ct-manager/src/app/_core/lib/general.service';

@Component({
  selector: 'app-lead-documents-form',
  templateUrl: './lead-documents-form.component.html',
  styleUrls: ['./lead-documents-form.component.scss']
})
export class LeadDocumentsFormComponent implements AfterViewInit {

  form: FormGroup;
  @Input() configuration: LeadFileCreateParameter | null = null;
  @Output() onSubmit: EventEmitter<LeadFileCreateParameter> = new EventEmitter<LeadFileCreateParameter>();
  @Output() onCancel: EventEmitter<LeadFileCreateParameter> = new EventEmitter<LeadFileCreateParameter>();

  formValue : any | null = null;

  CTFileUploaderConfiguration: CtFileUploaderConfiguration = CtFileUploaderConfiguration
    .create()
    .setTitle(null)
    .setLimit(1)
    .setAcceptedFormats(['application/pdf', 'image/jpeg', 'image/png', 'text/html',".csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel"])
    .setControlValidators([Validators.required])

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.cancel")
    .setAction(() => this.onCancel.emit())
    .setMatherialOptions(MAT_RAISED_WARN);

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setClass('ct-model-submit-button')
    .setAction(() => this.submit())
    .setMatherialOptions(MAT_RAISED_ACCENT);

  noteControl = this.CTMGeneralService.getTextControl({
    name: 'Note',
    required: false,
  })
    .setType(CtControlTypes.TEXTAREA)
    .setFlexSpacePercentage(100)
    .setTheme(CtThemeTypes.MATERIAL);


  constructor(    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private CTMGeneralService: CTMGeneralService) {

      this.form = this.formBuilder.group({});

    }
    ngAfterViewInit() {

      this.setup();

      this.cdr.detectChanges();

    }

    setup() {

      this.noteControl
        .setFormParent(this.form);

      this.form
        .addControl('File', this.CTFileUploaderConfiguration.control)

        if(this.configuration) {

          this.noteControl.setValue(this.configuration.Note);
          this.formValue = this.configuration;

        }


    }

    submit() {

        if(this.formValue){

          const value = this.form.value;

          const parameter = LeadFileCreateParameter
          .create()
          .setNote(value.Note)
          .setOid(this.formValue.Oid)


          this.onSubmit.emit(parameter)
          return
        }

      this.form.markAllAsTouched();

      if (this.form.invalid) return;

      const value = this.form.value;

      const parameter = LeadFileCreateParameter
        .create()
        .setFile(value.File)

      if (value.Note) {

        parameter
          .setNote(value.Note)

      }

      this.onSubmit.emit(parameter)

    }


}
