import {ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {
  CtBinaryOperator, CtButtonConfiguration,
  CtControlConfiguration,
  CtControlTypes,
  CTDatatablePaginationParameter,
  CtFormConfiguration, CTGeneralService,
  CTModelDatatableFilter,
  CtModelDatatableOperators,
  CtSelectControlOptions,
  CtSelectControlValue,
  CtSortOrderDescriptorParameter,
  CtThemeTypes, MAT_RAISED_ACCENT, MAT_RAISED_PRIMARY, MAT_RAISED_WARN,
} from "@ctsolution/ct-framework";
import {FormBuilder} from "@angular/forms";
import {CTWorkOrder} from "../../../../../_core/classes/ct-work-order";
import {WorkOrderService} from "../../../work-order.service";
import {CTMWorkOrderViewType} from "../../../../../_core/enum/work-order-type";
import {GeneralDataConfiguration} from "./general-data.configuration";
import {CtWebapiGenericResponse, DataRequest, MethodEnum} from "@ctsolution/ct-webapi";
import {CtWorkOrderInformationContent} from "../../../../../_core/classes/ct-work-order.information-content";
import {WorkOrderImportParameter} from "../../../../../_core/classes/work-order-import.parameter";
import {WorkOrderController} from "../../../../../_core/controllers/work-order.controller";
import {WorkOrderFileImportParameter} from "../../../../../_core/classes/work-order-xml-import.parameter";
import {CTMGeneralService} from 'projects/ct-manager/src/app/_core/lib/general.service';
import {debounceTime, distinctUntilChanged} from "rxjs";
import {
  WorkOrderImportFileConfigurationDialogService
} from "../../work-order-import-file-configuration-dialog/work-order-import-file-configuration-dialog.service";
import {WorkOrderAttachmentsService} from './work-order-attachments.service';
import {LeadRedirectButtonConfiguration} from "./lead-redirect-button/lead-redirect-button.configuration";
import { WorkOrderStatusEnum } from 'projects/ct-manager/src/app/_core/enum/work-order-state';
import { CtManagerService } from 'projects/ct-manager/src/app/_core/lib/ct-manager.service';

@Component({
  selector: 'ctm-general-data',
  templateUrl: './general-data.component.html',
  providers: [WorkOrderController]
})
export class GeneralDataComponent {

  @Output() onSubmit: EventEmitter<Date | null> = new EventEmitter<Date | null>();
  @Input() configuration: GeneralDataConfiguration | null = null;
  @Input() creaPackagingButtonConfiguration: CtButtonConfiguration | null = null;
  //input creaPackagingButtonConfiguration

  formConfiguration: CtFormConfiguration | null = null;
  leadButtonConfiguration: LeadRedirectButtonConfiguration | null = null;
  isNotClosedState = true;

  get isQuotation() {

    return this.configuration?.workOrderType === CTMWorkOrderViewType.Quotation;

  }

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.cancel")
    .setAction(() => this.CTGeneralService.back())
    .setMatherialOptions(MAT_RAISED_WARN);

  acceptQuotationButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("AcceptQuotation")
    .setAction(() => {

      this.formConfiguration?.form?.markAllAsTouched();
      this.onSubmit.emit(new Date());

    })
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setClass('ct-model-submit-button')
    .setAction(() => {

      this.formConfiguration?.form?.markAllAsTouched();
      this.onSubmit.emit();

    })
    .setMatherialOptions(MAT_RAISED_ACCENT);

  private workOrderStateLookup = this.generalService.getLookupControlDefaultConfiguration({
    name: 'WorkOrderState',
    controller: 'WorkOrderState',
    type: CtControlTypes.ENUMERABLE
  });
  private customerLookup = this.generalService.getLookupControlDefaultConfiguration({
    name: 'Customer',
    controller: 'Company',
    key: 'ReferenceName'
  })
    .configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        .setLookupFilter(value => {

          const filters: CTModelDatatableFilter[] = [CTModelDatatableFilter.create().setField('IsCustomer').setValue(true).setOperatorType(CtBinaryOperator.Equal)];

          if (value) {

            filters.push(CTModelDatatableFilter.create().setField('ReferenceName').setValue(value).setOperatorType(CtBinaryOperator.Contains))

          }

          const operators = CtModelDatatableOperators.create().setFilters(filters);

          operators
            .Pagination
            ?.setSortOrders(new Array<CtSortOrderDescriptorParameter>(CtSortOrderDescriptorParameter.create('ReferenceName')))

          return operators;

        })
        .setEnableAddOptionButton(true)
        .setOnAddOptionButtonClick(() => new Promise<CtWebapiGenericResponse<number> | null>((resolve) => {

          this.workOrderService
            .createCustomer()
            .then((result) => {

              if (result.Oid) {

                this.generalService.getInfos({ controller: 'company', oid: result.Oid }).subscribe((response: CtWebapiGenericResponse<any>) => {

                  const res = response.Result.DataSource;
                  const label = res.ReferenceName ?? res.Name + res.Surname;

                  setTimeout(() => {

                    this.customerLookup
                      ?.setValue(
                        CtSelectControlValue
                          .create()
                          .setLabel(label)
                          .setValue(result.Oid))

                  }, 100)

                });

              }

              resolve(result)

            })

        }))

    })

  private addressLookup = this.generalService.getLookupControlDefaultConfiguration({
    name: "Address",
    controller: "CompanyPlace"
  })
    .setFlexSpacePercentage(100)
    .configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        ?.setForceSelection(false)
        ?.setLookupFilter(value => {

          const filters: CTModelDatatableFilter[] = [];

          const customerControl = this.customerLookup.control;

          if (customerControl?.valid) {

            const customerControlValue = customerControl.value;
            const customerOid = customerControlValue?.value;

            filters.push(CTModelDatatableFilter.create().setField('Company.Oid').setValue(customerOid).setOperatorType(CtBinaryOperator.Equal));

          } else {

            customerControl?.markAsTouched();

          }

          if (value) {

            filters.push(CTModelDatatableFilter.create().setField('Name').setValue(value).setOperatorType(CtBinaryOperator.Contains))

          }

          const operators = CtModelDatatableOperators.create().setFilters(filters);

          operators
            .Pagination
            ?.setSortOrders(new Array<CtSortOrderDescriptorParameter>(CtSortOrderDescriptorParameter.create('Name')))

          return operators;

        })

    });

  private extraDataJsonControl = CtControlConfiguration
    .create()
    .setRemoteValueOptionsEndpoint(
      DataRequest
        .create()
        .setUrl(`/SpecificInfoWorkOrder/FieldsByType/1?hideSpinner=true`)
        .setMethod(MethodEnum.GET)
        .setBody({}))
    .setLabel('ExtraDataJSON')
    .setName('ExtraDataJSON')
    .setType(CtControlTypes.EXTRA_DATA);

  private genericQuotationControls = [
    this.generalService.getTextControl({
      name: 'Name',
      required: true,
      flexSpacePercentage: 50
    }).setLabel('ReferenceNameWorkOrder'),
    this.generalService.getTextControl({name: 'Code', required: false, flexSpacePercentage: 50}),
    this.workOrderStateLookup,
    this.customerLookup,
    this.addressLookup,
    this.generalService.getTextControl({name: 'Description'}).setType(CtControlTypes.TEXTAREA).setFlexSpacePercentage(100),
  ];

  private genericWorkOrderAdditionalControls = [
    this.generalService.getDateControlDefault({name: 'SupplyStartDate', required: false, flexSpacePercentage: 50}),
    this.generalService.getDateControlDefault({
      name: 'SupplyExpirationDate',
      required: false,
      flexSpacePercentage: 50
    })
  ]

  constructor(
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private ctManagerService : CtManagerService,
    private CTGeneralService: CTGeneralService,
    private generalService: CTMGeneralService,
    private workOrderService: WorkOrderService,
    private workOrderController: WorkOrderController,
    private workOrderImportFileConfigurationDialogService: WorkOrderImportFileConfigurationDialogService,
    private workOrderAttachments: WorkOrderAttachmentsService) {
  }

  async ngAfterViewInit() {

    const controls = this.genericQuotationControls;

   await this.ctManagerService.getComponentOverride("workOrder", "generalDataConfiguration", this.configuration).then((component) => {
      return;
    });

    switch (this.configuration?.workOrderType) {

      case CTMWorkOrderViewType.Standard:

        if (this.configuration.extraDataJsonEnabled) {

          this.genericWorkOrderAdditionalControls
            .push(this.extraDataJsonControl);

        }

        controls
          .push(...this.genericWorkOrderAdditionalControls);

        break;
      default:
        break;

    }

    this.formConfiguration = CtFormConfiguration
      .create(this.formBuilder.group({}))
      .setTheme(CtThemeTypes.MATERIAL)
      .setControls(controls);

    this.customerLookup
      .control
      ?.valueChanges
      .pipe(
        debounceTime(100),
        distinctUntilChanged())
      .subscribe(() => {
        this.addressLookup.setValue(null);
        this.addressLookup.setValue('');
      });

    if (this.configuration?.WorkOrder.Oid) {

      this.leadButtonConfiguration = LeadRedirectButtonConfiguration
        .create()
        .setWorkOrderOid(this.configuration?.WorkOrder.Oid);

    }

    this.cdr.detectChanges();

  }

  import(event: any) {

    const files = (event.target as HTMLInputElement).files ?? [];

    if (!files.length) return;

    const file = files[0];

    const filters: CTModelDatatableFilter[] = [];
    const pagination = new CTDatatablePaginationParameter(1, 100);


    this.generalService.getList({

      controller : "FileImportConfiguration",
      filters : filters,
      pagination : pagination

    }).subscribe((response: CtWebapiGenericResponse<any>) => {


      if(!response.Result.DataSource || response.Result.DataSource.length < 1) return;

      const result = response.Result.DataSource;

      if(result.length === 1) this.importFile(file, result[0].Code);
      else this.importFile(file, null);



    })
  }

  private importFile(file: File, code : string | null) {

    if (!this.configuration?.WorkOrder.Oid) return;

    this.workOrderImportFileConfigurationDialogService
      .open()
      .afterClosed()
      .subscribe((result: any | null) => {

        if(result.Code == null) result.Code = code;

        if (result.Code) {

          const parameter: WorkOrderFileImportParameter = WorkOrderFileImportParameter
            .create()
            .setWorkOrderOid(+this.configuration!.WorkOrder.Oid)
            .setFile(file)
            .setCode(result.Code)
            .setOverwriteSameCodeItems(result.OverwriteSameCodeItems);

          this.workOrderController.importItemsFromFile(parameter)
            .subscribe({
              next: () => {

                this.reloadCurrentPath();

              }

            })

        }

      })

  }

  private importGenericFile(file: File) {

    if (!this.configuration?.WorkOrder.Oid) return;

    const parameter: WorkOrderImportParameter = WorkOrderImportParameter
      .create()
      .setWorkOrderOid(+this.configuration.WorkOrder.Oid)
      .setFile(file);

    this.workOrderController
      .importItems(parameter)
      .subscribe({
        next: (response: any) => {

          if (!response.Result) return;

          const result: WorkOrderImportErrorsResult = response.Result;

          if (result.Errors.length > 0) {
            this.workOrderService.errorsDialog(result).then(() => {
              if (result.ImportIsValid) this.reloadCurrentPath();
            }).catch(error => {
              console.error("Errore nella chiusura della dialog:", error);
            });
          } else {
            this.reloadCurrentPath();
          }

        }

      })

  }

  private reloadCurrentPath(): void {

    let currentPath = window.location.pathname;

    const hasQuestionMark = currentPath.includes('?');

    if (!hasQuestionMark) currentPath += '?rows=true';

    window.location.href = window.location.origin + currentPath;

  }

  setup(model: CTWorkOrder<CtWorkOrderInformationContent> | null) {

    if (!model) return;

    this.formConfiguration?.form?.patchValue(model);

    if (model.State) {

      this.formConfiguration?.form?.get('WorkOrderState')?.setValue(model.State.Oid);

      this.isNotClosedState = model.State.Type != WorkOrderStatusEnum.Closed;

    }

    if (model.ExpiredAt) {

      this.formConfiguration?.form?.get('SupplyExpirationDate')?.setValue(model.ExpiredAt);

    }

    if (model.StartAt) {

      this.formConfiguration?.form?.get('SupplyStartDate')?.setValue(model.StartAt);

    }

    if (model.Company) {

      this.customerLookup
        .setValue(
          CtSelectControlValue
            .create()
            .setLabel(model.Company.ReferenceName)
            .setValue(model.Company.Oid))

    }

    if (model.Address) {

      setTimeout(() => {

        this.addressLookup
          .setValue(
            CtSelectControlValue
              .create()
              .setLabel(model.Address)
              .setValue(model.Address))

      }, 100)

    }

    this.cdr.detectChanges();

  }

  async PutFileAttachments() {

    const ref = await this.workOrderAttachments.open(this.configuration?.WorkOrder);

    ref
      .afterClosed()
      .subscribe(result => {

        (event as any)['FilesAttachedCount'] = result ?? 0

      })

  }

}

export class WorkOrderImportErrorsResult {

  Errors: any[] = [];
  ImportIsValid: boolean;

  private constructor(errors: any[], impostIsValid: boolean) {

    this.Errors = errors;
    this.ImportIsValid = impostIsValid;

  }

}
