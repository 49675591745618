import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {CtSerializerHelperService, CtWebapiService, DataRequest, MethodEnum} from "@ctsolution/ct-webapi";
import {Observable} from "rxjs";
import {FileGet} from "../classes/file-get";

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(
    private _webapi: CtWebapiService,
    private serializerHelperService: CtSerializerHelperService) {
  }

  blobDownload(blob: Blob, filename = `${environment.projectName}_${new Date().getTime()}`) {

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    document.body.appendChild(a);

    a.setAttribute("style", "display: none");
    a.href = url;
    a.download = filename;
    a.click();

    window.URL.revokeObjectURL(url);
    a.remove();

  }

  create<T>(request: DataRequest, parameter: T) {

    request
      .setAction('Create')
      .setBody(this.serializerHelperService.objectToFormData(parameter))
      .setMethod(MethodEnum.POST)
      .setQueryParams({hideSpinner: true});

    return this._webapi
      .request(request);

  }

  delete<T>(controller: string[], parameter: T | any) {

    if (!parameter.Oid) return;

    const request: DataRequest = new DataRequest()
      .setController([...controller, 'DeleteByOid'])
      .setAction(parameter.Oid)
      .setMethod(MethodEnum.DELETE)
      .setQueryParams({hideSpinner: true});

    return this._webapi
      .request(request);

  }

  getFile(request: DataRequest): Observable<Blob> {

    request
      .setResponseType("blob");

    if (!request.method) {

      request
        .setMethod(MethodEnum.GET)

    }

    return this._webapi
      .request(request);

  }

  downloadAttachmentByEndPoint(value: FileGet | null, filename : string | null = null) {

    if (!value) return;

    const request: DataRequest = DataRequest
      .create()
      .setUrl(value.Endpoint)
      .setResponseType("blob");

    this._webapi
      .get(request)
      .subscribe(result => {

        if (result) {

          if(!filename)
            window.open(URL.createObjectURL(result));

          else{

          const url = URL.createObjectURL(result);
          // Creiamo un link di download
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = filename;

          // Simuliamo un click sul link
          document.body.appendChild(a);
          a.click();

          // Rimuoviamo il link e rilasciamo l'oggetto URL
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        }

        }

      })

  }

}
