import {Component, Input, ViewChild} from '@angular/core';
import {MovementManagerConfiguration} from "./movement-manager.configuration";
import {MovementManagerInsertModeEnum} from "./movement-manager-insert-mode/movement-manager-insert-mode.enum";
import {MovementManagerAddItemService} from "./movement-manager-add-item/movement-manager-add-item.service";
import {MovementManagerRowsComponent} from "./movement-manager-rows/movement-manager-rows.component";
import {CTWorkOrder} from "../../../../../_core/classes/ct-work-order";
import {CtWorkOrderInformationContent} from "../../../../../_core/classes/ct-work-order.information-content";
import {CTWorkOrderRowUnionType} from "../../../../../_core/classes/ct-work-order.row-union";
import { MovementManagerAddOfferService } from './movement-manager-add-offer/movement-manager-add-offer.service';

@Component({
  selector: 'ctm-movement-manager',
  templateUrl: './movement-manager.component.html'
})
export class MovementManagerComponent {

  @ViewChild('movementManagerRowsComponent') movementManagerRowsComponent: MovementManagerRowsComponent | null = null;
  @Input() configuration: MovementManagerConfiguration | null = null;

  constructor(private movementManagerAddItemService: MovementManagerAddItemService, private movementManagerAddOfferService: MovementManagerAddOfferService) {
  }

  async insert(mode: MovementManagerInsertModeEnum) {

    let item: CTWorkOrderRowUnionType | null | undefined = null;

    switch (mode) {

      case MovementManagerInsertModeEnum.Stock:

        item = await this.movementManagerAddItemService.searchItem(this.configuration);
        break;

      case MovementManagerInsertModeEnum.FreeInput:

        item = await this.movementManagerAddItemService.manageRow(this.configuration);
        break;

        case MovementManagerInsertModeEnum.Offer:

        item = await this.movementManagerAddOfferService.searchOffer(this.configuration);
        break;



    }

    if (item) {

      this.movementManagerRowsComponent
        ?.addItem(item);

    }

  }

  setup(model: CTWorkOrder<CtWorkOrderInformationContent> | null) {

    if (!model) return;

    this.movementManagerRowsComponent
      ?.setup(model);

  }

}
