<mat-card class="temporary-area-box">
  <mat-card-header>
    <mat-card-title>
      <mat-icon color="primary">hourglass_top</mat-icon>
      {{ item?.Name }}</mat-card-title>
    <mat-card-subtitle> {{ item?.WorkOrder?.Name ?? '&nbsp;' }}</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>

    <!--    <ngx-charts-advanced-pie-chart-->
    <!--      style="cursor:pointer;"-->
    <!--      *ngIf="environment.demo"-->
    <!--      (click)="chartDetail()"-->
    <!--      [scheme]="scheme"-->
    <!--      [label]="'Completato'"-->
    <!--      [tooltipDisabled]="true"-->
    <!--      [results]="results">-->

    <!--      <ng-template #tooltipTemplate let-model="model">-->
    <!--        <div class="tooltip-label">{{ model.name }}</div>-->
    <!--        <div class="tooltip-value">{{ model.value }}%</div>-->
    <!--      </ng-template>-->

    <!--    </ngx-charts-advanced-pie-chart>-->

    <mat-card-actions
      fxLayout="row wrap"
      fxLayoutAlign="space-between center">
      <div>
        <button mat-button color="primary" (click)="detail()"> {{ "CT_PAGE.BREADCRUMBS.detail" | translate}}
          <mat-icon>info</mat-icon>
        </button>
      </div>
      <div>
        <button mat-button color="accent" (click)="edit()">{{ "edit" | translate}}
          <mat-icon>edit</mat-icon>
        </button>
        <!-- <button mat-button color="warn" (click)="delete()">{{ "delete" | translate}}
          <mat-icon>delete</mat-icon>
        </button> -->
      </div>
    </mat-card-actions>

  </mat-card-content>

</mat-card>
