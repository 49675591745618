import { Component, OnInit } from '@angular/core';
import { CtBinaryOperator, CtModelConfiguration, CTModelDatatableFilter, CtModelDatatableOperators, CtModelService, CtSchedulerConfiguration, CtSchedulerEvent } from '@ctsolution/ct-framework';
import { SchedulerRouteData } from '../../_core/route-data/schedule.route-data';
import { SchedulerService } from './scheduler.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EventTypeEnum, SchedulerEventPath } from './scheduler-event-button/scheduler-event-button.component';
import { CalendarEvent } from 'angular-calendar';
import { CTMGeneralService } from '../../_core/lib/general.service';

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss']
})
export class SchedulerComponent implements OnInit {


  schedulerConfiguration: CtSchedulerConfiguration = CtSchedulerConfiguration
    .create()
    .setLocale('it')
    .setOnCreate(async (value: any) => this.onCreate(value))
    .setOnEdit((value: CalendarEvent) => this.onEdit(value))
    .setExportEnabled(true)
    .setOnExport((event: CtSchedulerListParameter) => {

      const body: CtModelDatatableOperators = this.getModelDatatableOperators(event);

      this.modelService.export(this.configuration, body, { action: 'ExportExcelScheduler' })

    });;

  configuration: CtModelConfiguration<any> = CtModelConfiguration.create()
    .setRouteData(SchedulerRouteData())
    .setCTSchedulerConfiguration(this.schedulerConfiguration)

  constructor(private schedulerService: SchedulerService, private route: ActivatedRoute, private router: Router, private generalService: CTMGeneralService, private modelService : CtModelService<any>) { }

  ngOnInit() {

    let pid: string | null = null;
    this.route.queryParams.subscribe(params => {
      pid = params['IdEvent'] || null;
    });

    if (pid) {
      this.generalService.getList({
        controller: 'scheduler',
        filters: [
          CTModelDatatableFilter
            .create()
            .setField('Oid')
            .setValue(pid)
            .setOperatorType(CtBinaryOperator.Equal)
        ]
      }).subscribe(response => {

        this.onEditFly(response.Result.DataSource[0]);

      });
    }

  }

  async onCreate(value: any) {


    const type = this.route.snapshot.queryParamMap.get('TypeExtra');
    const oid = this.route.snapshot.queryParamMap.get('OidExtra');
    let param: SchedulerEventPath | null = null;
    if (type && oid)
      param = SchedulerEventPath.create(+oid, +type);

    const ret = await this.schedulerService.createEventFromPath(this.configuration, param, null, value);

    if (ret && param && type) {
      this.router.navigate([this.schedulerService.getPathToReturn(+type), 'edit', oid]) //TODO: Rivedere quando ci saranno piu tipi
      return
    }

    if (ret)
      window.location.reload();
  }

  async onEdit(value: CalendarEvent) {

    const ret = await this.schedulerService.createEventFromPath(this.configuration, null, (<any>value).data);
    if (ret)
      window.location.reload();
  }

  async onEditFly(value: CtSchedulerEvent) {

    const type = this.route.snapshot.queryParamMap.get('TypeExtra');
    const oid = this.route.snapshot.queryParamMap.get('OidExtra');
    const extraUrl = this.route.snapshot.queryParamMap.get('ExtraUrl');

    let param: SchedulerEventPath | null = null;
    if (type && oid)
      param = SchedulerEventPath.create(+oid, +type);
    const ret = await this.schedulerService.createEventFromPath(this.configuration, null, value);
    if (ret && param && type) {
      this.router.navigate([this.schedulerService.getPathToReturn(+type,extraUrl) ,'edit', oid]) //TODO: Rivedere quando ci saranno piu tipi
      return
    }

    if (ret)
      window.location.reload();
  }


  private getModelDatatableOperators(parameter: CtSchedulerListParameter): CtModelDatatableOperators {

    const body = CtModelDatatableOperators.create();

    body.Pagination?.setPage(1)?.setElementsForPage(null);

    const filters = [
      CTModelDatatableFilter.create()
        .setField('Start>=? and End<=?')
        .setValue([parameter.Start, parameter.End])
        .setOperatorType(CtBinaryOperator.AndOperator)
    ];

    body.setFilters(filters);

    return body;

  }

}

export class CtSchedulerListParameter {

  Type: CtSchedulerTypeEnum = CtSchedulerTypeEnum.NotSet;
  Origin: CtSchedulerOriginEnum = CtSchedulerOriginEnum.NotSet;
  forceUpdate: boolean = false;

  private constructor(public Start: Date, public End: Date) {
  }

  public static create = (Start: Date, End: Date) => new CtSchedulerListParameter(Start, End);

  setForceUpdate(value: boolean) {

    this.forceUpdate = value;
    return this;

  }

}

export enum CtSchedulerTypeEnum {

  NotSet = 0,
  Remainder = 1,
  Appointment = 2

}

export enum CtSchedulerOriginEnum {
  NotSet = 0,
  Lead = 1,
  WorkOrder = 2,
  Order = 3,
  Scheduler = 4
}
//TODO: lasciare le classi sul pacchetto e importarle da li
