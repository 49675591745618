import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormModuleDataListComponent } from './form-module-data-list/form-module-data-list.component';
import { CtButtonModule, CtFormGeneratorModule, CtFormModule, CtModelModule } from '@ctsolution/ct-framework';
import { FormModuleDataEditComponent } from './form-module-data-edit/form-module-data-edit.component';
import { FormModuleDataFormComponent } from './form-module-data-form/form-module-data-form.component';
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {MatLegacyMenuModule} from "@angular/material/legacy-menu";
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [FormModuleDataListComponent, FormModuleDataEditComponent, FormModuleDataFormComponent],
  imports: [
    CommonModule,
    CtModelModule,
    CtFormGeneratorModule,
    CtFormModule,
    MatCardModule,
    CtButtonModule,
    FlexLayoutModule,
    MatIconModule,
    MatLegacyButtonModule,
    MatLegacyMenuModule,
    TranslateModule,
    RouterModule.forChild([
      {
        path: "",
        children: [
          {
            path: "",
            redirectTo: "list",
            pathMatch: "full"
          },
          {
            path: "list",
            component: FormModuleDataListComponent
          },
          {
            path: "create",
            component: FormModuleDataEditComponent
          },
          {
            path: "edit/:id",
            component: FormModuleDataEditComponent
          }
        ]
      }
    ]),
  ]
})
export class FormModuleDataModule { }
