<mat-card>

  <mat-card-header
    fxLayout="row wrap"
    fxLayoutAlign="space-between center"
    style="padding-bottom: 0px">

    <mat-card-title>{{ (configuration?.IsQuotation ? 'RowQuotation' : 'DetailWorkOrder') | translate }}</mat-card-title>

  </mat-card-header>

  <mat-card-content class="movement-manager-rows-table" style="padding-top: 0px">

    <div style="display: flex; flex-direction: row; justify-content: space-between; margin-bottom: 5px">

      <div></div>

      <div>

        <ct-button
          style="margin-right: 5px"
          *ngIf="hasHandlingManagementButtonEnabled && this.configuration?.EnableWerehouse"
          [configuration]="createButton"></ct-button>

        <button
          mat-raised-button
          style="margin-right: 0px"
          (click)="toggleFilters(table)">
          <mat-icon
            [color]="filterPanelEnabled ? 'primary' : 'accent'"> {{ filterPanelEnabled ? 'filter_alt_off' : 'filter_alt' }}
          </mat-icon>
        </button>

      </div>

    </div>

    <p-table
      #table
      dataKey="Oid"
      [value]="items"
      [columns]="cols"
      [paginator]="true"
      [resizableColumns]="true"
      [rows]="10"
      [showCurrentPageReport]="true"
      autoLayout="true"
      [currentPageReportTemplate]="items.length ? 'totale {totalRecords}' : ''">
      <!-- [reorderableColumns]="false" non so che cazzo succede, non funzica! -->

      <ng-template pTemplate="header" let-columns>

        <tr>

          <th
            style="min-width: 80px;"
            *ngFor="let col of columns"
            pResizableColumn
            [pSortableColumn]="col.field"
          > {{ col.header | translate }}
            <p-sortIcon [field]="col.field"/>
          </th> <!-- pReorderableColumn -->

          <th style="width: 80px"></th>

        </tr>

        <tr [hidden]="!filterPanelEnabled">

          <th style="padding: 0.5rem;">
            <p-columnFilter
              [showMenu]="false"
              [showClearButton]="false"
              type="text"
              field="Code"
              placeholder="Cerca per codice"/>
          </th>

          <th style="padding: 0.5rem;">
            <p-columnFilter
              [showMenu]="false"
              [showClearButton]="false"
              type="text"
              field="Description"
              placeholder="Cerca per descrizione"/>
          </th>

          <th style="padding: 0.5rem;">
            <p-columnFilter
              [showMenu]="false"
              [showClearButton]="false"
              type="text"
              field="Quantity"
              placeholder="Cerca per quantità"/>
          </th>

          <th style="padding: 0.5rem;">
            <p-columnFilter
              [showMenu]="false"
              [showClearButton]="false"
              type="text"
              field="Amount"
              placeholder="Cerca per importo"/>
          </th>

          <th style="padding: 0.5rem;">
            <p-columnFilter
              [showMenu]="false"
              [showClearButton]="false"
              type="text"
              field="Discount"
              placeholder="Cerca per sconto"/>
          </th>

          <th style="padding: 0.5rem;">

            <p-columnFilter
              [showMenu]="false"
              [showClearButton]="false"
              type="text"
              field="VAT"
              placeholder="Cerca per IVA"/>

          </th>

          <th></th>

        </tr>

      </ng-template>

      <ng-template pTemplate="body" let-item let-ri="rowIndex">

        <tr>

          <td>
            {{ item.Code }}

          </td>

          <td>

            <span class="post-desc"> {{ item.Description }}</span>

          </td>

          <td >

            {{ item.Quantity }}

          </td>

          <td >

            {{ (item.Amount ?? 0) | currency:'EUR':true }}

          </td>

          <td >

            {{ item.Discount ? (item.Discount + '%') : '-' }}

          </td>

          <td >

            {{ item.VAT ? (item.VAT + '%') : '-' }}

          </td>

          <td>

            <div style="display: flex">

              <button
                pButton
                pRipple
                type="button"
                (click)="onRowEdit(ri, item)"
                class="p-button-text primary">
                <mat-icon>edit</mat-icon>
              </button>

              <button
                pButton
                pRipple
                type="button"
                *ngIf="item.State !== WorkOrderRowState.Closed"
                (click)="onRowDelete(ri, item)" class="p-button-text warn">
                <mat-icon>delete</mat-icon>
              </button>

            </div>

          </td>

        </tr>

      </ng-template>

      <ng-template pTemplate="emptymessage" let-columns>

        <p> {{ 'CT_MESSAGES.no_available_data' | translate }}</p>

      </ng-template>

    </p-table>

  </mat-card-content>

</mat-card>
