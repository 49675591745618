export enum HandlingStateEnum {

  Draft = 0,
  OnTheWay = 5,
  CheckIn = 10,
  Partial = 13,
  Delivered = 15,
  Canceled = 20

}
