import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialog
} from "@angular/material/legacy-dialog";
import { Router } from '@angular/router';
import { CtWebapiGenericResponse } from '@ctsolution/ct-webapi';
import { WorkOrderPackagingConfiguration } from './work-order-packaging.configuration';
import { CTMGeneralService } from 'projects/ct-manager/src/app/_core/lib/general.service';
import { CtBinaryOperator, CtModelConfiguration, CTModelDatatableFilter, CtModelService } from '@ctsolution/ct-framework';
import { switchMap } from 'rxjs';
import { CTManagerItemStock } from 'projects/ct-manager/src/app/_core/classes/ct-manager-item-stock';
import { ItemHandling } from 'projects/ct-manager/src/app/_core/classes/item-handling';
import { SelectedItemsConfiguration } from '../../../handling/order-handling-wizard/order-handling-wizard-step2/selected-items/selected-items.configuration';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { WorkOrderPackagingFormComponent } from '../work-order-packaging-form/work-order-packaging-form.component';
import { WorkOrderPackagingCreateConfiguration } from '../work-order-packaging-form/work-order-packaging-form.configuration';
import { HandlingRouteData } from 'projects/ct-manager/src/app/_core/route-data/handling.route-data';
import { HandlingStateEnum } from 'projects/ct-manager/src/app/_core/enum/handling-state.enum';
import { CTManagerLocation } from 'projects/ct-manager/src/app/_core/classes/ct-manager-location';
@Component({
  selector: 'app-work-order-packaging',
  templateUrl: './work-order-packaging.component.html',
  styleUrls: ['./work-order-packaging.component.scss']
})
export class WorkOrderPackagingComponent {

  items: CTManagerItemStock[] | null = null;
  isLoading: boolean = true;
  selectedItemConfiguration: SelectedItemsConfiguration = SelectedItemsConfiguration.create();
  selectedPack: any;
  openDrop = false;

  packagies: any[] | null = [];
  itemForm: FormGroup;
  packagingForm: FormGroup;
  locationOid: number | null = null;

  selectedItems = [];
  step1 = true;
  step2 = false;
  step3 = false;
  step4 = false;
  step5 = false;


  constructor(
    public dialogRef: MatDialogRef<WorkOrderPackagingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WorkOrderPackagingConfiguration,
    private router: Router,
    private generalService: CTMGeneralService,
    private formBuilder: FormBuilder,
    private dialog: MatLegacyDialog,
    private modelService: CtModelService<any>,
    private cdr: ChangeDetectorRef,

  ) {

    this.itemForm = this.formBuilder.group({
      itemsArray: this.formBuilder.array([]) // FormArray per gestire dinamicamente i controlli
    });

    this.packagingForm = this.formBuilder.group({
      packagingControl: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {

    if (!this.data.WorkOrder?.Oid) return;

    this.generalService.getList({
      controller: 'location',
      filters: [
        CTModelDatatableFilter.create()
          .setField('Type.MacroType')
          .setValue(1)
          .setOperatorType(CtBinaryOperator.Equal),
        CTModelDatatableFilter.create()
          .setField('WorkOrder.Oid')
          .setValue(this.data.WorkOrder.Oid)
          .setOperatorType(CtBinaryOperator.Equal),
      ]
    }).pipe(
      switchMap((response: any) => {
        // Recupera l'Oid dalla prima chiamata
        this.locationOid = response.Result.DataSource[0].Oid;

        // Effettua la seconda chiamata utilizzando l'Oid
        return this.generalService.getList({
          controller: 'locationstock',
          filters: [
            CTModelDatatableFilter.create()
              .setField('Location.Oid')
              .setValue(this.locationOid)
              .setOperatorType(CtBinaryOperator.Equal),
          ]
        });
      })
    ).subscribe((locationStockResponse: any) => {

      this.items = [...locationStockResponse.Result.DataSource]
      this.isLoading = false;
    });
  }

  onNoClick(num: number) {

    switch (num) {

      case 1:
        this.dialogRef.close();
        window.location.reload();
        break;

      case 2:
        this.step2 = false;
        this.step1 = true;
        break;

      case 3:
        this.step3 = false;
        this.step2 = true;
        break;

      case 4:
        this.step4 = false;
        this.step3 = true;
        break;

        case 5:
          this.dialogRef.close();
          window.location.reload();
        break;

      default:
        break;
    }

  }

  updateSelection(selectedItemsConfiguration: SelectedItemsConfiguration) {


    this.selectedItemConfiguration.resetItem();

    selectedItemsConfiguration
      .selectItems(this.selectedItems);

  }

  async updatePackage(selectedOid: number | null = null) {

    if (selectedOid)
      this.packagingForm.controls['packagingControl'].setValue(selectedOid);

    this.cdr.detectChanges();

    await this.generalService.getList({
      controller: 'location',
      filters: [
        CTModelDatatableFilter.create()
          .setField('WorkOrder.Oid')
          .setValue(this.data.WorkOrder.Oid)
          .setOperatorType(CtBinaryOperator.Equal),
          CTModelDatatableFilter.create()
          .setField('Type.MacroType')
          .setValue(2)
          .setOperatorType(CtBinaryOperator.Equal),
      ]
    }).subscribe((response: any) => {

      this.packagies = [...response.Result.DataSource];

    })

  }


  submit(num: number) {

    if (this.selectedItems.length < 1) return;

    switch (num) {

      case 1:
        this.updateSelection(this.selectedItemConfiguration);
        this.populateForm();
        this.step1 = false;
        this.step2 = true;
        break;

      case 2:
        if (this.onSubmitStep2()) {
          this.updatePackage();
          this.step2 = false;
          this.step3 = true;
        }

        break;

      case 3:
        //this.createHandling();
        this.step3 = false;
        this.step4 = true;
        break;

      default:
        break;
    }

  }

  getCurrentPackaging(){

    if(this.packagingForm.get('packagingControl')?.value)
    {

      return this.packagies?.find(pack => pack.Oid  == this.packagingForm.get('packagingControl')?.value).Name

    }

    else return null

  }

  navigateToPackaging() {

    this.router.navigate(['/location/box/create']);

    this.dialogRef.close();

  }

  populateForm() {

    const itemsArray = this.itemForm.get('itemsArray') as FormArray;

    this.selectedItemConfiguration.itemHandlings.forEach(item => {
      // Controlla se l'elemento è già presente nel FormArray
      const alreadyExists = itemsArray.controls.some(control => control.get('code')?.value === item.Item?.Code);
      
      if (!alreadyExists) {
        itemsArray.push(
          this.formBuilder.group({
            qty: [item.StartQty, [Validators.required, Validators.min(1), Validators.max(item.CTManagerItemStock?.Stock!)]],
            code: [item.Item?.Code]// Aggiungi la qty con validazione
          })
        );
      }
    });
  }

  // Getter per ottenere il FormArray
  get itemsArray(): FormArray {
    return this.itemForm.get('itemsArray') as FormArray;
  }

  // Funzione di submit
  onSubmitStep2() {


    if (this.itemForm.valid) {
      // Aggiorna la qty per ogni item in base al form
      this.selectedItemConfiguration.itemHandlings.forEach((item, index) => {
        item.StartQty = this.itemsArray.at(index).get('qty')?.value;
      });

      return true;
    } else {
      return false;
    }
  }

  createPackaging() {

    const param: WorkOrderPackagingCreateConfiguration = WorkOrderPackagingCreateConfiguration.create(this.locationOid, this.data.WorkOrder.Oid);
    this.packagies = null;
    const dialogRef = this.dialog.open(WorkOrderPackagingFormComponent, {

      data: param

    }).afterClosed().subscribe((res: number) => {
      this.updatePackage(res)
      this.cdr.detectChanges()
    });

  }

  createHandling() {

    const value: HandlingPackagingDTO = HandlingPackagingDTO.create();

    const conf: CtModelConfiguration<any> = CtModelConfiguration.create().setRouteData(HandlingRouteData());

    const packOid = this.packagingForm.get('packagingControl')?.value

    value.setHandlingState(HandlingStateEnum.Draft).setLocationFrom(this.locationOid).setLocationTo(packOid).setItemHandlings(this.selectedItemConfiguration.itemHandlings)

    this.modelService
      .putInfos(conf, value)
      ?.subscribe({
        next: (response: CtWebapiGenericResponse<number | null>) => {
          this.step4 = false;
          this.step5 = true;
         },
        error: (err) => console.error('Errore durante l\'operazione:', err)
      });
  }
}
export class HandlingPackagingDTO {

  HandlingState: HandlingStateEnum | null = null;
  LocationFrom: CTManagerLocation | null = null;
  LocationTo: CTManagerLocation | null = null;
  ItemHandlings: ItemHandling[] | null = null;

  private constructor() { }

  public static create = (): HandlingPackagingDTO => new HandlingPackagingDTO();

  setLocationFrom(Oid: number | null): HandlingPackagingDTO {

    this.LocationFrom = { Oid } as CTManagerLocation;
    return this;

  }

  setLocationTo(Oid: number | null): HandlingPackagingDTO {

    this.LocationTo = { Oid } as CTManagerLocation;
    return this;

  }


  setItemHandlings(value: ItemHandling[] | null): HandlingPackagingDTO {

    this.ItemHandlings = value;

    this.ItemHandlings
      ?.forEach(
        itemHandling => {

          switch (this.HandlingState) {

            case HandlingStateEnum.Draft:
            case HandlingStateEnum.OnTheWay:
              itemHandling.setState(this.HandlingState);
              break;
            default:

              itemHandling
                .setState(itemHandling.StatusToggleComponentConfiguration?.control?.value ?? HandlingStateEnum.Draft);

              break;

          }

          // if (itemHandling.StatusToggleComponentConfiguration) {
          //
          //   delete (<any>itemHandling).StatusToggleComponentConfiguration;
          //
          // }

        })

    return this;

  }

  setHandlingState(value: HandlingStateEnum | null): HandlingPackagingDTO {

    this.HandlingState = value;

    switch (value) {

      case HandlingStateEnum.Draft:
      case HandlingStateEnum.OnTheWay:
        this.ItemHandlings
          ?.forEach(itemHandling => itemHandling.setState(value));
        break;

    }

    return this;

  }

}
