import {CTWorkOrderRow} from "./ct-work-order.row";
import {CTManagerItem} from "./ct-manager-item";

export class CtWorkOrderRowItem extends CTWorkOrderRow {

  Item: CTManagerItem | null = null;

  public static override create = (model?: CTWorkOrderRow) => <CtWorkOrderRowItem>CTWorkOrderRow.create(model);

}
