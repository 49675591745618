import { AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { LeadDocumentsConfiguration } from './lead-documents.configuration';
import { CtBinaryOperator, CtButtonConfiguration, CtDatatableActionCustomConfiguration, CtDatatableConfiguration, CtGenericDialogComponent, CtGenericDialogConfiguration, CtModelConfiguration, CTModelDatatableFilter, CtModelDatatableOperators, CtModelService, MAT_RAISED_PRIMARY, SnackbarService } from '@ctsolution/ct-framework';
import { FileGet } from '../../../_core/classes/file-get';
import { FileService } from '../../../_core/lib/file.service';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { LeadDocumentsService } from './lead-documents.service';
import { LeadFileRouteData } from '../../../_core/route-data/lead-file.route-data';
import { DEFAULT_DATATABLE_CONFIGURATION } from '../../../_core/constants';
import { CtWebapiGenericResponse } from '@ctsolution/ct-webapi';
import { NoteDialogService } from '../../../_core/services/note-services/note-dialog/note-dialog-service';
import { NoteDialogConfiguration, FileOriginEnum } from '../../../_core/services/note-services/note-dialog.configuration';

@Component({
  selector: 'app-lead-documents',
  templateUrl: './lead-documents.component.html',
  styleUrls: ['./lead-documents.component.scss']
})
export class LeadDocumentsComponent implements AfterViewInit {

  @Input() configuration: LeadDocumentsConfiguration | null = null;

  modelConfiguration: CtModelConfiguration<LeadDocumentsComponent> | null = null
  datatableConfiguration: CtDatatableConfiguration | null = null;
  @ViewChild("downloadAttachmentButton") downloadAttachmentButton: TemplateRef<any> | null = null;
  @ViewChild("legendTemplate") legendTemplate: TemplateRef<any> | null = null;

  showZipButton = false;
  zipDownloadAttachmentButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setAction(() => this.downloadZip())
    .setLabel("Download zip")
    .setMatherialOptions(MAT_RAISED_PRIMARY)
    .setIcon("download");

  constructor(private noteDialogService : NoteDialogService,private generalService: CtModelService<any>, private fileService: FileService, private dialog: MatLegacyDialog, private leadDocumentsService: LeadDocumentsService, private snackbar: SnackbarService) { }

  ngAfterViewInit(): void {

    this.setup()

  }

  setup() {

    this.datatableConfiguration = DEFAULT_DATATABLE_CONFIGURATION()
      .setCustomDatatableActionConfiguration(
        CtDatatableActionCustomConfiguration
          .create()
          .setTemplateOutlet(this.downloadAttachmentButton));

    const configuration = CtModelConfiguration
      .create<LeadDocumentsComponent>()
      .setRouteData(LeadFileRouteData())
      .setCTDatatableConfiguration(this.datatableConfiguration)
      .setDisableAutoQueryFilter(true)
      .setOnCreate(() => {

        this.configuration?.setDocument(null)
        const dialogRef = this.leadDocumentsService.open(this.configuration)

        dialogRef
          .afterClosed()
          .subscribe(value => {

            if (value) {

              this.snackbar.onSuccess();
              this.reloadCurrentPath();

            }

          })

      })
      .setOnEdit((event: any) => {

        this.configuration?.setDocument(event);

        this.noteDialogService.open(NoteDialogConfiguration.create().setFileOid(event.Oid).setType(FileOriginEnum.Lead))
      })


    configuration
      .filterValues
      .addConstantFilter(CTModelDatatableFilter
        .create()
        .setField('Lead.Oid')
        .setValue(this.configuration?.Lead.Oid)
        .setOperatorType(CtBinaryOperator.Equal))


    this.modelConfiguration = configuration;

    this.modelConfiguration?.setLegendTemplate(this.legendTemplate)

    this.checkListDocuments()

  }

  checkListDocuments(){

    if (!this.modelConfiguration) return;

    const filters = CtModelDatatableOperators
      .create()
      .setFilters([
        CTModelDatatableFilter
          .create()
          .setField('Lead.Oid')
          .setValue(this.configuration?.Lead.Oid)
          .setOperatorType(CtBinaryOperator.Equal)
      ]);



    this.generalService.getList(this.modelConfiguration, filters).subscribe((response: CtWebapiGenericResponse<any>) => {

      if (response.Result.DataSource) {

        const res: Array<any> = response.Result.DataSource;

        if (res.length > 1) {

          this.showZipButton = true;

        }

      }

    })

  }
  reloadCurrentPath(){


    let currentPath = window.location.pathname;

    const hasQuestionMark = currentPath.includes('?');

    if (!hasQuestionMark) currentPath += '?rows=true';

    window.location.href = window.location.origin + currentPath;

  }

  download(file: FileGet | null) {

    if (!file) return;

    this.fileService.downloadAttachmentByEndPoint(file);

  }

  downloadZip() {

    const endpoint = `/Lead/GetDocumentsZip/${this.configuration?.Lead.Oid}`;
    const fileName = `Documenti_Lead_${this.configuration?.Lead.Name}`;
    const value : FileGet ={
      Endpoint: endpoint
    };
    this.fileService.downloadAttachmentByEndPoint(value,fileName)

  }

}
