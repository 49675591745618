import {Component, Inject, ViewChild} from "@angular/core";
import {
  CtBinaryOperator,
  CtButtonConfiguration, CtDatatableComponent,
  CtDatatableConfiguration,
  CTDatatablePaginationParameter, CtFormContainerHelper,
  CtModelConfiguration,
  CTModelDatatableFilter, CtModelDatatableOperators, CtModelService, CtSortOrderDescriptorParameter,
  MAT_RAISED_PRIMARY,
  MAT_RAISED_WARN
} from "@ctsolution/ct-framework";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import {OfferSelectionConfiguration} from "./offer-selection.configuration";
import {OFFER_SELECTION_COLUMNS} from "./offer.selection-column";
import { OfferRouteData } from "projects/ct-manager/src/app/_core/route-data/offer.route-data";
import { CTManagerOffer } from "projects/ct-manager/src/app/_core/classes/ct-manager-offer";

@Component({
  selector: "app-offer-selection",
  templateUrl: "./offer-selection.component.html",
  styleUrls: ["./offer-selection.component.scss"]
})
export class OfferSelectionComponent { // TODO: Questa tabella ha delle customizzazione particolari, però è riconducibile alla ct-datatable-container ... pensare di centralizzarla piu avanti?

  @ViewChild(CtDatatableComponent) datatable: CtDatatableComponent | null = null;

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.cancel")
    .setAction(() => this.onCancel())
    .setMatherialOptions(MAT_RAISED_WARN);

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setClass('ct-model-submit-button')
    .setAction(() => this.onSubmit())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  list: CtDatatableConfiguration = CtDatatableConfiguration
    .create()
    .setScrollbarH(true)
    .setFiltersEnabled(true)
    .setExternalSorting(true);

  private Filters: any | null = null;
  private ColumnPrimarySorting: CtSortOrderDescriptorParameter | null = null;
  configuration: CtModelConfiguration<any> | null = null;


  constructor(
    private modelService: CtModelService<any>,
    @Inject(MAT_DIALOG_DATA) private data: OfferSelectionConfiguration,
    private dialog: MatDialogRef<OfferSelectionComponent>) {

    this.list
      .setSelectionType(this.data.selectionType)
      .setColumns(OFFER_SELECTION_COLUMNS)
      .setController('CTMOfferSelection');

    this.configuration = CtModelConfiguration
      .create()
      .setRouteData(OfferRouteData())

  }

  ngAfterViewInit(): void {

    setTimeout(() => {

      window.dispatchEvent(new Event("resize"));

    }, 250);

  }

  getFilters(): CTModelDatatableFilter[] {

    let filters: CTModelDatatableFilter[] = new Array<CTModelDatatableFilter>();

    if (this.data.searchText) {

      filters
        .push(
          CTModelDatatableFilter
            .create()
            .setField("[Code] is not null AND Contains([Code], ?)")
            .setValue([this.data.searchText])
            .setOperatorType(CtBinaryOperator.OrOperator),
          CTModelDatatableFilter
            .create()
            .setField("[Name] is not null AND Contains([Name], ?)")
            .setValue([this.data.searchText])
            .setOperatorType(CtBinaryOperator.OrOperator)
        );

    }

    if (this.data.defaultFilters) {

      filters = filters.concat(this.data.defaultFilters)

    }

    return filters;

  }

  onSort(event: any) {

    this.ColumnPrimarySorting = CtFormContainerHelper.create().setupCTLookupInfoSortingValues(this.configuration!, event.sorting);

    this.fetchData();

  }

  fetchData(pagination?: CTDatatablePaginationParameter): void {

    this.list.setLoadingIndicator(true);

    this.caller(pagination)
      .subscribe(response => {

        this.list
          .setCount(response?.Result?.TotalElement ?? 0)
          .setRows((response?.Result?.DataSource ?? []) as CTManagerOffer[])
          .setLoadingIndicator(false);

      });

  }

  private caller(pagination?: CTDatatablePaginationParameter) {

    const body: CtModelDatatableOperators = CtModelDatatableOperators.create()
      .setFilters()

    if (pagination) {

      body
        .setPagination(pagination)

    }

    if (this.Filters) {

      body
        .setFiltersByKeyValueObject(this.Filters, this.configuration!.CTFieldInfos ?? []);

    }

    if (!body.Filters) {

      body
        .setFilters(new Array<CTModelDatatableFilter>())

    }

    body
      .Filters
      .push(...this.getFilters())

    if (body.Filters.length && !pagination) { // se ho dei filtri presenti e pagination non è valorizzata (quindi non provengo dal click del paginatore) riparto da pagina 1

      body
        .Pagination
        ?.setPage(1);

    }

    if (this.ColumnPrimarySorting) {

      body
        .Pagination
        ?.setSortOrders([this.ColumnPrimarySorting]);

    } else {

      body
        .Pagination
        ?.setSortOrders(
          new Array<CtSortOrderDescriptorParameter>(CtSortOrderDescriptorParameter.create('Code')))

    }

    return this.modelService
      .getList(this.configuration!, body);

  }

  onCancel() {

    this.dialog
      .close();

  }

  search(event: { [key: string]: any; } | null = null) {

    this.Filters = event ? CtFormContainerHelper.create().setupCTLookupInfoControlValues(this.configuration!, event, true) : null;

    if (!this.Filters) {

      this.datatable
        ?.form
        .reset({}, {emitEvent: false})

    } else {

      this.datatable
        ?.form
        .patchValue(this.Filters ?? {}, {emitEvent: false});

    }

    this.fetchData();

  }

  onSubmit() {

    let selected = this.list.selected;

    this.dialog
      .close(selected);

  }

}
