import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-massive-changes-edit',
  templateUrl: './massive-changes-edit.component.html',
  styleUrls: ['./massive-changes-edit.component.scss']
})
export class MassiveChangesEditComponent implements OnInit {

  constructor(private route : ActivatedRoute, private cdr : ChangeDetectorRef) { }

  ngOnInit() {

    this.route
    .data
    .subscribe(data => {

      data['title'] = 'CT_MENU.massive-changes';
      this.cdr.detectChanges();

    })
  }

}
