<ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>

<ng-template #StateCellTemplate let-row="row">

  <app-state-lead-bullet [configuration]="stateBulletConfiguration(row.LeadStatus.Name)"></app-state-lead-bullet>

</ng-template>

<ng-template #downloadButtons let-value="value">

  <button [matMenuTriggerFor]="leadHasDocumentsAvailable(value) ? menu : null" mat-icon-button [color]="'primary'"
    (click)="triggerMenu(value)">
    <mat-icon> attachment </mat-icon>
  </button>

  <mat-menu #menu="matMenu" xPosition="before">

    <button mat-menu-item (click)="downloadPdf(value)">
      <mat-icon color="warn">picture_as_pdf</mat-icon>
      <span>PDF</span>
    </button>

    <button mat-menu-item (click)="onZipDownload(value)">
      <mat-icon color="accent">folder_zip</mat-icon>
      <span> Allegati </span>
    </button>

  </mat-menu>

</ng-template>