import {Injectable} from '@angular/core';
import {CtAuthenticationGuard, CtAuthenticationService} from "@ctsolution/ct-authentication";
import {Role} from "../enum/role";

type RoleChecker = () => Promise<boolean>;

interface IBeRole {
  administrator: RoleChecker;
  operator: RoleChecker;
}

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  constructor(
    private CTAuthenticationService: CtAuthenticationService,
    private CTAuthenticationGuard: CtAuthenticationGuard
  ) {
  }

  public is: IBeRole = {
    administrator: async () => {
      await this.CTAuthenticationService.claimsUploaded();
      return this.CTAuthenticationGuard.userHasRole([Role.ADMIN]);
    },
    operator: async () => {
      await this.CTAuthenticationService.claimsUploaded();
      return this.CTAuthenticationGuard.userHasRole([Role.OPERATOR]);
    }
  };

}
