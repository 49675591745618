import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompanyEditFormComponent} from './company-edit-form.component';
import {CtButtonModule, CtFormModule} from '@ctsolution/ct-framework';
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    CtFormModule,
    MatCardModule,
    CtButtonModule,
    FlexLayoutModule
  ],
  declarations: [CompanyEditFormComponent],
  exports: [CompanyEditFormComponent]
})
export class CompanyEditFormModule {
}
