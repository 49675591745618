import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeadDocumentsWizardComponent } from './lead-documents-wizard.component';
import { LeadDocumentsFormComponent } from './lead-documents-form/lead-documents-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CtButtonModule, CtControlModule, CtFileUploaderModule } from '@ctsolution/ct-framework';
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {MatIconModule} from "@angular/material/icon";
import {TranslateModule} from "@ngx-translate/core";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {MatLegacyDialogModule} from "@angular/material/legacy-dialog";
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CtControlModule,
    CtFileUploaderModule,
    CtButtonModule,
    MatLegacyButtonModule,
    MatLegacyDialogModule,
    MatCardModule,
    MatIconModule,
    TranslateModule
  ],
  declarations: [LeadDocumentsWizardComponent,LeadDocumentsFormComponent],
  exports : [LeadDocumentsWizardComponent]
})
export class LeadDocumentsWizardModule { }
