import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ModuleStocksComponent } from "./module-stocks.component";
import { CtModelModule } from "@ctsolution/ct-framework";

@NgModule({
  declarations: [
    ModuleStocksComponent
  ],
  imports: [
    CommonModule,
    CtModelModule
  ],
  exports: [
    ModuleStocksComponent
  ]
})
export class ModuleStocksModule {
}
