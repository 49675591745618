import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WarehouseWorkorderComponent } from './warehouse-workorder.component';
import { CtButtonModule, CtModelModule } from '@ctsolution/ct-framework';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [
    CommonModule,
    CtModelModule,
    MatCardModule,
    TranslateModule,
    MatCardModule,
    CtButtonModule
  ],
  declarations: [WarehouseWorkorderComponent],
  exports : [WarehouseWorkorderComponent]
})
export class WarehouseWorkorderModule { }
