import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WorkOrderDocumentsComponent} from './work-order-documents.component';
import {CtButtonModule, CtModelModule} from '@ctsolution/ct-framework';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {MatLegacyMenuModule} from "@angular/material/legacy-menu";
import {WorkOrderDocumentsService} from './work-order-documents.service';
import {WorkOrderDocumentsWizardModule} from "./work-order-documents-wizard/work-order-documents-wizard.module";
import { WorkOrderMandatoryDocumentsAllertModule } from './work-order-mandatory-documents-allert/work-order-mandatory-documents-allert.module';

@NgModule({
  imports: [
    CommonModule,
    CtModelModule,
    MatIconModule,
    MatLegacyButtonModule,
    MatLegacyMenuModule,
    WorkOrderDocumentsWizardModule,
    CtButtonModule,
    WorkOrderMandatoryDocumentsAllertModule
  ],
  declarations: [WorkOrderDocumentsComponent],
  exports: [WorkOrderDocumentsComponent],
  providers: [WorkOrderDocumentsService],
})
export class WorkOrderDocumentsModule {
}
