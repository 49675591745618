import { Component } from '@angular/core';
import { CtModelConfiguration, CtModelOperator } from '@ctsolution/ct-framework';
import { WorkOrderPaymentRouteData } from '../../../_core/route-data/work-order-payment.route-data';
import { CTMGeneralService } from '../../../_core/lib/general.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CtWebapiService } from '@ctsolution/ct-webapi';
import { DEFAULT_DATATABLE_CONFIGURATION } from '../../../_core/constants';

@Component({
  selector: 'app-payments-list',
  templateUrl: './payments-list.component.html',
  styleUrls: ['./payments-list.component.scss']
})
export class PaymentsListComponent {


  configuration: CtModelConfiguration<PaymentsListComponent> = CtModelConfiguration
    .create<PaymentsListComponent>()
    .setRouteData(WorkOrderPaymentRouteData())
    .setOperations([CtModelOperator.Update,CtModelOperator.Detail])
    .setCTDatatableConfiguration(DEFAULT_DATATABLE_CONFIGURATION())
    .setOnEdit(event =>  this.general.navigateOnCTModelRouter(["payments"], "edit", {
      value: event,
      configuration: this.configuration!
    }))
    .setOnDetail(event => this.router.navigate(["work-order", "edit", event.WorkOrder.Oid!.toString()]));


  constructor(private general: CTMGeneralService, private router: Router) {
  }


  ngAfterViewInit(): void {

    this.setup()

  }


  setup() {

    this.configuration
      .initialized()
      .subscribe(() => this.configuration?.setOperations([CtModelOperator.Update,CtModelOperator.Detail]));
  }
}
