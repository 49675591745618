import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WarehouseStocksComponent } from "./warehouse-stocks.component";
import { CtModelModule } from "@ctsolution/ct-framework";

@NgModule({
  declarations: [
    WarehouseStocksComponent
  ],
  imports: [
    CommonModule,
    CtModelModule
  ],
  exports: [
    WarehouseStocksComponent
  ]
})
export class WarehouseStocksModule {
}
