import {Component, Input} from "@angular/core";
import {Handling} from "../../../../../../_core/classes/handling";
import {HandlingOriginEnum} from "../../../../../../_core/enum/handling-origin.enum";
import { ItemsSupplierSelections, ModuleCreationRequestResult } from "projects/ct-manager/src/app/_core/controllers/item.controller";
import { ItemSuppliersTransfer, SuppliersTransfer } from "projects/ct-manager/src/app/_core/classes/item-suppliers-transfer";


@Component({
  selector: "app-module-item-order",
  templateUrl: "./module-item-order.component.html",
  styleUrls: ["./module-item-order.component.scss"]
})
export class ModuleItemOrderComponent {

  @Input() handlings: Handling[] | null = null;
  @Input() model!: ModuleCreationRequestResult;
  matchedItems: any[] = [];
  finalItems: ItemsSupplierSelections[] = [];



  getSourceFrom(handling: Handling): string | null | undefined {

    return handling.HandlingOrigin === HandlingOriginEnum.Location ? handling.LocationFrom?.Name : handling.SupplierFrom?.ReferenceName;

  }

  getSourceIcon(handling: Handling): string | null | undefined {

    return handling.HandlingOrigin === HandlingOriginEnum.Location ? 'swap_horiz' : 'local_shipping';

  }

  onSupplierChange(item : ItemSuppliersTransfer,newSupplier: SuppliersTransfer) {


    const existingItem = this.finalItems.find(itemFinal => itemFinal.ItemOid === item.ItemOid);

    if (existingItem) existingItem.SupplierOid = newSupplier.Oid
    else this.finalItems.push(new ItemsSupplierSelections(item.ItemOid,newSupplier.Oid));


    this.model.ItemsSupplierSelections = this.finalItems;

  }
}
