import {Component} from '@angular/core';
import {
  CtControlConfiguration,
  CtControlTypes, CtControlValidator,
  CTFieldInfos,
  CtFormContainerHelper,
  CtThemeTypes
} from "@ctsolution/ct-framework";

@Component({
  selector: 'app-user-working-hours-notes-control',
  template: `
    <ct-control [configuration]="control"></ct-control>`
})
export class UserWorkingHoursNotesControlComponent {

  control: CtControlConfiguration = CtControlConfiguration
    .create()
    .setType(CtControlTypes.TEXTAREA)
    .setTheme(CtThemeTypes.MATERIAL)
    .setLabel('Note');

}
