import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserListModule} from "./user-list/user-list.module";
import {UserEditModule} from "./user-edit/user-edit.module";
import {RouterModule} from "@angular/router";
import {UserEditComponent} from "./user-edit/user-edit.component";
import {UserListComponent} from "./user-list/user-list.component";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    UserListModule,
    UserEditModule,
    RouterModule.forChild([
      {
        path: "",
        children: [
          {
            path: "",
            redirectTo: "list",
            pathMatch: "full"
          },
          {
            path: "create",
            component: UserEditComponent
          },
          {
            path: "edit/:id",
            component: UserEditComponent
          },
          {
            path: "list",
            component: UserListComponent
          }
        ]
      }
    ])
  ]
})
export class UserModule {
}
