import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ModuleAddItemComponent} from "./module-add-item.component";
import {ModuleAddItemService} from "./module-add-item.service";
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {
  ItemSelectionModule
} from "../../../../handling/order-handling-wizard/order-handling-wizard-step2/item-selection/item-selection.module";
import {MatListModule} from "@angular/material/list";
import {
  SelectedItemsModule
} from "../../../../handling/order-handling-wizard/order-handling-wizard-step2/selected-items/selected-items.module";
import {FlexModule} from "@angular/flex-layout";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {TranslateModule} from "@ngx-translate/core";
import {CtButtonModule} from "@ctsolution/ct-framework";

@NgModule({
  declarations: [
    ModuleAddItemComponent
  ],
    imports: [
        CommonModule,
        MatDialogModule,
        ItemSelectionModule,
        MatListModule,
        SelectedItemsModule,
        FlexModule,
        MatLegacyButtonModule,
        TranslateModule,
        CtButtonModule
    ],
  providers: [
    ModuleAddItemService
  ]
})
export class ModuleAddItemModule {
}
