import {Injectable} from '@angular/core';
import {MatLegacyDialog} from "@angular/material/legacy-dialog";
import {SupplierChangeConfirmComponent} from "./supplier-change-confirm.component";

@Injectable()
export class SupplierChangeConfirmService {

  constructor(private dialog: MatLegacyDialog) {
  }

  async confirm(): Promise<boolean> {

    const dialogRef = this.dialog
      .open(SupplierChangeConfirmComponent, {disableClose: true, maxWidth: '500px'})

    return dialogRef.afterClosed().toPromise();

  }

}
