import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { CtButtonConfiguration, MAT_RAISED_WARN, MAT_RAISED_PRIMARY, CtThemeTypes, SnackbarService } from '@ctsolution/ct-framework';
import { CTMGeneralService } from 'projects/ct-manager/src/app/_core/lib/general.service';
import { CatalogCloneItemConfiguration } from './catalog-clone-item.configuration';
import { CtWebapiGenericResponse, CtWebapiService, DataRequest, MethodEnum } from '@ctsolution/ct-webapi';
import { ItemRouteData } from 'projects/ct-manager/src/app/_core/route-data/item.route-data';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'catalog-clone-item',
  templateUrl: './catalog-clone-item.component.html',
  styleUrls: ['./catalog-clone-item.component.scss']
})
export class CatalogCloneItemComponent {

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("close")
    .setAction(() => this.dialogRef.close(false))
    .setMatherialOptions(MAT_RAISED_WARN);

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("clone")
    .setAction(() => this.submit())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  configuration: CatalogCloneItemConfiguration | null = null;

  form: FormGroup;



  private codeControl = this.generalService.getTextControl({
    name: 'Code',
    required: true,
  }).setTheme(CtThemeTypes.MATERIAL);



  constructor(
    private dialogRef: MatLegacyDialogRef<any>,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private generalService: CTMGeneralService,
    private _webapi: CtWebapiService,
    private _snackbar: SnackbarService,
  ) {

    this.configuration = data.configuration;
    this.form = this.formBuilder.group({})
    this.codeControl.setFormParent(this.form);
    this.codeControl.setValue(this.configuration?.codeValue)

  }

  submit() {

    if (this.form.valid) {

      const code = this.form.get('Code')?.value
      const oid = this.configuration?.itemOid;
      const CONTROLLER_REQUEST = (): DataRequest => new DataRequest().setController([ItemRouteData().controller!]);


      const parameter = {
        Oid: oid,
        NewCode: code
      }

      const request: DataRequest = CONTROLLER_REQUEST()
        .setAction("CloneItem")
        .setMethod(MethodEnum.POST)
        .setQueryParams({
          hideSpinner: true
        })
        .setBody(parameter);

      this._webapi
        .request(request).subscribe((res: CtWebapiGenericResponse<any>) => {

          if (res.Response.Code !== 200) {
            this._snackbar.open(res.Result);
            return
          }

          this.dialogRef.close();
          if (!this.configuration?.codeValue) return;


          window.location.href = `/catalog/item/edit/${res.Result}`


        });


    }


  }

}
