import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserInfoWorkDataComponent } from './user-info-work-data.component';
import { CtModelModule } from '@ctsolution/ct-framework';
import { UserInfoWorkDataEditModule } from './user-info-work-data-edit/user-info-work-data-edit.module';
import { UserInfoWorkDataService } from './user-info-work-data.service';

@NgModule({
  imports: [
    CommonModule,
    CtModelModule,
    UserInfoWorkDataEditModule
  ],
  declarations: [UserInfoWorkDataComponent],
  exports : [UserInfoWorkDataComponent],
  providers: [UserInfoWorkDataService]
})
export class UserInfoWorkDataModule { }
