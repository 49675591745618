import {Component} from '@angular/core';
import {
  CtControlConfiguration, CtControlValidator, CTFieldInfos,
  CtFormContainerHelper, CtSelectControlOptions,
  CtThemeTypes
} from "@ctsolution/ct-framework";
import {environment} from "../../../../../../environments/environment";
import {CtWebapiGenericResponse} from "@ctsolution/ct-webapi";

const UserWorkingHoursActivityTypeControl: CTFieldInfos = CTFieldInfos.create({
  IsDTO: true,
  Field: "ActivityType",
  Type: "ActivityTypeDTO",
  IsNullable: false,
  ControlType: "Lookup",
  LookupInfos: {
    RemoteEndpoint: `${environment.dev.REST_API_SERVER}/ActivityType/Lookup`,
    CodeField: "Code",
    DescriptionField: "Description",
    IncrementalSearch: false,
  },
} as CTFieldInfos);

@Component({
  selector: 'app-user-working-hours-activity-type-control',
  template: `
    <ct-control [configuration]="control"></ct-control>`
})
export class UserWorkingHoursActivityTypeControlComponent {

  control: CtControlConfiguration = CtControlConfiguration
    .create(
      CtFormContainerHelper
        .create()
        .getCTFormControlByCTFieldInfo(UserWorkingHoursActivityTypeControl))
    .setTheme(CtThemeTypes.MATERIAL)
    .setValidators([
      CtControlValidator.create({name: 'required'} as CtControlValidator)
    ])
    .configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        .setLookupResponseOrderBy((response: CtWebapiGenericResponse<any>) => {

          const dataSource = response.Result.DataSource;

          const oreLavorateObject = dataSource
            .find((item: any) => {
              const description = item.Description.toLowerCase();
              return description.includes('lavor') || description.includes('job') || description.includes('work');
            });

          if (oreLavorateObject) {

            const index = dataSource.indexOf(oreLavorateObject);
            if (index !== -1) dataSource.splice(index, 1);

            dataSource.unshift(oreLavorateObject);

          }

          response.Result.DataSource = dataSource;

          return response;

        })
        .setNullValueEnabled(false);

    });

}
