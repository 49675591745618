import {CtModelRouteData} from "@ctsolution/ct-framework";

export const LeadFileNoteRouteData = (): CtModelRouteData => CtModelRouteData
  .create()
  .setController("LeadFileNote");

  export const UserWorkingHoursFileNoteRouteData = (): CtModelRouteData => CtModelRouteData
  .create()
  .setController("UserWorkingHoursFileNote");

  export const WorkOrderFileNoteRouteData = (): CtModelRouteData => CtModelRouteData
  .create()
  .setController("WorkOrderFileNote");