export class LangDownloadFileConfiguration {

  icon: string | null = null;
  color: string | null = 'accent';
  buttonType: 'mat-mini-fab' | 'mat-icon-button' | null = null;
  languages: any[] = [
    {
      language: "Italiano",
      code: "it",
      type: "IT",
      icon: "it"
    },
    {
      language: "English",
      code: "en",
      type: "US",
      icon: "us"
    }
  ];

  private constructor() {
  }

  public static create = () => new LangDownloadFileConfiguration();

  setIcon(value: string | null) {

    this.icon = value;
    return this;

  }

  setColor(value: string | null) {

    this.color = value;
    return this;

  }

  setButtonType(value: 'mat-mini-fab' | 'mat-icon-button' | null) {

    this.buttonType = value;
    return this;

  }

  setLanguage(languages : any){

    this.languages = languages
    return this;

  }

}
