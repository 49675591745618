import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CtBinaryOperator, CtButtonConfiguration, CtModelConfiguration, CTModelDatatableFilter, CtModelOperator, MAT_RAISED_PRIMARY } from '@ctsolution/ct-framework';
import { CTWorkOrder } from 'projects/ct-manager/src/app/_core/classes/ct-work-order';
import { DEFAULT_DATATABLE_CONFIGURATION } from 'projects/ct-manager/src/app/_core/constants';
import { LocationMacroType } from 'projects/ct-manager/src/app/_core/enum/location.macro-type';
import { CTMGeneralService } from 'projects/ct-manager/src/app/_core/lib/general.service';
import { LocationRouteData } from 'projects/ct-manager/src/app/_core/route-data/location.route-data';
import { WorkOrderRouteData } from 'projects/ct-manager/src/app/_core/route-data/work-order.route-data';

@Component({
  selector: 'app-warehouse-workorder',
  templateUrl: './warehouse-workorder.component.html',
  styleUrls: ['./warehouse-workorder.component.scss']
})
export class WarehouseWorkorderComponent {

  @Input() referenceId: string | null = null;
  configuration: CtModelConfiguration<WarehouseWorkorderComponent> | null = null;
  buttonConfiguration : CtButtonConfiguration = CtButtonConfiguration.create()
  .setLabel("Vai alla commessa")
  .setMatherialOptions(MAT_RAISED_PRIMARY)
  .setAction(() => this.router.navigate(["work-order", "edit", this.workOrderList[0].Oid!.toString()]));

  workOrderList : CTWorkOrder<any>[] = [];
  type : LocationMacroType | null = null;


  constructor(private generalService : CTMGeneralService,private router : Router) { }

  ngAfterViewInit() {

    if (!this.referenceId) return;

    this.generalService.getInfos(
      {
        controller: LocationRouteData().controller!,
        oid: +this.referenceId
      }
    ).subscribe(result => {

      if(!result.Result.DataSource) return
      this.type = result.Result.DataSource.Type.MacroType
      if(!result.Result.DataSource.WorkOrder) return

      if(this.type === LocationMacroType.Standard)
      {
          const configuration = CtModelConfiguration
          .create<WarehouseWorkorderComponent>()
          .setRouteData(WorkOrderRouteData())
          .setCTDatatableConfiguration(DEFAULT_DATATABLE_CONFIGURATION())
          .setOnEdit((event: any) => this.router.navigate(["work-order", "edit", event.Oid!.toString()]))

          configuration
          .initialized()
          .subscribe(() => this.configuration?.setOperations([CtModelOperator.Update]))

        const filterValues = configuration.filterValues;

        filterValues
          .addConstantFilter(CTModelDatatableFilter
            .create()
            .setField('Oid')
            .setValue(result.Result.DataSource.WorkOrder.Oid)
            .setOperatorType(CtBinaryOperator.Equal));

        this.configuration = configuration;

      }
      if(this.type === LocationMacroType.Box || this.type === LocationMacroType.Temporary)
      {
        this.generalService.getList({
          controller: WorkOrderRouteData().controller!,
          filters: [
            CTModelDatatableFilter.create()
            .setField('Oid')
            .setValue(result.Result.DataSource.WorkOrder.Oid)
            .setOperatorType(CtBinaryOperator.Equal)
          ]
        }).subscribe(result => {

          if(result.Result.DataSource)

            this.workOrderList = result.Result.DataSource;

        });
      }

    })




  }

}
