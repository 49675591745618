import {Component} from "@angular/core";
import {CTManagerImportConfiguration} from "../../layouts/ct-manager-import/ct-manager-import.configuration";
import {FileController} from "../../_core/controllers/file.controller";

@Component({
  selector: "app-item-import",
  template: `
    <ct-manager-import [configuration]="configuration"></ct-manager-import>`,
  providers: [FileController]
})
export class ItemImportComponent {

  configuration: CTManagerImportConfiguration = CTManagerImportConfiguration
    .create()
    .setImportSubscription((file: File) => this.fileController.itemImport(file))
    .setTitle("itemImport.title")
    .setSubtitle("itemImport.subTitle")
    .setDescription("itemImport.description")
    .setTemplate({
      path: `assets/templates/CTMItemImportTemplate.csv`,
      fileName: `CTMItemImportTemplate${new Date().getFullYear()}`
    });

  constructor(private fileController: FileController) {
  }

}
