import {Component, Input} from "@angular/core";
import {
  CtBinaryOperator,
  CtModelConfiguration, CTModelDatatableFilter
} from "@ctsolution/ct-framework";
import {DEFAULT_DATATABLE_CONFIGURATION} from "../../../../_core/constants";
import {LocationStockRouteData} from "../../../../_core/route-data/location-stock.route-data";

@Component({
  selector: "app-module-stocks",
  template: `
    <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>`
})
export class ModuleStocksComponent {

  @Input() referenceId: string | null = null;
  configuration: CtModelConfiguration<ModuleStocksComponent> | null = null;

  ngAfterViewInit() {

    if (!this.referenceId) return;

    const configuration = CtModelConfiguration
      .create<ModuleStocksComponent>()
      .setRouteData(LocationStockRouteData())
      .setCTDatatableConfiguration(DEFAULT_DATATABLE_CONFIGURATION())
      .setFieldsCustomTemplates([{prop: "Item", visible: false}]);

    configuration
      .filterValues
      .addConstantFilter(
        CTModelDatatableFilter
          .create()
          .setField('Item.Oid')
          .setValue(this.referenceId)
          .setOperatorType(CtBinaryOperator.Equal)
      )

    this.configuration = configuration;

  }

}
