import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CtBinaryOperator, CtButtonConfiguration, CtControlConfiguration, CtControlService, CtControlTypes, CtFormConfiguration, CtModelConfiguration, CTModelDatatableFilter, CtModelDatatableOperators, CtModelService, CtSelectControlOptions, CtThemeTypes, MAT_RAISED_ACCENT, MAT_RAISED_WARN } from '@ctsolution/ct-framework';
import { CTMGeneralService } from 'projects/ct-manager/src/app/_core/lib/general.service';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import { WorkOrderPackagingCreateConfiguration } from './work-order-packaging-form.configuration';
import { CTManagerLocation } from 'projects/ct-manager/src/app/_core/classes/ct-manager-location';
import { LocationRouteData } from 'projects/ct-manager/src/app/_core/route-data/location.route-data';
import { CtWebapiGenericResponse } from '@ctsolution/ct-webapi';
@Component({
  selector: 'app-work-order-packaging-form',
  templateUrl: './work-order-packaging-form.component.html',
  styleUrls: ['./work-order-packaging-form.component.scss']
})
export class WorkOrderPackagingFormComponent {


  configuration: CtFormConfiguration;

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.cancel")
    .setAction(() => this.dialogRef.close())
    .setMatherialOptions(MAT_RAISED_WARN);

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setClass('ct-model-submit-button')
    .setAction(() => this.submit())
    .setMatherialOptions(MAT_RAISED_ACCENT);

  // private referenceNameControl = this.generalService.getTextControl({
  //   name: 'ReferenceName',
  // });

  private nameControl = this.generalService.getTextControl({
    name: 'Name',
    required: true
  }).setLabel("Name").setFlexSpacePercentage(100);


  private boxWeightControl = this.generalService.getTextControl({
    name: 'BoxWeight'
  }).setFlexSpacePercentage(25);

  private boxHeightControl = this.generalService.getTextControl({
    name: 'BoxHeight'
  }).setFlexSpacePercentage(25);

  private boxWidthControl = this.generalService.getTextControl({
    name: 'BoxWidth'
  }).setFlexSpacePercentage(25);

  private boxLengthControl = this.generalService.getTextControl({
    name: 'BoxLength'
  }).setFlexSpacePercentage(25);

  private notesControl: CtControlConfiguration = CtControlConfiguration
    .create()
    .setName('Notes')
    .setType(CtControlTypes.TEXTAREA)
    .setTheme(CtThemeTypes.MATERIAL)
    .setLabel('Note')
    .setFlexSpacePercentage(100);


  private typeLookup =  this.generalService.getLookupControlDefaultConfiguration({
    name: 'Type',
    controller: 'LocationType',
    type: CtControlTypes.ENUMERABLE,
    required : true
  }).setFlexSpacePercentage(100)
  .configureOptions<CtSelectControlOptions>(options => {

    if (!options) options = CtSelectControlOptions.create();

    options
      .setNullValueEnabled(false)
      .setLookupFilter((value) => {

        const filters: CTModelDatatableFilter[] = [
          CTModelDatatableFilter
            .create()
            .setField('MacroType')
            .setValue(2)
            .setOperatorType(CtBinaryOperator.Equal),
        ];

        const operators = CtModelDatatableOperators
          .create()
          .setFilters(filters);

        return operators;

      })

  });



  private controls = [
    this.nameControl,
    this.typeLookup,
    this.boxHeightControl,
    this.boxWeightControl,
    this.boxLengthControl,
    this.boxWidthControl,
    this.notesControl
  ];

  constructor(
    private controlService: CtControlService,
    private generalService: CTMGeneralService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<WorkOrderPackagingFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data: WorkOrderPackagingCreateConfiguration,
    private modelService : CtModelService<any>,
  ) {

    this.configuration = CtFormConfiguration
      .create(this.formBuilder.group({}))
      .setTheme(CtThemeTypes.MATERIAL)
      .setControls(this.controls);

  }

  private submit(){

    if(this.configuration.form == null || this.configuration.form?.invalid) return;

    if (this.configuration.form.valid && this.data.WorkOrderOid && this.data.ParentLocationOid) {

      const value: PackagingDTO = PackagingDTO.create(this.configuration.form.value.Name);

      const conf : CtModelConfiguration<any> = CtModelConfiguration.create().setRouteData(LocationRouteData());

      value
      .setType(this.configuration.form.value.Type)
      .setIsEnabled(true)
      .setIsLoadPermitted(true)
      .setIsUnloadPermitted(false)
      .setWorkOrder(this.data.WorkOrderOid)
      .setParentLocation(this.data.ParentLocationOid)
      .setNote(this.configuration.form.value.Notes)
      .setBoxHeight(this.configuration.form.value.BoxHeight)
      .setBoxLength(this.configuration.form.value.BoxLength)
      .setBoxWeight(this.configuration.form.value.BoxWeight)
      .setBoxWidth(this.configuration.form.value.BoxWidth)

      

      this.modelService
        .putInfos(conf, value)
        ?.subscribe({
          next: (response: CtWebapiGenericResponse<number | null>) => {

            this.dialogRef.close(response.Result);

          },
          error: (err) => console.error('Errore durante l\'operazione:', err)
        });
    }

  }


}

export class PackagingDTO{

  IsEnabled: boolean | null = null;
  LoadPermitted: boolean | null = null;
  UnloadPermitted: boolean | null = null;
  WorkOrder: any | null = null;
  ParentLocation: any | null = null;
  Type: any | null = null;
  Notes: string | null = null;
  BoxWeight : number | null = null;
  BoxHeight : number | null = null;
  BoxWidth : number | null = null;
  BoxLength : number | null = null;

  constructor(public Name : string){}

  public static create = (name : string) : PackagingDTO => new PackagingDTO(name);

  public setWorkOrder(Oid : number){

    this.WorkOrder = {Oid} as any;
    return this;

  }

  public setType(Oid : number){

    this.Type = {Oid} as any;
    return this;

  }

  public setParentLocation(Oid : number){

    this.ParentLocation = {Oid} as any;
    return this;

  }

  public setIsEnabled(value : boolean){

    this.IsEnabled = value;
    return this;

  }

  public setIsLoadPermitted(value : boolean){

    this.LoadPermitted = value;
    return this;

  }

  public setNote(value : string){

    this.Notes = value;
    return this;

  }

  public setIsUnloadPermitted(value : boolean){

    this.UnloadPermitted = value;
    return this;
  }

  public setBoxWeight(value : number | null){

    this.BoxWeight = value;
    return this;

  }

  public setBoxLength(value : number | null){

    this.BoxLength = value;
    return this;

  }

  public setBoxWidth(value : number | null){

    this.BoxWidth = value;
    return this;

  }

  public setBoxHeight(value : number | null){

    this.BoxHeight = value;
    return this;

  }

}