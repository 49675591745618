
<h5>Documenti</h5>

<ct-model *ngIf="modelConfiguration" [configuration]="modelConfiguration"></ct-model>

<ng-template #downloadAttachmentButton let-value="value">

  <button
    mat-icon-button
    [color]="'accent'"
    [matMenuTriggerFor]="menu">
    <mat-icon>attachment</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button (click)="download(value)" mat-menu-item>{{ value.FileName }}
    </button>
  </mat-menu>

</ng-template>

<ng-template #legendTemplate>

  <ct-button *ngIf="showZipButton" [configuration]="zipDownloadAttachmentButton"></ct-button>

</ng-template>
