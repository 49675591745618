import {Component, Input} from '@angular/core';
import {HandlingOriginEnum} from "../../../../../_core/enum/handling-origin.enum";
import {ModuleCreationRequestResult} from "../../../../../_core/controllers/item.controller";
import {Handling} from "../../../../../_core/classes/handling";

@Component({
  selector: 'app-module-create-handlings',
  templateUrl: './module-create-handlings.component.html',
  styleUrls: ['./module-create-handlings.component.scss']
})
export class ModuleCreateHandlingsComponent {
  @Input() model: ModuleCreationRequestResult | null = null;

  get handlings(): Handling[] {

    return this.getItemHandlingsByType(HandlingOriginEnum.Location)

  }

  get orders(): Handling[] {

    return this.getItemHandlingsByType(HandlingOriginEnum.Supplier)

  }

  private getItemHandlingsByType(handlingOrigin: HandlingOriginEnum): Handling[] {

    return (this.model?.Handlings ?? [])
      .filter(handling => handling.HandlingOrigin === handlingOrigin);

  }

}
