import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CtButtonModule, CtControlModule, CtFormModule } from '@ctsolution/ct-framework';
import { TranslateModule } from '@ngx-translate/core';
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import { MassivePaymentsComponent } from './massive-payments.component';
import { TableModule } from 'primeng/table';
import { MassiveChangesService } from '../../massive-changes.service';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CtControlModule,
    FormsModule,
    ReactiveFormsModule,
    CtButtonModule,
    MatDialogModule,
    CtFormModule,
    TableModule
  ],
  declarations: [MassivePaymentsComponent],
  exports: [MassivePaymentsComponent],
  providers: [MassiveChangesService]
})
export class MassivePaymentsModule { }
