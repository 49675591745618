import {Component} from "@angular/core";
import {environment} from "../environments/environment";
import {LangService} from "./_core/lib/lang.service";
import {SnackbarService} from "@ctsolution/ct-framework";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html"
})
export class AppComponent {

  defaultLang = environment.defaultLang;

  constructor(
    private snackbar: SnackbarService,
    private lang: LangService) {

    this.lang
      .useLang()
      .then(() => {

        if (!environment.production) {

          this.snackbar.open("devMode");

        }

      })

  }

}
