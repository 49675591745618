import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {WarehouseEditComponent} from "./warehouse-edit.component";
import {CtModelModule} from "@ctsolution/ct-framework";

@NgModule({
  declarations: [
    WarehouseEditComponent
  ],
  imports: [
    CommonModule,
    CtModelModule
  ]
})
export class WarehouseEditModule {
}
