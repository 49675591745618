import { AfterViewInit, ChangeDetectorRef, Component, TemplateRef, ViewChild } from '@angular/core';
import { CtDatatableActionCustomConfiguration, CtDatatableContainerFilterValues, CtModelConfiguration, CtSortOrderDescriptorParameter } from '@ctsolution/ct-framework';
import { DEFAULT_DATATABLE_CONFIGURATION } from '../../../_core/constants';
import { FormModuleDataRouteData } from '../../../_core/route-data/form-module-data.route-data';
import { CTMGeneralService } from '../../../_core/lib/general.service';
import { FileGet } from '../../../_core/classes/file-get';
import { FileService } from '../../../_core/lib/file.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-form-module-data-list',
  templateUrl: './form-module-data-list.component.html'
})
export class FormModuleDataListComponent implements AfterViewInit {

  configuration: CtModelConfiguration<FormModuleDataListComponent> | null = null;
  datatableContainerFilterValues: CtDatatableContainerFilterValues | null = null;

  @ViewChild("downloadAttachmentButton") downloadAttachmentButton: TemplateRef<any> | null = null;




  constructor(private general: CTMGeneralService, private fileService: FileService, private route : ActivatedRoute, private cdr : ChangeDetectorRef,private translate : TranslateService) { }


  ngAfterViewInit() {

    this.setup();

  }

  download(file: FileGet | null) {

    if (!file) return;

    this.fileService.downloadAttachmentByEndPoint(file);

  }

  setup() {

    this.route.queryParams.subscribe(params => {
      const q = params['q'];

      if (q) {

        const filterValues = JSON.parse(q);
        this.datatableContainerFilterValues = CtDatatableContainerFilterValues.create(filterValues);

        this.route
        .data
        .subscribe(data => {
  
          const value = this.datatableContainerFilterValues?.constants.find((item) => item.Field === 'FormTemplate.Code')?.Value


          setTimeout(() => {

            data['title'] = `${this.translate.instant("formmoduledata")} - ${this.translate.instant(value)}`;
            data['urls'] = [{ title: data['title'] }];

          },300)
          //altrimenti al refresh della pagina non fa in tempo la traslate

          this.cdr.detectChanges();

        })

      }
    });

    const datatableConfiguration = DEFAULT_DATATABLE_CONFIGURATION()
      .setCustomDatatableActionConfiguration(
        CtDatatableActionCustomConfiguration
          .create()
          .setTemplateOutlet(this.downloadAttachmentButton));

    const configuration = CtModelConfiguration
      .create<FormModuleDataListComponent>()
      .setRouteData(FormModuleDataRouteData())
      .setOrderValues(
        new Array<CtSortOrderDescriptorParameter>(
          CtSortOrderDescriptorParameter.create('CreateAt').setIsDescending(true)))
      .setCTDatatableConfiguration(datatableConfiguration)
      .setOnCreate(() => this.general.navigateOnCTModelRouter(["form-module-data"], "create", { qpHandling: 'preserve' }))
      .setOnEdit(event => this.general.navigateOnCTModelRouter(["form-module-data"], "edit", {
        value: event,
        configuration: this.configuration,
        qpHandling: 'preserve'
      }))

    this.configuration = configuration;

  }

}

