import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompanyInformationComponent} from './company-information.component';
import {CtButtonModule, CtModelModule} from "@ctsolution/ct-framework";
import {CompanyInformationService} from "./company-information.service";
import {MatDividerModule} from "@angular/material/divider";
import {TranslateModule} from "@ngx-translate/core";
import {MatLegacyCardModule} from "@angular/material/legacy-card";

@NgModule({
  declarations: [
    CompanyInformationComponent
  ],
  exports: [
    CompanyInformationComponent
  ],
  imports: [
    CommonModule,
    CtModelModule,
    CtButtonModule,
    MatDividerModule,
    TranslateModule,
    MatLegacyCardModule
  ],
  providers: [CompanyInformationService]
})
export class CompanyInformationModule {
}
