<mat-card class="p-20">
  <mat-card-header>

    <mat-card-title> Modifica modulo</mat-card-title>

  </mat-card-header>

  <mat-card-content>

    <ct-form [configuration]="configuration"></ct-form>

    <mat-card-actions fxLayout="row wrap" fxLayoutAlign="space-between">

      <ct-button [configuration]="cancelButton"></ct-button>

      <ct-button [configuration]="submitButton"></ct-button>

    </mat-card-actions>

  </mat-card-content>

</mat-card>

