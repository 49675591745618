import {Injectable} from '@angular/core';
import {CtAuthenticationService} from "@ctsolution/ct-authentication";
import {UserCompanySettings} from "../classes/user-company-settings";
import {CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  constructor(private CTAuthenticationService: CtAuthenticationService, private _webapi: CtWebapiService) {
  }

  async getUserEmail(): Promise<string | null> {

    await this.CTAuthenticationService.claimsUploaded();
    return this.CTAuthenticationService.getJwtValueByContainedKey('email', false);

  }

  async getUserInfoOid(): Promise<string | null> {

    await this.CTAuthenticationService.claimsUploaded();
    return this.CTAuthenticationService.getJwtValueByContainedKey('Oid');

  }

  async getDailyWorkingHours(): Promise<number | null> {

    await this.CTAuthenticationService.claimsUploaded();

    const value: string | null = this.CTAuthenticationService.getJwtValueByContainedKey('DailyWorkingHours');

    if (value && !isNaN(+value)) return +value;

    return null;

  }

  private cachedUserCompanySettings: UserCompanySettings | null = null;

  async getUserCompanySettings(): Promise<UserCompanySettings | null> {

    if (this.cachedUserCompanySettings !== null) return this.cachedUserCompanySettings;

    try {

      const request: DataRequest = new DataRequest(`${window.location.origin}/assets/temp-company-settings.json?hideError=true`);

      const configuration: UserCompanySettings = await this._webapi
        .get(request)
        .toPromise();

      if (!configuration) return null;

      this.cachedUserCompanySettings = UserCompanySettings.create(configuration);

      return this.cachedUserCompanySettings;

    } catch (error) {

      console.error("An error occurred while requesting company settings:", error);
      return null;
    }

  }

}
