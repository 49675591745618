export class NoteDialogConfiguration{


    public FileOid: number | null = null;
    public Type : FileOriginEnum | null = null;
    public Reload : boolean = false;
    constructor(){}

    public static create = () => new NoteDialogConfiguration();

    public setFileOid(value: number | null) {
        this.FileOid = value;
        return this;
    }

    public setType(value: FileOriginEnum | null) {
        this.Type = value;
        return this;
    }

    public setReload(value: boolean) {
      this.Reload = value;
      return this;
    }

}

export enum FileOriginEnum{

    WorkOrder = 1,
    WorkingHour = 2,
    Lead = 3,

}
