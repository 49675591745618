import {ChangeDetectorRef, Component} from '@angular/core';
import {CtModelConfiguration, CtModelRouteData, CtModelType} from "@ctsolution/ct-framework";
import {ActivatedRoute} from "@angular/router";
import {UserRouteData} from "../../../_core/route-data/user.route-data";
import {CtFieldCustomColumn} from "@ctsolution/ct-framework/lib/ct-datatable/_core/ct-field-custom.column";
import {Role} from "../../../_core/enum/role";
import {UserFeeManagerConfiguration} from "../user-fee-manager/user-fee-manager.configuration";
import {CtManagerService} from "../../../_core/lib/ct-manager.service";

@Component({
  selector: 'app-user-edit',
  template: `
    <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>
    <ng-container *ngIf="enableFeeManager">
      <div class="ct-manager-master-detail-container">
        <app-user-fee-manager [configuration]="userFeeManagerConfiguration"></app-user-fee-manager>
      </div>
    </ng-container>

    <ng-container *ngIf="enableMasterDetail && this.viewModel.MONITORING">

      <app-user-info-work-data></app-user-info-work-data>

    </ng-container>
  `
})
export class UserEditComponent {

  configuration: CtModelConfiguration<UserEditComponent> | null = null;
  userFeeManagerConfiguration: UserFeeManagerConfiguration = UserFeeManagerConfiguration
    .create();

  viewModel = {

    CRM: false,
    MONITORING : false

  }

  get enableMasterDetail(): boolean {

    return !!this.configuration?.RouteData?.id;

  }

  get userHasAgentRole() {

    return this.configuration?.form?.value?.Role?.toLowerCase() === Role.AGENT.toLowerCase();

  }

  get enableFeeManager() {

    return this.userHasAgentRole && this.enableMasterDetail && this.viewModel.CRM;

  }

  constructor(private route: ActivatedRoute, private managerService: CtManagerService,private cdr : ChangeDetectorRef) {
  }

  ngAfterViewInit() {

    this.setup();


    this.route
    .data
    .subscribe(data => {

      data['title'] = 'CT_PAGE.BREADCRUMBS.DetailUser';
      this.cdr.detectChanges();

    })


  }

  async setup() {

    const routeData: CtModelRouteData = UserRouteData()
      .setModelType(CtModelType.FORM);

    const pId: string | null = this.route.snapshot.paramMap.get("id");

    if (pId) {

      routeData
        .setId(+pId);

      this.userFeeManagerConfiguration
        .setUserOid(routeData.id)

    }

    this.viewModel.CRM = await this.managerService.hasEnabled.CRM() ?? false;
    this.viewModel.MONITORING = await this.managerService.hasEnabled.monitoring() ?? false;
    const userHasMonitoringManagementEnabled = await this.managerService.hasEnabled.monitoring() ?? false;

    const fieldsCustomTemplates: CtFieldCustomColumn[] = [
      {prop: "DailyWorkingHours", visible: userHasMonitoringManagementEnabled},
      {prop: "WeeklyWorkingHours", visible: userHasMonitoringManagementEnabled},
      {prop: "WorkJob", visible: userHasMonitoringManagementEnabled},
    ];

    this.configuration = CtModelConfiguration
      .create<UserEditComponent>()
      .setRouteData(routeData)
      .setFieldsCustomTemplates(fieldsCustomTemplates)

  }

}
