import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkOrderPackagingFormComponent } from './work-order-packaging-form.component';
import { CtButtonModule, CtFormModule } from '@ctsolution/ct-framework';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { FlexModule } from '@angular/flex-layout';

@NgModule({
  imports: [
    CommonModule,
    CtFormModule,
    FormsModule,
    ReactiveFormsModule,
    CtButtonModule,
    MatCardModule,
    FlexModule
  ],
  declarations: [WorkOrderPackagingFormComponent],
  exports : [WorkOrderPackagingFormComponent]
})
export class WorkOrderPackagingFormModule { }
