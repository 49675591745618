export class UserInfoWorkDataEditConfiguration{

    Oid?: number;
    UserOid? : number;
    constructor() {}

    public static create = (): UserInfoWorkDataEditConfiguration => new UserInfoWorkDataEditConfiguration();

    public setOid (oid: number): UserInfoWorkDataEditConfiguration
    {
        this.Oid = oid;
        return this;
    }
    public setUserOid (oid: number): UserInfoWorkDataEditConfiguration
    {
        this.UserOid = oid;
        return this;
    }
}