import {Component} from '@angular/core';
import {
  CtButtonConfiguration,
  CtControlConfiguration,
  CtControlTypes,
  CtThemeTypes,
  MAT_RAISED_ACCENT,
  MAT_RAISED_WARN
} from "@ctsolution/ct-framework";
import {MatLegacyDialogRef} from "@angular/material/legacy-dialog";
import {FormBuilder, FormGroup} from "@angular/forms";
import {CTMGeneralService} from "../../../../_core/lib/general.service";

@Component({
  selector: 'app-work-order-import-file-configuration-dialog',
  templateUrl: './work-order-import-file-configuration-dialog.component.html'
})
export class WorkOrderImportFileConfigurationDialogComponent {

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.cancel")
    .setAction(() => this.onCancel())
    .setMatherialOptions(MAT_RAISED_WARN);

  finalizeButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("Salva")
    .setAction(() => this.onImport())
    .setMatherialOptions(MAT_RAISED_ACCENT);

  form: FormGroup;
  configurationLookup: CtControlConfiguration = this.generalService
    .getLookupControlDefaultConfiguration({
      name: 'Configuration',
      controller: 'FileImportConfiguration',
      type: CtControlTypes.ENUMERABLE
    })
    .setTheme(CtThemeTypes.MATERIAL)
    .setLabel('Configurazione')
  // .configureOptions<CtSelectControlOptions>(options => {
  //
  //   if (!options) options = CtSelectControlOptions.create();
  //
  //   options
  //     .setLookupResponseMapper(response => {
  //
  //       const dataSource = (<Array<any>>response.Result.DataSource ?? []);
  //
  //       this.configurationLookup
  //         .setValue(dataSource[0].Oid);
  //
  //       return dataSource
  //         .map(elm => CtSelectControlValue.create().setLabel(elm.ReferenceName).setValue(elm.Oid));
  //
  //
  //     })
  //
  // })

  OverwriteSameCodeItemsControl: CtControlConfiguration = this.generalService.getTextControl({

    name: 'OverwriteSameCodeItems',

  })
    .setType(CtControlTypes.CHECKBOX)
    .setTheme(CtThemeTypes.MATERIAL)
    .setLabel('Aggiorna le righe con lo stesso codice')

  constructor(
    private dialogRef: MatLegacyDialogRef<WorkOrderImportFileConfigurationDialogComponent>,
    private formBuilder: FormBuilder,
    private generalService: CTMGeneralService
  ) {

    this.form = this.formBuilder.group({})

    this.configurationLookup
      .setFormParent(this.form);

    this.OverwriteSameCodeItemsControl
      .setFormParent(this.form);

    /*
    * TODO: Davvero timeout necessario?
    * DAM -> ti ho commentato nella configurazione della input cosa dovresti fare. Devi avere un ritorno quando il component ha finito di caricare i dati.
    * Quindi ora sostanzialmente fai l'override della funzione di mapping. In futuro sarebbe comodo avere solo un ping quando il component ha finito di caricare
    * */

    setTimeout(() => {
      if (this.configurationLookup.valueOptions?.length)
        this.configurationLookup.setValue(this.configurationLookup.valueOptions[0].value);
    }, 500);

  }

  onCancel() {

    this.dialogRef.close();

  }

  onImport() {

    if (this.form.invalid) return;

    const result = {

      Code: this.configurationLookup.control?.value,
      OverwriteSameCodeItems: this.OverwriteSameCodeItemsControl.control?.value

    }

    this.dialogRef
      .close(result)

  }

}
