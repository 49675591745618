import {Component, Input} from '@angular/core';
import {
  CtBinaryOperator, CTDatatablePaginationParameter,
  CtModelConfiguration,
  CTModelDatatableFilter,
  CtModelDatatableOperators,
  CtModelRouteData, CtModelService,
  CtModelType, CtSortOrderDescriptorParameter, SnackbarService
} from "@ctsolution/ct-framework";
import {CtWebapiGenericResponse, CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";
import {LeadPlaceRouteData} from "../../../_core/route-data/lead-place.route-data";

@Component({
  selector: 'app-lead-place-list',
  templateUrl: './lead-place-list.component.html',
  styles: [`
    .lead-places-container {
      .add-button {
        background-color: var(--primary-color);
        cursor: pointer;

        .mat-card-content {
          display: flex;
          min-height: 447px !important;

          button {
            margin: auto;
            background: white;

            mat-icon {
              color: var(--primary-color);
            }
          }
        }
      }
    }
  `]
})
export class LeadPlaceListComponent {

  @Input() masterConfiguration: CtModelConfiguration<any> | null = null;
  configurations: Array<CtModelConfiguration<any>> = [];

  constructor(private _webapi: CtWebapiService, private _model: CtModelService<any>, private _snackbar: SnackbarService) {
  }

  ngOnInit() {

    if (this.masterConfiguration?.RouteData?.id) {

      this.setup();

    }

  }

  setup() {

    const body: CtModelDatatableOperators = CtModelDatatableOperators
      .create()
      .setFilters([
        CTModelDatatableFilter
          .create()
          .setField('Lead.Oid')
          .setValue(this.masterConfiguration?.RouteData?.id)
          .setOperatorType(CtBinaryOperator.Equal)
      ])
      .setPagination(
        new CTDatatablePaginationParameter()
          .setSortOrders([
            CtSortOrderDescriptorParameter.create('Oid')
          ])
      )

    const request: DataRequest = new DataRequest()
      .setController([LeadPlaceRouteData().controller!])
      .setAction('List')
      .setQueryParams({hideSpinner: true})
      .setBody(body)

    this._webapi
      .post<CtModelConfiguration<any>>(request)
      .subscribe((result: CtWebapiGenericResponse<CtModelConfiguration<any>>) => {

        this.configurations = new Array<CtModelConfiguration<any>>();

        if (!(result.Result?.DataSource ?? []).length) {

          //this.addNewConfiguration();  rimosso, momentaneamente non neccessario
          return;

        }

        result
          .Result
          .DataSource
          .forEach((ds: any) => {

            const configuration: CtModelConfiguration<any> = this.getDefaultConfiguration(ds.Oid).setDataSource(ds);

            this.configurations
              .push(configuration);

          })

      });

  }


  addNewConfiguration() {

    this.configurations
      .push(this.getDefaultConfiguration());

  }

  private getDefaultRouteData = () => CtModelRouteData
    .create()
    .setModelType(CtModelType.FORM)
    .setController(`${window.location.origin}/assets/demo/lead-place-model.json`);

  private getLeadPlaceConfiguration = () => CtModelConfiguration
    .create()
    .setRouteData(
      CtModelRouteData
        .create()
        .setController(LeadPlaceRouteData().controller)
    );

  private getDefaultConfiguration = (id: number | null = null) => CtModelConfiguration
    .create()
    .setRouteData(this.getDefaultRouteData())
    .setDefaultActionsEnabled(false)
    .setOnCreate((event: any) => {

      const configuration: CtModelConfiguration<any> = this.getLeadPlaceConfiguration();

      if (id) {

        configuration
          .RouteData
          ?.setId(id);

      }

      event
        .LeadOid = this.masterConfiguration?.RouteData?.id ?? -1;

      this._model
        .putInfos(configuration, event)
        ?.subscribe(() => {

          this._snackbar.onSuccess();

          this.setup()

        });

    })
    .setOnDelete((value: { idx: number }) => {

      if (!id) {

        this.configurations.pop();
        return;

      }

      const configuration: CtModelConfiguration<any> = this.getLeadPlaceConfiguration();

      configuration
        .RouteData
        ?.setId(id);

      const controller: string | undefined | null = configuration.RouteData?.controller;

      if (!controller) return;

      const request: DataRequest = new DataRequest()
        .setController([controller, 'Delete'])
        .setAction(id.toString());

      this._webapi
        .delete(request)
        .subscribe(() => {

          this._snackbar.onSuccess();
          this.setup()

        });

    });

}
