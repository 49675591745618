import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LangDownloadFileConfiguration} from "./lang-download-file.configuration";

@Component({
  selector: 'app-lang-download-file',
  templateUrl: './lang-download-file.component.html'
})
export class LangDownloadFileComponent {

  @Input() configuration: LangDownloadFileConfiguration | null = null;
  @Output() onDownload: EventEmitter<string> = new EventEmitter<string>();


}
