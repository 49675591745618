import {Component} from '@angular/core';
import {
  CtControlConfiguration,
  CtControlTypes, CtControlValidator, CTFieldInfos,
  CtFormContainerHelper, CtModelConfiguration,
  CtRangeControlOptions,
  CtThemeTypes
} from "@ctsolution/ct-framework";
import {JwtService} from "../../../../../_core/lib/jwt.service";
import {CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";

const UserWorkingHoursHoursControl: CTFieldInfos = CTFieldInfos.create({
  Field: "Hours",
  Type: "InputText",
  IsNullable: false,
  ControlType: "InputText",
} as CTFieldInfos);

const DEFAULT_WORKING_HOURS_VALUE = 8;

@Component({
  selector: 'app-user-working-hours-hours-control',
  template: `
    <ct-control [configuration]="control"></ct-control>`
})
export class UserWorkingHoursHoursControlComponent {

  control: CtControlConfiguration = CtControlConfiguration
    .create(
      CtFormContainerHelper
        .create()
        .getCTFormControlByCTFieldInfo(UserWorkingHoursHoursControl))
    .setTheme(CtThemeTypes.MATERIAL)
    .setType(CtControlTypes.COUNTER)
    .setOptions(
      CtRangeControlOptions
        .create()
        .setStep(0.5)
    )
    .setValidators([
      CtControlValidator.create({name: 'required'} as CtControlValidator),
      CtControlValidator.create({name: "min", value: 0.5} as CtControlValidator),
      CtControlValidator.create({name: "max", value: 24} as CtControlValidator)
    ])
    .setReadonly(true);

  constructor(private jwt: JwtService, private _webapi: CtWebapiService) {
  }


  ngOnInit() {

    this.setup();

  }


  async setup(hours: number | null = null) {

    const userWorkingHours = hours ?? await this.jwt.getDailyWorkingHours() ?? null;

    this.control
      .setValue(userWorkingHours ?? DEFAULT_WORKING_HOURS_VALUE)

  }

  setupByUserInfo(userInfoOid: number) {

    const request: DataRequest = new DataRequest()
      .setController(['user', 'GetUserWorkingHours'])
      .setAction(userInfoOid.toString())
      .setQueryParams({hideSpinner: true});

    this._webapi
      .get(request)
      .subscribe({
        next: (result: number | null) => {

          if (result) {

            this.setup(result)

          }

        }
      });

  }

}
