//usare mat-dialog legacy
//creare funzione di apertura modale che aprirà il component work-order-packaging
import { Injectable } from '@angular/core';
import {MatLegacyDialog} from "@angular/material/legacy-dialog";
import { WorkOrderPackagingComponent } from './work-order-packaging.component';
import { WorkOrderPackagingConfiguration } from './work-order-packaging.configuration';

@Injectable({
  providedIn: 'root'
})
export class PackagingService {

  constructor(private dialog: MatLegacyDialog) {}

  openDialogPackaging(param : WorkOrderPackagingConfiguration): void {
    const dialogRef = this.dialog.open(WorkOrderPackagingComponent, {
      data: param,
      disableClose: true
    });

  }
}
