import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferListComponent } from './offer-list.component';
import { CtModelModule } from '@ctsolution/ct-framework';

@NgModule({
  imports: [
    CommonModule,
    CtModelModule
  ],
  declarations: [OfferListComponent],
  exports : [OfferListComponent]
})
export class OfferListModule { }
