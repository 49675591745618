<div style="padding: 5px 15px" fxLayout="row wrap" fxLayoutAlign="space-between center">

  <mat-slide-toggle
    labelPosition="before"
    [(ngModel)]="automaticRefreshIsToggled"
    (change)="toggleSlideToggle()"> {{"automaticRefresh"| translate}}
  </mat-slide-toggle>

  <ct-button
    [configuration]="createButton"></ct-button>

</div>

<ng-container
  *ctSkeleton="loading;
           repeat: 3;
           className: 'temporary-area-box-skeleton';">

  <ng-container *ngIf="list.length === 0; else listTemplate">

    <p> {{"noItemsAvailableLabel"| translate}}.</p>

  </ng-container>
  <ng-template #listTemplate>

    <div fxLayout="row wrap">

      <div
        *ngFor="let item of list"
        [fxFlex.gt-sm]="50"
        fxFlex.gt-xs="100"
        fxFlex="100">

        <app-temporary-area-list-box
          [item]="item"
          (onEdit)="edit(item)"
          (onDetail)="detail(item)"
          (onDelete)="delete(item)"></app-temporary-area-list-box>

      </div>

    </div>

  </ng-template>

</ng-container>
