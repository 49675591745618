import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CTGeneralService, CtButtonConfiguration, CtControlService, CtControlTypes, CtFormConfiguration, CtSelectControlOptions, CtSelectControlValue, CtThemeTypes, MAT_RAISED_ACCENT, MAT_RAISED_WARN } from '@ctsolution/ct-framework';
import { FormModuleData } from '../../../_core/classes/form-module-data';
import { StatusFormModuleData } from '../../../_core/enum/status-form-module-data';

@Component({
  selector: 'app-form-module-data-form',
  templateUrl: './form-module-data-form.component.html'
})
export class FormModuleDataFormComponent implements AfterViewInit {

  @Output() onSubmit: EventEmitter<FormGroup | null> = new EventEmitter<FormGroup | null>();
  configuration: CtFormConfiguration;
  @Input() dataSource!: FormModuleData | null;

  private StatusControl = this.controlService.getDefaultConfiguration({
    name: 'Status',
    type: CtControlTypes.ENUMERABLE,
    flexSpacePercentage: 100,
    required: true
  })
    .configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        .setNullValueEnabled(true)
    })
    .setValueOptions([
      CtSelectControlValue.create().setLabel('Draft').setValue(StatusFormModuleData.Bozza),
      CtSelectControlValue.create().setLabel('Cancelled').setValue(StatusFormModuleData.Cancelled),
      CtSelectControlValue.create().setLabel('ToValidate').setValue(StatusFormModuleData.DaValidare),
      CtSelectControlValue.create().setLabel('Validated').setValue(StatusFormModuleData.Validato),
    ])
  private controls = [

    this.StatusControl

  ];

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.cancel")
    .setAction(() => this.CTGeneralService.back())
    .setMatherialOptions(MAT_RAISED_WARN);

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setClass('ct-model-submit-button')
    .setAction(() => this.onSubmit.emit(this.configuration.form))
    .setMatherialOptions(MAT_RAISED_ACCENT);

  constructor(
    private CTGeneralService: CTGeneralService,
    private formBuilder: FormBuilder,
    private controlService: CtControlService) {

    this.configuration = CtFormConfiguration
      .create(this.formBuilder.group({}))
      .setTheme(CtThemeTypes.MATERIAL)
      .setControls(this.controls);

  }

  ngAfterViewInit(): void {

    this.setup();

  }

  setup(){

    if(this.dataSource?.Status == StatusFormModuleData.Validato) this.StatusControl.setDisabled(true);
    this.StatusControl.setValue(this.dataSource?.Status);

  }

}


