import {Component} from '@angular/core';
import {CtModelConfiguration} from "@ctsolution/ct-framework";
import {DEFAULT_DATATABLE_CONFIGURATION} from "../../../_core/constants";
import {ContactRouteData} from "../../../_core/route-data/contact.route-data";
import {CTMGeneralService} from "../../../_core/lib/general.service";

@Component({
  selector: 'app-contact-list',
  template: `
    <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>`
})
export class ContactListComponent {

  configuration: CtModelConfiguration<ContactListComponent> | null = null;

  constructor(private general: CTMGeneralService) {
  }

  ngAfterViewInit() {

    this.setup();

  }

  async setup() {

    this.configuration = CtModelConfiguration
      .create<ContactListComponent>()
      .setRouteData(ContactRouteData())
      .setCTDatatableConfiguration(DEFAULT_DATATABLE_CONFIGURATION())
      .setOnCreate(() => this.general.navigateOnCTModelRouter(["contact"], "create"))
      .setOnEdit(event => this.general.navigateOnCTModelRouter(["contact"], "edit", {
        value: event,
        configuration: this.configuration
      }))

  }

}
