<ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>

<ng-template #downloadAttachmentButton let-value="value">

  <button
    mat-icon-button
    [color]="(value?.Files ?? []).length > 0 ? 'accent' : 'warn'"
    [disabled]="(value?.Files ?? []).length <= 0"
    [matMenuTriggerFor]="menu">
    <mat-icon>attachment</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button (click)="download(file)" *ngFor="let file of (value?.Files ?? [])" mat-menu-item>{{ file.FileName }}
    </button>
  </mat-menu>

</ng-template>
