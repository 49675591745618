import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {CtButtonConfiguration, MAT_RAISED_PRIMARY, MAT_RAISED_WARN} from "@ctsolution/ct-framework";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import {debounceTime, distinctUntilChanged} from "rxjs";
import {FormControl} from "@angular/forms";
import {ItemHandling} from "../../../../../../_core/classes/item-handling";

enum ScannerMode {

  Insert,
  Remove

}

@Component({
  selector: 'app-barcode-tracking',
  templateUrl: './barcode-tracking.component.html',
  styleUrls: ['./barcode-tracking.component.scss']
})
export class BarcodeTrackingComponent implements OnInit {

  mode: ScannerMode | null = null;
  control: FormControl = new FormControl(null);

  previewButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("confirm")
    .setClass("w-100")
    .setAction(() => this.close())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      items: ItemHandling[],
      selected: ItemHandling
    },
    private dialogRef: MatDialogRef<BarcodeTrackingComponent>
  ) {
  }

  get barcodesCount(): number {

    return this.data
      .items
      .reduce((acc, oggetto) => {
        const count = oggetto.Barcodes.length;
        return acc + count;
      }, 0);

  }

  get items(): ItemHandling[] {

    return this.data.selected ? [this.data.selected] : this.data.items;

  }

  ngOnInit() {

    this.control
      .valueChanges
      .pipe(debounceTime(750), distinctUntilChanged())
      .subscribe(value => {

        const barcodes: string[] = (value ?? '').split(/\s+/);

        switch (this.mode) {

          case ScannerMode.Insert:

            // this.data
            //   .items
            //   .forEach(elm => {
            //
            //     elm.oggetto = []
            //
            //     for (let i = 0; i < (elm.Qty ?? 1); i++) {
            //
            //       if (barcodes.length === 0) return;
            //       elm.oggetto.push(barcodes.pop() ?? '');
            //
            //     }
            //
            //   });

            break;

          case ScannerMode.Remove:
          //
          // this.data
          //   .items
          //   .forEach(elm => {
          //
          //     elm
          //       .oggetto
          //       .forEach((brc, index) => {
          //
          //         if (barcodes.includes(brc)) {
          //
          //           elm.Barcodes.splice(index, 1);
          //
          //         }
          //
          //       })
          //
          //   })

        }

      });

  }

  selectMode(value: ScannerMode) {

    this.mode = value;

    setTimeout(() => document.getElementById("textarea")?.focus(), 0);

  }

  onInput(event: any) {

    const textarea = event.target;
    const value = textarea.value;
    const words = value.split(/\s+/); // dividi la stringa in parole

    const newWords = words.map((word: any) => {
      const alphanumeric = word.replace(/[^a-zA-Z0-9]/g, ''); // rimuovi i caratteri non alfanumerici
      return alphanumeric.length > 0 ? alphanumeric : word; // se la parola è vuota, lascia vuota
    });

    const newText = newWords.join('\n'); // unisci le parole con un a capo
    textarea.value = newText;

  }

  close() {

    this.dialogRef
      .close();

  }

  protected readonly ScannerMode = ScannerMode;

}
