<div class="lead-information-cntr" *ngIf="this.companyOid">
  <mat-card>
    <mat-card-content>
      <ng-container
        *ngIf="placeInformation.length > 0; else NoPlaceInformation"
      >
        <h3>Indirizzi:</h3>

        <div fxLayout="row wrap">
          <div
            fxFlex="33.33"
            fxFlex.sm="100"
            *ngFor="let item of placeInformation; let last = last"
          >
            <span *ngIf="item.PlaceType.Name">
              <strong>{{ item.PlaceType.Name }} </strong><br
            /></span>
            <span *ngIf="item.City"> {{ item.City }} </span>
            <span *ngIf="item.Name"> {{ item.Name }} <br /></span>
            <span *ngIf="item.PostalCode"> {{ item.PostalCode }} </span>
            <span *ngIf="item.Province"> {{ item.Province }} </span>
          </div>
        </div>
      </ng-container>

      <ng-template #NoPlaceInformation>
        <div>
          <h4>Nessun indirizzo disponibile.</h4>
          <h5>
            Clicca su "Aggiungi indirizzo" per inserire un nuovo indirizzo.
          </h5>
        </div>
      </ng-template>
    </mat-card-content>

    <div class="row-button-cntr">
      <mat-card-actions>
        <ct-button [configuration]="configurationButton"></ct-button>
      </mat-card-actions>
    </div>
  </mat-card>
</div>
