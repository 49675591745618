import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeadCreateWorkorderComponent } from './lead-create-workorder.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import { CtButtonModule } from '@ctsolution/ct-framework';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    CtButtonModule,
    FlexLayoutModule
  ],
  declarations: [LeadCreateWorkorderComponent],
  exports: [LeadCreateWorkorderComponent]
})
export class LeadCreateWorkorderModule { }
