import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CtAuthenticationService, SignInParameter} from "@ctsolution/ct-authentication";
import {
  CTGeneralService,
  CtTogglePasswordVisibilityButtonConfiguration,
  FormHelperService
} from "@ctsolution/ct-framework";
import {environment} from "../../../environments/environment";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html"
})
export class LoginComponent implements OnInit {

  passwordControlVisibilityConfiguration: CtTogglePasswordVisibilityButtonConfiguration = CtTogglePasswordVisibilityButtonConfiguration
    .create()
    .setInputId("passwordControl");

  public form: FormGroup = Object.create(null);
  private returnUrl: string | null = null;

  viewModel = {

    projectName: environment.projectName

  };

  constructor(
    private fb: FormBuilder,
    private general: CTGeneralService,
    private _auth: CtAuthenticationService,
    private formHelper: FormHelperService,
    private route: ActivatedRoute,
    private router: Router) {

    if (this._auth.userValue) {

      this.general.navigateToRoot();

    } else {

      this.route
        .queryParams
        .subscribe(qp => {

          if (qp["returnUrl"]) {

            this.returnUrl = qp["returnUrl"];

          }

        });

    }

  }

  ngOnInit(): void {

    this.form = this.fb.group({
      Email: [null, Validators.compose([Validators.required])],
      Password: [null, Validators.compose([Validators.required])],
      RememberMe: [false]
    });

  }

  onSubmit(): void {

    if (this.form.valid) {

      this._auth
        .signIn(this.form.value as SignInParameter)
        .subscribe(() => {

          if (this.returnUrl) {

            this.router
              .navigateByUrl(this.returnUrl);

          } else {

            this.general.navigateToRoot();

          }

        });

    } else {

      this.formHelper.scrollToError();

    }

  }

}
