import {Component, OnInit} from "@angular/core";
import {JwtService} from "../../_core/lib/jwt.service";
import {UserCompanySettings} from "../../_core/classes/user-company-settings";
import {
  CtBinaryOperator,
  CtCardConfiguration,
  CtModelConfiguration,
  CTModelDatatableFilter,
  CtModelDatatableOperators,
  CtModelService,
  CtNoteWidgetConfiguration,
  CtNoteWidgetServerSideConfiguration
} from "@ctsolution/ct-framework";
import {
  ENTRY_HANDLING_CARD,
  IMPORT_DATA_CARD, ITEM_CREATE_CARD, ITEM_LIST_CARD, LEAD_CREATE_CARD, LEAD_LIST_CARD, MODULE_CREATE_CARD,
  NEW_MOVEMENT_CARD,
  NEW_ORDER_CARD, TRANSIT_HANDLING
} from "./dashboard.warehouse-widget";
import {Router} from "@angular/router";
import {RoleService} from "../../_core/lib/role.service";
import {NEW_REGISTER_CARD, REGISTER_CARD, WORK_ORDER_CARD} from "./dashboard.monitoring-widget";
import {
  UserWorkingHoursWizardService
} from "../../components/user-working-hours/user-working-hours-wizard/user-working-hours-wizard.service";
import {HandlingStateEnum} from "../../_core/enum/handling-state.enum";
import {CONTACT_CARD} from "./dashboard.administrator";
import {NotesService} from "../../_core/lib/notes.service";
import {CtWidgetNote} from "@ctsolution/ct-framework";
import {HandlingRouteData} from "../../_core/route-data/handling.route-data";
import {Handling} from "../../_core/classes/handling";
import {CtManagerService} from "../../_core/lib/ct-manager.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styles: [
    `::ng-deep .dashboard-container {
      h3 {
        font-size: 18px;
        font-weight: 500;
      }

      .ct-card-container .mat-card-content {
        padding: 10px 20px;
      }
    }`
  ]
})
export class DashboardComponent implements OnInit {

  cardList: CtCardConfiguration[] = [];
  counterList: CtCardConfiguration[] = [];
  masterCardList: CtCardConfiguration[] = [];
  masterCardList2: CtCardConfiguration[] = [];

  companySettings: UserCompanySettings | null = null;
  viewModel = {
    userHasMonitoringManagementEnabled: false,
    userHasWarehouseManagementEnabled: false,
    CRM: false
  }
  isAdministrator: boolean | null = null;

  noteConfiguration: CtNoteWidgetConfiguration = CtNoteWidgetConfiguration
    .create()
    .setServerSideConfiguration(
      CtNoteWidgetServerSideConfiguration
        .create()
        .setAssigner((value: CtWidgetNote) => this.notesService.notePut(value))
        .setGetter(() => this.notesService.getList())
        .setDeleter((value: CtWidgetNote) => this.notesService.noteDelete(value)));

  constructor(
    private jwt: JwtService,
    private router: Router,
    private roleService: RoleService,
    private notesService: NotesService,
    private modelService: CtModelService<any>,
    private userworkinghoursWizardService: UserWorkingHoursWizardService,
    private managerService: CtManagerService) {
  }

  ngOnInit() {

    this.setup();

  }

  get masterWidgetEnabled(): boolean {

    return this.userWorkingHoursWizardEnabled || this.notesWidgetEnabled;

  }

  get notesWidgetEnabled(): boolean {

    return (this.companySettings?.UserHasNotesEnabled ?? false) && (this.isAdministrator ?? false);

  }

  get userWorkingHoursWizardEnabled(): boolean {

    return (this.viewModel.userHasMonitoringManagementEnabled ?? false) && !(this.isAdministrator ?? false);

  }

  async setup() {

    this.companySettings = await this.jwt.getUserCompanySettings();
    this.isAdministrator = await this.roleService.is.administrator();

    this.viewModel.userHasWarehouseManagementEnabled = await this.managerService.hasEnabled.warehouse() ?? false;
    this.viewModel.userHasMonitoringManagementEnabled = await this.managerService.hasEnabled.monitoring() ?? false;
    this.viewModel.CRM = await this.managerService.hasEnabled.CRM() ?? false;

    if(this.viewModel.CRM){

      this.cardList.push(LEAD_LIST_CARD.setAction(() => this.router.navigate(["/", "lead"])));
      this.cardList.push(LEAD_CREATE_CARD.setAction(() => this.router.navigate(["/", "lead", "create"])));

    }

    //TODO: Momentaneamente disabilitate finche non viene completato lo sviluppo delle configurazioni


    if (this.viewModel.userHasWarehouseManagementEnabled) {

      const handlingManagementDisabled = this.companySettings?.HandlingManagementDisabled;

      if (!handlingManagementDisabled) {

        this.getMovementsInPreparation().then();
        this.getMovementsInTransit().then();

      }

      const masterCardList = [NEW_ORDER_CARD.setAction(() => this.router.navigate(["/", "handling", "order", "create"]))];

      if (!handlingManagementDisabled) {

        masterCardList.push(NEW_MOVEMENT_CARD.setAction(() => this.router.navigate(["/", "handling", "movement", "create"])))

      }

      this.masterCardList2 = [
        IMPORT_DATA_CARD.setAction(() => this.router.navigate(["/", "ct-model", "item-import"]))
      ];

      const cardList = [
        ITEM_LIST_CARD.setAction(() => this.router.navigate(["/", "catalog", "item"])),
        ITEM_CREATE_CARD.setAction(() => this.router.navigate(["/", "catalog", "item", "create"]))
      ];

      if (!handlingManagementDisabled) {

        cardList.push(MODULE_CREATE_CARD.setAction(() => this.router.navigate(["/", "catalog", "module", "create"])))

      }

      this.masterCardList.push(...masterCardList);
      this.cardList.push(...cardList);

    }

    if (this.viewModel.userHasMonitoringManagementEnabled && this.isAdministrator) {

      this.masterCardList
        .push(NEW_REGISTER_CARD.setAction(() => this.userworkinghoursWizardService.open()));

      this.cardList.push(
        REGISTER_CARD.setAction(() => this.router.navigate(["/", "user-working-hours"])),
        WORK_ORDER_CARD.setAction(() => this.router.navigate(["/", "work-order"])));

    }

    if (this.isAdministrator) {

      this.cardList
        .push(
          CONTACT_CARD
            .setAction(() => this.router.navigate(["/", "customer", "private"])))

    }


  }

  async getMovementsInTransit() {

    const result = await this.getMovementsByState(HandlingStateEnum.OnTheWay);
    this.setupCounter(TRANSIT_HANDLING, result);

  }

  async getMovementsInPreparation() {

    const result = await this.getMovementsByState(HandlingStateEnum.Draft);
    this.setupCounter(ENTRY_HANDLING_CARD, result);

  }

  private setupCounter(counter: CtCardConfiguration, result: CtModelConfiguration<any>) {

    counter
      .setDescription((result?.TotalElement ?? 0) > 0 ? result.TotalElement : "0"); // temporaneo, per mostrare 0 nel pannello

    this.counterList
      .push(counter);

  }

  private getMovementsByState(state: HandlingStateEnum) {

    const configuration: CtModelConfiguration<any> = CtModelConfiguration
      .create()
      .setRouteData(HandlingRouteData());

    const operators: CtModelDatatableOperators = CtModelDatatableOperators
      .create()
      .setFilters([
        CTModelDatatableFilter
          .create()
          .setField("HandlingState")
          .setValue(state)
          .setOperatorType(CtBinaryOperator.Equal)
      ]);

    return new Promise<CtModelConfiguration<Array<Handling>>>(resolve => {

      this.modelService
        .getList(configuration, operators)
        .subscribe(response => resolve(response.Result))

    })

  }

}
