import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchedulerComponent } from './scheduler.component';
import { RouterModule } from '@angular/router';
import { CtModelModule } from '@ctsolution/ct-framework';
import { SchedulerListComponent } from './scheduler-list/scheduler-list.component';
import { SchedulerListModule } from './scheduler-list/scheduler-list.module';

@NgModule({
  imports: [
    CommonModule,
    CtModelModule,
    SchedulerListModule,
    RouterModule.forChild([
      {
        path: "",
        children: [
          {
            path: "",
            redirectTo: "calendar",
            pathMatch: "full"
          },
          {
            path: "calendar",
            component: SchedulerComponent
          },
          {
            path: "list",
            component: SchedulerListComponent
          }
        ]
      }
    ])
  ],
  declarations: [SchedulerComponent],
  exports : [SchedulerComponent]
})
export class SchedulerModule { }
