<div class="module-create-form">

  <div>

    <p> {{ viewModel.warning | translate }} </p>

  </div>

  <form [formGroup]="form">

    <div [hidden]="!needsQuantity">

      <p> {{ 'ModuleCreateForm.QuantityLabel' | translate }} </p>

      <div
        class="p-15">

        <ct-control [configuration]="qtyControl"></ct-control>

      </div>

    </div>

    <div>

      <div
        class="p-15">

        <ct-control [configuration]="locationToControl"></ct-control>

      </div>

    </div>

  </form>

</div>
