import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GeneralDataComponent} from "./general-data.component";
import {MatLegacyCardModule} from "@angular/material/legacy-card";
import {CtAttachmentManagerService, CtButtonModule, CtFormModule} from "@ctsolution/ct-framework";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {
  WorkOrderImportFileConfigurationDialogModule
} from "../../work-order-import-file-configuration-dialog/work-order-import-file-configuration-dialog.module";
import {WorkOrderAttachmentsService} from './work-order-attachments.service';
import {WorkOrderFileController} from 'projects/ct-manager/src/app/_core/controllers/work-order-file.controller';
import {LeadRedirectButtonModule} from "./lead-redirect-button/lead-redirect-button.module";
import { CompanyService } from '../../../../company/company.service';
import { CompanyContactRelationService } from '../../../../company-contact/company-contact-relation/company-contact-relation.service';
import { SchedulerListModule } from '../../../../scheduler/scheduler-list/scheduler-list.module';

@NgModule({
  declarations: [GeneralDataComponent],
  imports: [
    CommonModule,
    MatLegacyCardModule,
    CtFormModule,
    CtButtonModule,
    FlexLayoutModule,
    MatIconModule,
    MatLegacyButtonModule,
    WorkOrderImportFileConfigurationDialogModule,
    LeadRedirectButtonModule
  ],
  exports: [GeneralDataComponent],
  providers: [
    WorkOrderFileController,
    WorkOrderAttachmentsService,
    CtAttachmentManagerService,
    CompanyService,
    CompanyContactRelationService
  ]
})
export class GeneralDataModule {
}
