import {ContactCompanyType} from "../../_core/enum/contact-company-type";

export class ContactCompany {

  Oid?: number | null;
  Companies: {
    Oid: number | null;
  } | null = null;
  Contacts: {
    Oid: number | null;
  } | null = null;
  Type: ContactCompanyType = ContactCompanyType.NotDefined;

  private constructor() {
  }

  public static create = () => new ContactCompany();

  setCompaniesOid(value: number | null) {

    this.Companies = {
      Oid: value
    };
    return this;

  }

  setContactsOid(value: number | null) {

    this.Contacts = {
      Oid: value
    };
    return this;

  }

  setType(value: ContactCompanyType) {

    this.Type = value;
    return this;

  }

}
