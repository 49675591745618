export class LeadDocumentsConfiguration {

  Lead: { Oid: number } | any | null = null;
  Document : any | null = null

  private constructor() {
  }

  public static create = () => new LeadDocumentsConfiguration();

  setLeadOid(value: number | null) {

    this.Lead = value ? {Oid: value} : null;
    return this;

  }

  setDocument(value: any | null) {

    this.Document = value;
    return this;

  }

}
