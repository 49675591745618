import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserWorkingHoursListComponent} from "./user-working-hours-list.component";
import {CtButtonModule, CtModelModule} from "@ctsolution/ct-framework";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {UserWorkingHoursExportModule} from "../user-working-hours-export/user-working-hours-export.module";

@NgModule({
  declarations: [
    UserWorkingHoursListComponent
  ],
  imports: [
    CommonModule,
    CtModelModule,
    CtButtonModule,
    MatIconModule,
    MatLegacyButtonModule,
    UserWorkingHoursExportModule
  ],
  exports: [
    UserWorkingHoursListComponent
  ]
})
export class UserWorkingHoursListModule {
}
