import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContactListComponent} from './contact-list.component';
import {CtModelModule} from "@ctsolution/ct-framework";

@NgModule({
  declarations: [
    ContactListComponent
  ],
  imports: [
    CommonModule,
    CtModelModule
  ],
  exports: [
    ContactListComponent
  ]
})
export class ContactListModule {
}
