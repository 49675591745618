import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {Injectable} from "@angular/core";
import {UserWorkingHoursWizardComponent} from "./user-working-hours-wizard.component";
import {UserWorkingHours} from "../../../_core/classes/user-working-hours";

@Injectable()
export class UserWorkingHoursWizardService {

  constructor(private dialog: MatDialog) {
  }

  open(event?: UserWorkingHours) {

    const dialogConfiguration: UserWorkingHoursWizardDialogData = UserWorkingHoursWizardDialogData
      .create()
      .setCloseButtonEnabled(true);

    if (event) {

      dialogConfiguration
        .setData(event)

    }

    return this.dialog
      .open(UserWorkingHoursWizardComponent, {
        data: dialogConfiguration,
        panelClass: 'user-working-hours-wizard-container',
        maxWidth: 500
      })

  }

}

export class UserWorkingHoursWizardDialogData {

  data: UserWorkingHours | null = null;
  closeButtonEnabled: boolean | null = null;

  constructor() {
  }

  public static create = (): UserWorkingHoursWizardDialogData => new UserWorkingHoursWizardDialogData();

  setData(value: UserWorkingHours | null) {

    this.data = value;
    return this;

  }

  setCloseButtonEnabled(value: boolean | null): UserWorkingHoursWizardDialogData {

    this.closeButtonEnabled = value;
    return this;

  }

}
