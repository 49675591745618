import {CtModelConfiguration, CtModelRouteData} from "@ctsolution/ct-framework";
import {UserWorkingHours} from "../../_core/classes/user-working-hours";

export const UserWorkingHoursRouteData = (): CtModelRouteData => CtModelRouteData
  .create()
  .setController("userworkinghours");

export const UserWorkingHoursConfiguration = (): CtModelConfiguration<UserWorkingHours> => CtModelConfiguration
  .create<UserWorkingHours>()
  .setRouteData(UserWorkingHoursRouteData());
