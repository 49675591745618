import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { MassiveChangesEditComponent } from './massive-changes-edit/massive-changes-edit.component';
import { MassiveChangesEditModule } from './massive-changes-edit/massive-changes-edit.module';
import { MassivePaymentsModule } from './massive-changes-edit/massive-payments/massive-payments.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MassiveChangesEditModule,
    MassivePaymentsModule,
    RouterModule.forChild([
      {
        path: "",
        children: [
          {
            path: "",
            redirectTo: "edit",
            pathMatch: "full"
          },
          {
            path: "edit",
            component: MassiveChangesEditComponent
          }
        ]
      }
    ])
  ]
})
export class MassiveChangesModule { }
