import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserFeeManagerComponent} from './user-fee-manager.component';
import {CtModelModule} from "@ctsolution/ct-framework";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  declarations: [
    UserFeeManagerComponent
  ],
  exports: [
    UserFeeManagerComponent
  ],
    imports: [
        CommonModule,
        CtModelModule,
        TranslateModule
    ]
})
export class UserFeeManagerModule {
}
