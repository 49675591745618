export class UserWorkingHoursExportParameter {

  Month: number = 0;
  Year: number = 0;

  private constructor() {
  }

  public static create = (): UserWorkingHoursExportParameter => new UserWorkingHoursExportParameter();

  setMonth(value: number) {

    this.Month = value;
    return this;

  }

  setYear(value: number) {

    this.Year = value;
    return this;

  }

}
