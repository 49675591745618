import {Component, Inject} from "@angular/core";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import {
  CtModelConfiguration, CtModelRouteData, CtModelType
} from "@ctsolution/ct-framework";
import {ItemHandling} from "../../../../../_core/classes/item-handling";
import {ChildModuleItemRouteData} from "../../../../../_core/route-data/child-module-item.route-data";

@Component({
  selector: "app-module-edit-item",
  template: `
    <div class="module-edit-item-panel" mat-dialog-content>
      <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>
    </div>`,
  styles: [`
    .module-edit-item-panel {
      padding: 0px;
      margin: 0px;
    }`]
})
export class ModuleEditItemComponent {

  routeData: CtModelRouteData = ChildModuleItemRouteData()
    .setModelType(CtModelType.FORM);

  configuration: CtModelConfiguration<ModuleEditItemComponent> | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ItemHandling,
    private dialogRef: MatDialogRef<ModuleEditItemComponent>) {

  }

  ngOnInit() {

    if (!this.data.Oid) {

      this.close();
      return;

    }

    this.routeData
      .setId(this.data.Oid);

    const fieldsCustomTemplates = [
      {prop: "Item", disabled: true},
      {prop: "UM", disabled: true},
      {prop: "Module", disabled: true}
    ];

    this.configuration = CtModelConfiguration
      .create<ModuleEditItemComponent>()
      .setRouteData(this.routeData)
      .setFieldsCustomTemplates(fieldsCustomTemplates);

  }

  close() {

    this.dialogRef
      .close();

  }

}
