import {Injectable} from "@angular/core";
import {ItemType} from "../../_core/enum/item-type";

@Injectable()
export class CatalogService {

  constructor() {
  }

  getCatalogTypeByRouteValue(typePathValue: string | null): ItemType | null {

    switch (typePathValue) {

      case "module":
        return ItemType.Module;
      case "item":
      default:
        return ItemType.Item;

    }

  }

}
