import {Component, Input} from '@angular/core';
import {WorkOrderHandlingsConfiguration} from "./work-order-handlings.configuration";
import {
  CtBinaryOperator,
  CtModelConfiguration,
  CTModelDatatableFilter,
  CtModelOperator,
  CtModelType
} from "@ctsolution/ct-framework";
import {HandlingRouteData} from "../../../../../_core/route-data/handling.route-data";
import {HandlingOriginEnum} from "../../../../../_core/enum/handling-origin.enum";
import {Router} from "@angular/router";

@Component({
  selector: 'ctm-work-order-handlings',
  template: `
    <ct-model *ngIf="modelConfiguration" [configuration]="modelConfiguration"></ct-model>`
})
export class WorkOrderHandlingsComponent {

  @Input() configuration: WorkOrderHandlingsConfiguration | null = null;
  modelConfiguration: CtModelConfiguration<any> | null = null;

  constructor(private router: Router) {
  }

  ngAfterViewInit() {

    this.setup();

  }

  setup() {

    const configuration = CtModelConfiguration
      .create<any>()
      .setRouteData(HandlingRouteData().setModelType(CtModelType.DATATABLE))
      .setOnDetail(handling => {

        this.router.navigate(["/", "handling", handling.HandlingOrigin === HandlingOriginEnum.Supplier ? 'order' : 'movement', "edit", handling.Oid]);

      })
      .setAutoSetupBreadcrumbs(false);

    configuration
      .initialized()
      .subscribe(() => configuration?.setOperations([CtModelOperator.Detail]));

    configuration
      .filterValues
      .addConstantFilter(
        CTModelDatatableFilter
          .create()
          .setField("WorkOrder.Oid")
          .setValue(this.configuration?.WorkOrder?.Oid)
          .setOperatorType(CtBinaryOperator.Equal))

    this.modelConfiguration = configuration;

  }

}
