<div class="item-selection-container">

  <h1 mat-dialog-title> {{ "offerSelect" | translate }} </h1>

  <div class="filter-cntr">
    <button
      mat-raised-button
      class="filter-toggle"
      style="margin-right: 0px"
      *ngIf="datatable?.configuration?.filtersEnabled"
      (click)="datatable.toggleFilters()">
      <mat-icon
        [color]="datatable.viewModel.filtersVisible ? 'primary' : 'accent'"> {{ datatable.viewModel.filtersVisible ? 'filter_alt_off' : 'filter_alt' }}
      </mat-icon>
    </button>
  </div>
  <div mat-dialog-content>

    <ct-datatable
      [configuration]="list"
      (reload)="fetchData($event)"
      (sort)="onSort($event)"
      (filter)="search($event)"></ct-datatable>

  </div>

  <div mat-dialog-actions>

    <ct-button [configuration]="cancelButton"></ct-button>

    <ct-button [configuration]="submitButton"></ct-button>

  </div>

</div>

