import {CTBase} from "@ctsolution/ct-base";
import {WorkOrderRowState} from "../enum/work-order-row-state";
import {WorkOrderRowOrigin} from "../enum/work-order-row-origin";

export class CTWorkOrderRow extends CTBase<CTWorkOrderRow> {

  Oid?: number;
  Code: string | null = null;
  WorkOrder: { Oid: number } | any | null = null;
  RowNumber?: number;
  Description: string | null = null;
  VAT: number | null = null;
  Quantity: number | null = null;
  Discount: number | null = null;
  CreateAt?: Date;
  UpdateAt?: Date;
  State?: WorkOrderRowState | null;
  Origin?: WorkOrderRowOrigin | null;

  constructor();
  constructor(model?: CTWorkOrderRow)
  constructor(model?: CTWorkOrderRow) {

    super();

    if (model) this.getClass(model);

  }

  public static create = (model?: CTWorkOrderRow) => new CTWorkOrderRow(model);

  setOid(value?: number) {

    this.Oid = value;
    return this;

  }

  setState(value?: WorkOrderRowState) {

    this.State = value;
    return this;

  }

  setOrigin(value?: WorkOrderRowOrigin) {

    this.Origin = value;
    return this;

  }

  setWorkOrderOid(value: number) {

    this.WorkOrder = {Oid: value};
    return this;

  }

  setCode(value: string | null) {

    this.Code = value;
    return this;

  }

}
