import {CTBase} from "@ctsolution/ct-base";
import {UserWorkingHours} from "./user-working-hours";
import {environment} from "../../../environments/environment";

export class CTManagerFile extends CTBase<CTManagerFile> {

  Oid: number | null = null;
  File: File | null = null;
  UserWorkingHours: UserWorkingHours | null = null;

  private constructor();
  private constructor(model?: CTManagerFile)
  private constructor(model?: CTManagerFile) {

    super();

    if (model) this.getClass(model);

  }

  public static create = (model?: any): CTManagerFile => new CTManagerFile(model);

  protected getPreviewUrl = (): string => `${environment.dev.REST_API_SERVER}/ct/file/get?id=${this.Oid}`

  setFile(value: File | null) {

    this.File = value;
    return this;

  }

  setUserWorkingHours(value: UserWorkingHours | null) {

    this.UserWorkingHours = value;
    return this;

  }

}
