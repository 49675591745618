import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {ContactEditComponent} from "./contact-edit/contact-edit.component";
import {ContactListComponent} from "./contact-list/contact-list.component";
import {ContactEditModule} from "./contact-edit/contact-edit.module";
import {ContactListModule} from "./contact-list/contact-list.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ContactEditModule,
    ContactListModule,
    RouterModule.forChild([
      {
        path: "",
        children: [
          {
            path: "",
            redirectTo: "list",
            pathMatch: "full"
          },
          {
            path: "create",
            component: ContactEditComponent
          },
          {
            path: "edit/:id",
            component: ContactEditComponent
          },
          {
            path: "list",
            component: ContactListComponent
          }
        ]
      }
    ])
  ]
})
export class ContactModule {
}
