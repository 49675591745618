import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {ItemImportComponent} from "./item-import.component";
import {CTManagerImportModule} from "../../layouts/ct-manager-import/ct-manager-import.module";

@NgModule({
  declarations: [
    ItemImportComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        children: [
          {
            path: "",
            component: ItemImportComponent,
            data: {
              title: "Importazione Anagrafica Articoli",
              urls: [{title: "Importazione Anagrafica Articoli"}]
            }
          }
        ]
      }
    ]),
    CTManagerImportModule
  ]
})
export class ItemImportModule {
}
