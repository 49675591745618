import {Component, TemplateRef, ViewChild} from '@angular/core';
import {
  CtDatatableActionCustomConfiguration,
  CtDatatableConfiguration,
  CtModelConfiguration,
  CtModelOperator,
  CtSortOrderDescriptorParameter
} from "@ctsolution/ct-framework";
import {DEFAULT_DATATABLE_CONFIGURATION} from "../../../_core/constants";
import {FormGeneratorDataRouteData} from "../../../_core/route-data/form-generator-data.route-data";
import {FileGet} from "../../../_core/classes/file-get";
import {FileService} from "../../../_core/lib/file.service";
import { CTMGeneralService } from '../../../_core/lib/general.service';

@Component({
  selector: 'app-form-generator-data-list',
  templateUrl: './form-generator-data-list.component.html'
})
export class FormGeneratorDataListComponent {

  @ViewChild("downloadAttachmentButton") downloadAttachmentButton: TemplateRef<any> | null = null;

  configuration: CtModelConfiguration<FormGeneratorDataListComponent> | null = null;
  datatableConfiguration: CtDatatableConfiguration = DEFAULT_DATATABLE_CONFIGURATION();

  constructor(private fileService: FileService,private general : CTMGeneralService) {
  }

  ngAfterViewInit() {

    this.datatableConfiguration
      .setCustomDatatableActionConfiguration(
        CtDatatableActionCustomConfiguration
          .create()
          .setTemplateOutlet(this.downloadAttachmentButton));

    const configuration = CtModelConfiguration
      .create<FormGeneratorDataListComponent>()
      .setRouteData(FormGeneratorDataRouteData())
      .setOrderValues(
        new Array<CtSortOrderDescriptorParameter>(
          CtSortOrderDescriptorParameter.create('CreateAt').setIsDescending(true)))
      .setCTDatatableConfiguration(this.datatableConfiguration)
      .setOnEdit(event => this.general.navigateOnCTModelRouter(["form-generator-data"], "edit", {
        value: event,
        configuration: this.configuration!
      }));

    configuration
      .initialized()
      .subscribe(() => configuration?.setOperations([CtModelOperator.Update]));



    this.configuration = configuration;



  }

  download(file: FileGet | null) {

    if (!file) return;

    this.fileService.downloadAttachmentByEndPoint(file);

  }

}
