import {ChangeDetectorRef, Component} from '@angular/core';
import {
  CtControlConfiguration,
  CtControlTypes,
  CtControlValidator, CtSelectControlOptions,
  CtSelectControlValue,
  CtThemeTypes
} from "@ctsolution/ct-framework";

export const months = [
  "months.january", "months.february", "months.march", "months.april",
  "months.may", "months.june", "months.july", "months.august",
  "months.september", "months.october", "months.november", "months.december"
];

@Component({
  selector: 'bs-month-control',
  template: `
    <ct-control [configuration]="control"></ct-control>`
})
export class MonthControlComponent {

  control: CtControlConfiguration = CtControlConfiguration
    .create()
    .setLabel('month')
    .setName('Month')
    .setTheme(CtThemeTypes.MATERIAL)
    .setType(CtControlTypes.ENUMERABLE)
    .setOptions(
      CtSelectControlOptions
        .create()
        .setNullValueEnabled(false)
    )
    .setValidators([
      CtControlValidator.create({name: "required"} as CtControlValidator)
    ]);

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {

    this.setup();

  }

  setup() {

    this.control
      .setValueOptions(this.getMonthValueOptions());

    this.cdr.detectChanges();

  }

  getMonthValueOptions() {

    const valueOptions: CtSelectControlValue[] = [];

    months
      .forEach((elm, idx) => {

        valueOptions
          .push(
            CtSelectControlValue
              .create()
              .setLabel(elm)
              .setValue(idx)
          )

      })

    return valueOptions;

  }

}
