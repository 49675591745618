import {CTBase} from "@ctsolution/ct-base";
import {UnitsOfMeasurements} from "./units-of-measurements";
import {CTManagerItemStock} from "./ct-manager-item-stock";

export class CTManagerOffer extends CTBase<CTManagerOffer> {
  Oid: number | null = null;
  Name: string | null = null;
  Code: string | null = null;
  Description: string | null = null;
  DefaultUnitOfMeasure?: UnitsOfMeasurements | null = null;

  constructor();
  constructor(model?: CTManagerOffer)
  constructor(model?: CTManagerOffer) {

    super();

    if (model) this.getClass(model);

  }

  public static create = (model?: CTManagerOffer): CTManagerOffer => new CTManagerOffer(model);

}
