import {Injectable} from "@angular/core";
import {DataRequest} from "@ctsolution/ct-webapi";
import {ItemFile} from "../classes/item-file";
import {CTManagerItem} from "../classes/ct-manager-item";
import {map} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {ItemFileRouteData} from "../route-data/item-file.route-data";
import {FileService} from "../lib/file.service";

const CONTROLLER_REQUEST = (): DataRequest => new DataRequest().setController([ItemFileRouteData().controller!]);

@Injectable()
export class ItemFileController {

  constructor(private fileService: FileService) {
  }

  create(file: File, item: CTManagerItem) {

    const parameter: ItemFile = new ItemFile(file, item);

    return this.fileService.create<ItemFile>(CONTROLLER_REQUEST(), parameter);

  }

  delete(file: ItemFile) {

    return this.fileService.delete<ItemFile>(CONTROLLER_REQUEST().controller!, file);

  }

  getItemFile(file: ItemFile, directDownload = false) {

    if (!file.Oid) return;

    const request: DataRequest = new DataRequest()
      .setController(['ct', 'File'])
      .setAction('Get')
      .setQueryParams({id: file.Oid, hideSpinner: true});

    return this.fileService
      .getFile(request)
      .pipe(
        map(result => {

          if (result) {

            if (!file.Name) file.Name = `attachment`;

            const fileName: string = file.Name.substring(0, file.Name.lastIndexOf('.')) || file.Name;

            if (directDownload) {

              this.fileService.blobDownload(result, fileName ?? `${environment.projectName}File${new Date().getTime()}`);

            }

          }

          return result;

        })
      );

  }

}
