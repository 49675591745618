import {Injectable} from "@angular/core";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {ItemSelectionImportComponent} from "./item-selection-import.component";

@Injectable()
export class ItemSelectionImportService {
  constructor(private dialog: MatDialog) {
  }

  open = () => this.dialog.open(ItemSelectionImportComponent, {
    panelClass: "item-selection-import-cntr",
    maxWidth: "500px"
  });

}
