import {TableColumn} from "@swimlane/ngx-datatable";
import {CtControlConfiguration, CtThemeTypes} from "@ctsolution/ct-framework";

export const OFFER_SELECTION_COLUMNS: (TableColumn | any)[] = [
  {
    prop: "Code",
    name: "Codice",
    width: 100,
    control: CtControlConfiguration
      .create()
      .setName('Code')
      .setLabel("Codice")
      .setTheme(CtThemeTypes.MATERIAL),
    ColumnBuilder: {
      FilterEnabled: true
    }
  },
  {
    prop: "Name",
    name: "Nome",
    width: 300,
    control: CtControlConfiguration
      .create()
      .setName('Name')
      .setLabel("Nome")
      .setTheme(CtThemeTypes.MATERIAL),
    ColumnBuilder: {
      FilterEnabled: true
    }
  },
  {
    prop: "Description",
    name: "Descrizione",
    width: 300,
    control: CtControlConfiguration
      .create()
      .setName('Description')
      .setLabel("Descrizione")
      .setTheme(CtThemeTypes.MATERIAL),
    ColumnBuilder: {
      FilterEnabled: true
    }
  }
];
