export class PaymentsEditConfiguration {

    Oid?: number;
    UserOid? : number;
    WorkOrderOid? : number;
    constructor() {}

    public static create = (): PaymentsEditConfiguration => new PaymentsEditConfiguration();

    public setOid (oid: number): PaymentsEditConfiguration
    {
        this.Oid = oid;
        return this;
    }
    public setUserOid (oid: number): PaymentsEditConfiguration
    {
        this.UserOid = oid;
        return this;
    }

    public setWorkOrderOid (oid: number): PaymentsEditConfiguration
    {
        this.WorkOrderOid = oid;
        return this;
    }
}

