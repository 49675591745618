import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WarehouseHandlingsComponent } from "./warehouse-handlings.component";
import { CtModelModule } from "@ctsolution/ct-framework";

@NgModule({
  declarations: [
    WarehouseHandlingsComponent
  ],
  imports: [
    CommonModule,
    CtModelModule
  ],
  exports: [
    WarehouseHandlingsComponent
  ]
})
export class WarehouseHandlingsModule {
}
