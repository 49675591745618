import { AfterViewInit, Component, Input } from '@angular/core';
import { CTModelDatatableFilter, CtBinaryOperator, CtModelConfiguration, CtModelOnFlyCreateService, CtModelRouteData, CtModelType } from '@ctsolution/ct-framework';
import { FeeTimeRepetitionAmountType } from 'projects/ct-manager/src/app/_core/enum/fee-time-repetition-amount-type';
import { OfferFeeRouteData } from 'projects/ct-manager/src/app/_core/route-data/offer-fee.route-data';

@Component({
  selector: 'offer-fee',
  templateUrl: './offer-fee.component.html',
})
export class OfferFeeComponent implements AfterViewInit {

  @Input() configuration: any | null = null;
  modelConfiguration: CtModelConfiguration<OfferFeeComponent> | null = null;

  constructor(private onFlyCreateHelper: CtModelOnFlyCreateService) { }

  ngAfterViewInit() {

    this.setup();

  }

  async setup() {

    const routeData: CtModelRouteData = OfferFeeRouteData()
      .setModelType(CtModelType.DATATABLE);

    const configuration = CtModelConfiguration
      .create<any>()
      .setRouteData(routeData)
      .setOnCreate(() => this.openOfferFeeForm())
      .setOnEdit((offerFee) => this.openOfferFeeForm(offerFee.Oid))

    configuration
      .filterValues
      .addConstantFilter(
        CTModelDatatableFilter
          .create()
          .setField('Offer.Oid')
          .setValue(this.configuration?.Offer?.Oid)
          .setOperatorType(CtBinaryOperator.Equal)
      )

    this.modelConfiguration = configuration;

  }

  openOfferFeeForm(Oid: number | null = null) {

    const routeData: CtModelRouteData = OfferFeeRouteData()
      .setModelType(CtModelType.FORM);

    const configuration: CtModelConfiguration<any> | null = CtModelConfiguration.create()

    if (Oid) {

      routeData
        .setId(Oid);

    }

    if (this.configuration?.Offer) {

      configuration
        .filterValues
        .addConstantFilter(
          CTModelDatatableFilter
            .create()
            .setField('Offer')
            .setValue(this.configuration.Offer)
            .setOperatorType(CtBinaryOperator.Equal)
        );

    }

    configuration
      .setRouteData(routeData);

    configuration
      .initialized()
      .subscribe(() => {

        setTimeout(() => {

          const form = configuration?.form;
          const amountTypeControl = form?.get('AmountType');
          const quantityControl = form?.get('Quantity');

          quantityControl?.disable();

          amountTypeControl
            ?.valueChanges
            .subscribe(value => {

              if (value === FeeTimeRepetitionAmountType.FixedForQuantity) {

                quantityControl?.enable()

              } else {

                quantityControl?.setValue(null);
                quantityControl?.disable();

              }

            })

        })

      })

      this.onFlyCreateHelper
        .openOnFlyCreate(configuration)
        .afterClosed()
        .subscribe((reload: any) => {

          if (reload) window.location.reload(); // dovrei usare la navigazione sul router, però non sta funzionando correttamente ... per ora facciamo refresh forzato

        });


    }
}
