import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {HandlingListComponent} from "./handling-list.component";
import {CtFrameworkModule, CtModelModule} from "@ctsolution/ct-framework";
import {LangDownloadFileModule} from "../../lang-download-file/lang-download-file.module";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  declarations: [
    HandlingListComponent
  ],
  imports: [
    CommonModule,
    CtModelModule,
    LangDownloadFileModule,
    MatIconModule,
    CtFrameworkModule
  ]
})
export class HandlingListModule {
}
