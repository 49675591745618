import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserListComponent} from "./user-list.component";
import {CtModelModule} from "@ctsolution/ct-framework";

@NgModule({
  declarations: [
    UserListComponent
  ],
  imports: [
    CommonModule,
    CtModelModule
  ],
  exports: [UserListComponent]
})
export class UserListModule {
}
