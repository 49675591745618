import {Injectable} from '@angular/core';
import {MatLegacyDialog} from "@angular/material/legacy-dialog";
import {WorkOrderImportFileConfigurationDialogComponent} from "./work-order-import-file-configuration-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class WorkOrderImportFileConfigurationDialogService {

  constructor(private dialog: MatLegacyDialog) {
  }

  open() {

    return this.dialog.open(WorkOrderImportFileConfigurationDialogComponent)

  }

}
