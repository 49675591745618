import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CtButtonConfiguration, CtControlTypes, CtFormConfiguration, CtSelectControlValue, CtTextareaControlOptions, CtThemeTypes, MAT_RAISED_ACCENT, MAT_RAISED_WARN } from '@ctsolution/ct-framework';
import { CTMGeneralService } from 'projects/ct-manager/src/app/_core/lib/general.service';
import { WorkOrderService } from '../../../../../work-order.service';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MovementManagerAddOfferConfiguration } from './movement-manager-add-offer.configuration';
import { CTWorkOrderRowUnionType } from 'projects/ct-manager/src/app/_core/classes/ct-work-order.row-union';
import { CtWorkOrderRowOffer } from 'projects/ct-manager/src/app/_core/classes/ct-work-order.row-offer';
import { CtWorkOrderRowItem } from 'projects/ct-manager/src/app/_core/classes/ct-work-order.row-item';
import { CtWorkOrderRowContact } from 'projects/ct-manager/src/app/_core/classes/ct-work-order.row-contact';
import { CTWorkOrderRow } from 'projects/ct-manager/src/app/_core/classes/ct-work-order.row';
import { WorkOrderRowState } from 'projects/ct-manager/src/app/_core/enum/work-order-row-state';
import { WorkOrderRowOrigin } from 'projects/ct-manager/src/app/_core/enum/work-order-row-origin';

@Component({
  selector: 'app-movement-manager-add-offer',
  templateUrl: './movement-manager-add-offer.component.html',
  styleUrls: ['./movement-manager-add-offer.component.scss']
})
export class MovementManagerAddOfferComponent{

  formConfiguration: CtFormConfiguration | null = null;

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("close")
    .setAction(() => this.onCancel())
    .setMatherialOptions(MAT_RAISED_WARN);

  addButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setClass('ct-model-submit-button')
    .setAction(() => this.onAdd())
    .setMatherialOptions(MAT_RAISED_ACCENT);

  private controls = [
    this.generalService.getTextControl({name: 'Code', required: true, flexSpacePercentage: 100}),
    this.generalService.getTextControl({name: 'Description', required: true})
      .setType(CtControlTypes.TEXTAREA)
      .setFlexSpacePercentage(100)
      .configureOptions<CtTextareaControlOptions>(options => {

        if (!options) options = CtTextareaControlOptions.create();

        options
          .setRows(5)

      }),
    this.generalService.getTextControl({
      name: 'Quantity', required: true,
      flexSpacePercentage: 25
    })
      .setValue(1)
      .setType(CtControlTypes.NUMBER),
    this.generalService.getTextControl({
      name: 'Amount',
      required: true,
      flexSpacePercentage: 25
    })
      .setType(CtControlTypes.NUMBER)
      .setLabel('Importo (€)'),
    this.generalService.getTextControl({
      name: 'Discount',
      required: false,
      flexSpacePercentage: 25
    }).setType(CtControlTypes.NUMBER)
      .setValue(0)
      .setLabel('Sconto (%)'),
    this.generalService.getTextControl({
      name: 'VAT',
      required: false,
      flexSpacePercentage: 25
    }).setType(CtControlTypes.ENUMERABLE)
      .setLabel('IVA (%)')
      .setValueOptions([
        CtSelectControlValue.create().setLabel("4").setValue(4),
        CtSelectControlValue.create().setLabel("10").setValue(10),
        CtSelectControlValue.create().setLabel("22").setValue(22),
      ])
  ];

  constructor(
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private generalService: CTMGeneralService,
    private workOrderService: WorkOrderService,
    private dialogRef: MatLegacyDialogRef<MovementManagerAddOfferComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) private config: MovementManagerAddOfferConfiguration) {

    this.formConfiguration = CtFormConfiguration
      .create(this.formBuilder.group({}))
      .setTheme(CtThemeTypes.MATERIAL)
      .setControls(this.controls);

    if (this.config.Values) {

      this.setup(this.config.Values)

    }

  }

  ngAfterViewInit() {

    this.cdr.detectChanges();

  }

  setup(value: CTWorkOrderRowUnionType | null) {

    if (!value) return;

    this.formConfiguration
      ?.form
      ?.patchValue(value);

    const offer = (<CtWorkOrderRowOffer>value).Offer;

    if (offer) {

      if ('Description' in offer) {

        const description = this.formConfiguration?.form?.get('Description');
        description?.setValue(offer?.Description);
        description?.disable();

      }

      if ('Code' in offer) {

        const code = this.formConfiguration?.form?.get('Code');
        code?.setValue(offer?.Code)
        code?.disable();

      }
    }

  }

  onAdd() {

    if (!this.formConfiguration?.form?.valid) return;

    const value = this.formConfiguration?.form?.getRawValue();
    const values = this.config.Values;

    let row: CTWorkOrderRowUnionType;

    if ((<CtWorkOrderRowItem>values)?.Item) {

      row = CtWorkOrderRowItem.create({Item: (<CtWorkOrderRowItem>values)?.Item, ...value});

    } else if ((<CtWorkOrderRowOffer>values)?.Offer) {

      row = CtWorkOrderRowOffer.create({Offer: (<CtWorkOrderRowOffer>values)?.Offer, ...value});

    } else if ((<CtWorkOrderRowContact>values)?.Contact) {

      row = CtWorkOrderRowContact.create({Contact: (<CtWorkOrderRowContact>values)?.Contact, ...value});

    } else {

      row = CTWorkOrderRow.create(value);

    }

    row
      .setWorkOrderOid(this.config.WorkOrder?.Oid ?? -1)

    if (values?.Oid) {

      row.setOid(values.Oid);

    }

    row
      .setState(values?.State ?? WorkOrderRowState.Open)
      .setOrigin(values?.Origin ?? WorkOrderRowOrigin.Standard);

    this.workOrderService
      .putRow(row)
      ?.then((response) => {

        if (response.Result && !isNaN(response?.Result)) {

          row
            .setOid(response.Result)

        }

        this.dialogRef
          .close(row)

      })

  }

  onCancel() {

    this.dialogRef
      .close();

  }
}
