export class UserFeeManagerConfiguration {

  User: {
    Oid: number | null
  } | null = null;

  private constructor() {
  }

  public static create = () => new UserFeeManagerConfiguration();

  setUserOid(value: number | null) {

    this.User = {
      Oid: value
    }

    return this;

  }

}
