import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserEditComponent} from "./user-edit.component";
import {CtModelModule} from "@ctsolution/ct-framework";
import {UserFeeManagerModule} from "../user-fee-manager/user-fee-manager.module";
import { UserInfoWorkDataModule } from '../user-info-work-data/user-info-work-data.module';


@NgModule({
  declarations: [UserEditComponent],
  imports: [
    CommonModule,
    CtModelModule,
    UserFeeManagerModule,
    UserInfoWorkDataModule
  ],
  exports: [UserEditComponent]
})
export class UserEditModule {
}
