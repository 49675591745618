export class CTManagerLocation{

  IsEnabled: boolean | null = null;
  LoadPermitted: boolean | null = null;
  UnloadPermitted: boolean | null = null;
  Name: string | null = null;
  Oid: number | null = null;
  WorkOrder: any | null = null;
  Address: string | null = null;

}
