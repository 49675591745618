
<div>

    <h4 style="margin-left: 20px;">{{"CT_MENU.stores" | translate}}</h4>
    <ct-model *ngIf="locationConfiguration" [configuration]="locationConfiguration"></ct-model>

</div>

<div>

    <h4 style="margin-left: 20px;">{{"CT_MENU.box" | translate}}</h4>
    <ct-model *ngIf="boxConfiguration" [configuration]="boxConfiguration"></ct-model>

</div>