import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { SelectionType } from '@swimlane/ngx-datatable';
import { CTManagerItem } from 'projects/ct-manager/src/app/_core/classes/ct-manager-item';
import { CTManagerItemStock } from 'projects/ct-manager/src/app/_core/classes/ct-manager-item-stock';
import { CTManagerOffer } from 'projects/ct-manager/src/app/_core/classes/ct-manager-offer';
import { HandlingOriginEnum } from 'projects/ct-manager/src/app/_core/enum/handling-origin.enum';
import { ItemSelectionConfiguration } from 'projects/ct-manager/src/app/components/handling/order-handling-wizard/order-handling-wizard-step2/item-selection/item-selection/item-selection.configuration';
import { SelectedItemsConfiguration } from 'projects/ct-manager/src/app/components/handling/order-handling-wizard/order-handling-wizard-step2/selected-items/selected-items.configuration';
import { OfferSelectionConfiguration } from 'projects/ct-manager/src/app/components/offer/offer-selection/offer-selection/offer-selection.configuration';

@Component({
  selector: 'app-movement-manager-search-offer',
  templateUrl: './movement-manager-search-offer.component.html',
})
export class MovementManagerSearchOfferComponent {

  offerSelectionConfiguration: OfferSelectionConfiguration = OfferSelectionConfiguration
    .create()
    .setImportEnabled(false)
    .setOrigin(HandlingOriginEnum.Supplier)
    .setSelectionType(SelectionType.single);

  constructor(private dialogRef: MatLegacyDialogRef<MovementManagerSearchOfferComponent>) {
  }

  onSelect(event: CTManagerOffer[] = []) {

    if (event.length) {

      const offer = event[0];

      const value = CTManagerOffer.create(offer);

      if (value.DefaultUnitOfMeasure) {

        delete value.DefaultUnitOfMeasure;

      }

      this.dialogRef
        .close(value)

    }

  }

  close() {

    this.dialogRef
      .close();

  }
}
