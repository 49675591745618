import {Injectable} from "@angular/core";
import {DataRequest} from "@ctsolution/ct-webapi";
import {map} from "rxjs/operators";
import {environment} from "../../../environments/environment";
import {FileService} from "../lib/file.service";
import { WorkOrderFileRouteData } from "../route-data/work-order-file.route-data";
import { CTWorkOrder } from "../classes/ct-work-order";
import { WorkOrderFile } from "../classes/work-orde-file";

const CONTROLLER_REQUEST = (): DataRequest => new DataRequest().setController([WorkOrderFileRouteData().controller!]);

@Injectable()
export class WorkOrderFileController {

  constructor(private fileService: FileService) {
  }

  create(file: File, workOrder: CTWorkOrder<any>) {

    const parameter: WorkOrderFile = new WorkOrderFile(file, workOrder);

    return this.fileService.create<WorkOrderFile>(CONTROLLER_REQUEST(), parameter);

  }

  delete(file: WorkOrderFile) {

    return this.fileService.delete<WorkOrderFile>(CONTROLLER_REQUEST().controller!, file);

  }

  getWorkOrderFile(file: WorkOrderFile, directDownload = false) {

    if (!file.Oid) return;

    const request: DataRequest = new DataRequest()
      .setController(['ct', 'File'])
      .setAction('Get')
      .setQueryParams({id: file.Oid, hideSpinner: true});

    return this.fileService
      .getFile(request)
      .pipe(
        map(result => {

          if (result) {

            if (!file.Name) file.Name = `attachment`;

            const fileName: string = file.Name.substring(0, file.Name.lastIndexOf('.')) || file.Name;

            if (directDownload) {

              this.fileService.blobDownload(result, fileName ?? `${environment.projectName}File${new Date().getTime()}`);

            }

          }

          return result;

        })
      );

  }

}
