import {TableColumn} from "@swimlane/ngx-datatable";
import {CtControlConfiguration, CtThemeTypes} from "@ctsolution/ct-framework";

export const ITEM_STOCK_SELECTION_COLUMNS: (TableColumn | any)[] = [
  {
    prop: "ItemCode",
    name: "Codice",
    width: 100,
    control: CtControlConfiguration
      .create()
      .setName('Code')
      .setLabel('Codice')
      .setTheme(CtThemeTypes.MATERIAL),
    ColumnBuilder: {
      FilterEnabled: true
    }
  },
  {
    prop: "ItemName",
    name: "Nome",
    width: 300,
    control: CtControlConfiguration
      .create()
      .setName('ItemName')
      .setLabel("Nome")
      .setTheme(CtThemeTypes.MATERIAL),
    ColumnBuilder: {
      FilterEnabled: true
    }
  },
  {
    prop: "ItemDescription",
    name: "Descrizione",
    width: 300,
    control: CtControlConfiguration
      .create()
      .setName('ItemDescription')
      .setLabel("Descrizione")
      .setTheme(CtThemeTypes.MATERIAL),
    ColumnBuilder: {
      FilterEnabled: true
    }
  },
  {
    prop: "Stock",
    name: "Quantità"
  },
  {
    prop: "UMSymbol",
    name: "UM"
  },
  {
    prop: "ItemAdditionalCode",
    name: "RAL",
    control: CtControlConfiguration
      .create()
      .setName('ItemAdditionalCode')
      .setLabel("RAL")
      .setTheme(CtThemeTypes.MATERIAL),
    ColumnBuilder: {
      FilterEnabled: true
    }
  }
];
