import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ModuleHandlingsComponent } from "./module-handlings.component";
import { CtModelModule } from "@ctsolution/ct-framework";

@NgModule({
  declarations: [
    ModuleHandlingsComponent
  ],
  imports: [
    CommonModule,
    CtModelModule
  ],
  exports: [
    ModuleHandlingsComponent
  ]
})
export class ModuleHandlingsModule {
}
