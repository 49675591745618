import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LeadRedirectButtonComponent} from './lead-redirect-button.component';
import {CtButtonModule} from '@ctsolution/ct-framework';
import {MatLegacyCardModule} from "@angular/material/legacy-card";

@NgModule({
  imports: [
    CommonModule,
    CtButtonModule,
    MatLegacyCardModule
  ],
  declarations: [LeadRedirectButtonComponent],
  exports: [LeadRedirectButtonComponent]
})
export class LeadRedirectButtonModule {
}
