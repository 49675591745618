import { AfterViewInit, Component } from '@angular/core';
import { CtModelConfiguration } from '@ctsolution/ct-framework';
import { CTMGeneralService } from '../../../_core/lib/general.service';
import { DEFAULT_DATATABLE_CONFIGURATION } from '../../../_core/constants';
import { OfferRouteData } from '../../../_core/route-data/offer.route-data';

@Component({
  selector: 'app-offer-list',
  templateUrl: './offer-list.component.html'
})
export class OfferListComponent implements AfterViewInit {

  configuration: CtModelConfiguration<OfferListComponent> | null = null;

  constructor(private general: CTMGeneralService) {}

  ngAfterViewInit() {

    this.setup();

  }

  async setup() {

    this.configuration = CtModelConfiguration
      .create<OfferListComponent>()
      .setRouteData(OfferRouteData())
      .setCTDatatableConfiguration(DEFAULT_DATATABLE_CONFIGURATION())
      .setOnCreate(() => this.general.navigateOnCTModelRouter(["offer"], "create" ))
      .setOnEdit(event => this.general.navigateOnCTModelRouter(["offer"], "edit", {
        value: event,
        configuration: this.configuration,
      }))

  }

}
