import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { CtControlConfiguration, CtThemeTypes, CtButtonConfiguration, MAT_RAISED_PRIMARY, MAT_RAISED_ACCENT } from "@ctsolution/ct-framework";
import { OfferSelectionService } from "../offer-selection.service";
import { OfferSelectionConfiguration } from "../offer-selection/offer-selection.configuration";
import { OfferSearchControl } from "./search.form-configuration";
import { CTManagerOffer } from "projects/ct-manager/src/app/_core/classes/ct-manager-offer";

@Component({
  selector: "app-offer-search",
  templateUrl: "./offer-search.component.html"
})
export class OfferSearchComponent {

  form: FormGroup;

  @Input() configuration: OfferSelectionConfiguration | null = null;

  @Output() onFocus: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSelected: EventEmitter<CTManagerOffer[]> = new EventEmitter<CTManagerOffer[]>();

  offerSearchControl: CtControlConfiguration = CtControlConfiguration
    .create(OfferSearchControl)
    .setTheme(CtThemeTypes.MATERIAL);

  searchButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_SEARCH.search")
    .setAction(() => this.onSearch())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  constructor(private formBuilder: FormBuilder, private offerSelectionService: OfferSelectionService) {

    this.form = this.formBuilder.group({});

    this.offerSearchControl
      .setFormParent(this.form)

  }

  onSearch() {

    this.form.markAllAsTouched();

    if (this.form.valid) {

      this.configuration
        ?.setSearchText(this.form.value.offerSearch);

      this.offerSelectionService
        .open(this.configuration)
        .afterClosed()
        .subscribe((event: CTManagerOffer[] | null) => {

          if (event) {

            this.onSelected.emit(event);

          }

        });

    }

  }

}
