import {Injectable} from '@angular/core';
import {
  CTDatatablePaginationParameter,
  CtModelConfiguration,
  CtModelDatatableOperators,
  CtModelService,
  CtWidgetNote
} from "@ctsolution/ct-framework";
import {NotesRouteData} from "../route-data/notes.route-data";
import {CtWebapiGenericResponse, CtWebapiService, DataRequest} from "@ctsolution/ct-webapi";

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  configuration: () => CtModelConfiguration<CtWidgetNote> = () => CtModelConfiguration
    .create<CtWidgetNote>()
    .setRouteData(NotesRouteData());

  constructor(private _model: CtModelService<CtWidgetNote>, private _webapi: CtWebapiService) {
  }

  noteDelete(note: CtWidgetNote) {

    if (!note.Oid) return null;

    const configuration = this.configuration();

    const controller: string | undefined | null = configuration.RouteData?.controller;

    if (!controller) return null;

    const request: DataRequest = new DataRequest()
      .setController([controller, 'Delete'])
      .setAction(note.Oid!.toString())
      .setQueryParams({hideSpinner: true})

    return this._webapi
      .delete(request);

  }

  notePut(note: CtWidgetNote) {

    const configuration = this.configuration();

    (<any>note).body = {hideSpinner: true};

    if (note.Oid) {

      configuration
        .RouteData
        ?.setId(note.Oid);

    }

    return this._model
      .putInfos(configuration, note) ?? null;

  }

  getList() {

    return new Promise<CtWidgetNote[] | null>((resolve) => {

      const filter = CtModelDatatableOperators
        .create()
        .setPagination(new CTDatatablePaginationParameter())

      this._model
        .getList(this.configuration(), filter)
        .subscribe((result: CtWebapiGenericResponse<CtModelConfiguration<CtWidgetNote[]>>) => resolve(<CtWidgetNote[] | null>result.Result.DataSource));

    })

  }

}
