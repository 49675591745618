import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeadListComponent } from './lead-list.component';
import { CtModelModule } from '@ctsolution/ct-framework';
import { StateLeadBulletModule } from './state-lead-bullet/state-lead-bullet.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatLegacyMenuModule } from '@angular/material/legacy-menu';

@NgModule({
  imports: [
    CommonModule,
    CtModelModule,
    StateLeadBulletModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatLegacyMenuModule
  ],
  declarations: [LeadListComponent],
  exports: [LeadListComponent]
})
export class LeadListModule { }
