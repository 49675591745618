import { Injectable } from "@angular/core";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { CtGenericDialogComponent, CtGenericDialogConfiguration } from "@ctsolution/ct-framework";

@Injectable()
  export class MassiveChangesService {
  
    constructor(private dialog: MatLegacyDialog) {}
  
    open = (param: CtGenericDialogConfiguration) => this.dialog.open(CtGenericDialogComponent, {
        data : {configuration : param},
        maxWidth: '500px', 
        minWidth: '300px' , 
        disableClose : false
      });
    
    }