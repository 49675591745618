import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkOrderSummaryComponent } from './work-order-summary.component';
import {MatLegacyButtonModule} from "@angular/material/legacy-button";
import {MatIconModule} from "@angular/material/icon";
import {CtButtonModule, CtFrameworkModule} from "@ctsolution/ct-framework";
import {FlexLayoutModule} from "@angular/flex-layout";
import {TableModule} from "primeng/table";
import {MatLegacyCardModule} from "@angular/material/legacy-card";
import {MatLegacyRadioModule} from "@angular/material/legacy-radio";
import {ButtonModule} from "primeng/button";
import {RippleModule} from "primeng/ripple";
import {DropdownModule} from "primeng/dropdown";
import {TagModule} from "primeng/tag";

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    MatLegacyCardModule,
    MatLegacyRadioModule,
    FlexLayoutModule,
    CtButtonModule,
    CtFrameworkModule,
    MatIconModule,
    MatLegacyButtonModule,
    ButtonModule,
    RippleModule,
    DropdownModule,
    TagModule

  ],
  declarations: [WorkOrderSummaryComponent],
  exports : [WorkOrderSummaryComponent]
})
export class WorkOrderSummaryModule { }
