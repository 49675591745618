import { Injectable } from "@angular/core";
import { EventTypeEnum, SchedulerEventPath } from "./scheduler-event-button/scheduler-event-button.component";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { CtBinaryOperator, CtControlConfiguration, CtControlService, CtControlTypes, CtModelConfiguration, CTModelDatatableFilter, CtModelDatatableOperators, CtSchedulerEvent, CtSchedulerEventFormComponent, CtSchedulerEventFormConfiguration, CtSelectControlOptions, CtSelectControlValue, CtSortOrderDescriptorParameter } from "@ctsolution/ct-framework";
import { ActivatedRoute, Router } from "@angular/router";
import { CTMGeneralService } from "../../_core/lib/general.service";
import { DataRequest, MethodEnum } from "@ctsolution/ct-webapi";
import { JwtService } from "../../_core/lib/jwt.service";

@Injectable({
  providedIn: 'root'
})
export class SchedulerService {

  agentControl = this.controlService
    .getDefaultConfiguration({
      name: 'Agent',
      type: CtControlTypes.LOOKUP,
      remoteValueOptionsEndpoint: DataRequest
        .create()
        .setController(['User'])
        .setAction('Lookup')
        .setMethod(MethodEnum.POST)
        .setQueryParams({ hideSpinner: true })
    })
    .setLabel('UserSelected')
    .configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        .setNullValueEnabled(true)
        .setLookupFilter((value) => {

          const filters: CTModelDatatableFilter[] = [];

          if (value) {

            filters
              .push(
                CTModelDatatableFilter
                  .create()
                  .setField("NameSurname")
                  .setValue(value)
                  .setOperatorType(CtBinaryOperator.Contains)
              );
          }

          const operators = CtModelDatatableOperators
            .create()
            .setFilters(filters);

          operators
            .Pagination
            ?.setSortOrders(new Array<CtSortOrderDescriptorParameter>(CtSortOrderDescriptorParameter.create('Name')))

          return operators;

        })

    });

    companyControl = this.controlService
    .getDefaultConfiguration({
      name: 'CompanyPlace',
      type: CtControlTypes.LOOKUP,
      remoteValueOptionsEndpoint: DataRequest
        .create()
        .setController(['CompanyPlace'])
        .setAction('Lookup')
        .setMethod(MethodEnum.POST)
        .setQueryParams({ hideSpinner: true })
    })
    .setLabel('CompanyPlace')
    .configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        .setNullValueEnabled(true)
        .setLookupFilter((value) => {

          const filters: CTModelDatatableFilter[] = []

            filters
              .push(
                CTModelDatatableFilter
                  .create()
                       .setField('Company.IsSelf')
                        .setValue(true)
                        .setOperatorType(CtBinaryOperator.Equal)
              );

          const operators = CtModelDatatableOperators
            .create()
            .setFilters(filters);

          operators
            .Pagination
            ?.setSortOrders(new Array<CtSortOrderDescriptorParameter>(CtSortOrderDescriptorParameter.create('Name')))

          return operators;

        })

    });

  constructor(
    private dialog: MatLegacyDialog,
    private router: Router,
    private route: ActivatedRoute,
    private general: CTMGeneralService,
    private controlService: CtControlService,
    private jwtService : JwtService
  ) {
  }

  async createEventFromPath(modelConfiguration: CtModelConfiguration<any>, param?: SchedulerEventPath | null, value?: CtSchedulerEvent | null, event?: any) {

    let eventValue: CtSchedulerEvent | null = null;

    if(!value)
      await this.setDefaultUser().then(() => {return});

    const configuration = CtSchedulerEventFormConfiguration
      .create()
      .setModelConfiguration(modelConfiguration)
      .setAdditionalFormInfos([this.companyControl,this.agentControl])
      .setSetupAdditionalForm(this.setupAddiotionForm)


      // TODO: Implement agentControl after ctangularworkspace update

    if (value) {

      eventValue = value;

      if (typeof eventValue.Start === 'string') {
        eventValue.Start = new Date(eventValue.Start);
      }

      if (typeof eventValue.End === 'string') {
        eventValue.End = new Date(eventValue.End);
      }

      configuration
        .setEventValue(eventValue)

      if (value.Origin && value.ExternalOid) {
        const subtitle = await this.getSubtitle(+value.Origin, value.ExternalOid);
        if (subtitle) (<any>eventValue).SubTitle = subtitle; // dam: commento per jack, ho aggiunto l'any come cast perché altrimenti non compilava, forse qualche problema di publish del pacchetto


        configuration.setNavigateSubmit(() => {
          this.router.navigate([this.getPathToReturn(+value.Origin), 'edit', value.ExternalOid])
          this.dialog.closeAll();
        })

      }
    }

    if (param?.Oid && param?.Type) {
      eventValue = <CtSchedulerEvent>{ Start: event.date }
      eventValue.Origin = +param?.Type;
      eventValue.ExternalOid = param?.Oid;

      const subtitle = await this.getSubtitle(+param?.Type, +param?.Oid);
      if (subtitle) (<any>eventValue).SubTitle = subtitle;

      configuration.setEventValue(eventValue);
    }

    if (eventValue == null && event) {
      configuration.setEventValue(<CtSchedulerEvent>{ Start: event.date });

    }


    return this.dialog
      .open(CtSchedulerEventFormComponent, { disableClose: true, maxWidth: 500, data: configuration })
      .afterClosed().toPromise();

  }

  getPathToReturn(origin: number, extraUrl: string | null = null) {

    switch (origin) {
      case EventTypeEnum.LEAD:
        return 'lead';
      case EventTypeEnum.WORKORDER:
        return 'work-order';
      case EventTypeEnum.CUSTOMER:
        if (extraUrl) return `customer/${extraUrl}`
        return 'customer';
      default:
        return '';
    }

  }

  setupAddiotionForm(controls : Array<CtControlConfiguration>, value : any){

    if(value.CompanyPlace){

      controls.find(control => control.name === "CompanyPlace")?.setValue(CtSelectControlValue.create().setLabel(value.CompanyPlace.Name ?? "Dato sconosciuto").setValue(value.CompanyPlace.Oid));

    }

    if(value.Agent){

      controls.find(control => control.name === "Agent")?.setValue(CtSelectControlValue.create().setLabel(value.Agent.NameSurname ?? "Dato sconosciuto").setValue(value.Agent.Oid));

    }else if(controls.find(control => control.name === "Agent")?.value)
    {

      controls.find(control => control.name === "Agent")?.control?.setValue(controls.find(control => control.name === "Agent")?.value);

    }

  }

  navigateToPath(path: SchedulerEventPath) {

    const queryParams = {
      TypeExtra: path.Type,
      OidExtra: path.Oid
    };

    // Naviga verso il nuovo path mantenendo eventuali altri queryParams esistenti
    this.router.navigate(['scheduler'], { queryParamsHandling: 'merge', queryParams }
    );


  }


  returnParam(id: number | null = null) {

    const pId = this.route.snapshot.paramMap.get("id");
    let param: SchedulerEventPath | null = null;
    const currentUrl = this.router.url;
    let type: EventTypeEnum | null = null;

    if (currentUrl.includes('lead')) {
      type = EventTypeEnum.LEAD;
    } else if (currentUrl.includes('work-order')) {
      type = EventTypeEnum.WORKORDER;
    } else if (currentUrl.includes('customer')) {
      type = EventTypeEnum.CUSTOMER;
    }


    if (id)
      param = SchedulerEventPath.create(id, type);
    else
      if (pId)
        param = SchedulerEventPath.create(+pId, type);

    return param

  }


  getSubtitle(origin: EventTypeEnum, oid: number) {

    switch (origin) {

      case EventTypeEnum.LEAD:
        return this.getLeadSubtitle(oid);

      case EventTypeEnum.WORKORDER:
        return this.getWorkOrderSubtitle(oid);

      default:
        return "";


    }


  }

  private getLeadSubtitle(oid: number): Promise<string> {

    return new Promise<any | null>(resolve => {
      this.general.getInfos(
        {
          controller: 'Lead',
          oid: oid
        }
      ).subscribe(result => {

        if (result.Result?.DataSource) {
          const res = `${result.Result.DataSource.Name ?? ""} ${result.Result.DataSource.Surname ?? ""} ${result.Result.DataSource.Email ?? ""} ${result.Result.DataSource.Phone?? ""} `;
          resolve(res)

        }

      })

    })

  }


  private getWorkOrderSubtitle(oid: number) {

    return new Promise<any | null>(resolve => {
      this.general.getInfos(
        {
          controller: 'WorkOrder',
          oid: oid
        }
      ).subscribe(result => {

        if (result.Result?.DataSource) {

          const res = `${result.Result.DataSource.Name ?? ""} ${result.Result.DataSource.Code ?? ""}`;
          resolve(res)

        }

      })

    })

  }

  async setDefaultUser(){

    const oid = await this.jwtService.getUserInfoOid();

    if(oid)
    {

      return new Promise<void>(resolve => {
        this.general.getInfos(
          {
            controller: 'User',
            oid: +oid
          }
        ).subscribe(result => {

          if (result.Result?.DataSource) {

            this.agentControl.setValue(CtSelectControlValue.create().setLabel(result.Result.DataSource.NameSurname ?? "Dato sconosciuto").setValue(+oid));
            resolve()

          }
        })
      })
    }
  }

}
