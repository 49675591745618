<div class="module-create-container">

  <h1 mat-dialog-title> {{ viewModel.title | translate }} </h1>

  <div mat-dialog-content>

    <app-module-create-form
      #moduleCreateForm
      [parameter]="parameter"
      [hidden]="!!moduleCreationRequestResult"></app-module-create-form>

    <app-module-create-handlings
      #moduleCreateHandlings
      [hidden]="!moduleCreationRequestResult"
      [model]="moduleCreationRequestResult"></app-module-create-handlings>

  </div>

  <div mat-dialog-actions>

    <ct-button [configuration]="cancelButton"></ct-button>

    <ng-container
      *ngIf="!!moduleCreationRequestResult && (moduleCreationRequestResult?.Handlings ?? []).length; else generateHandlingsPreview">

      <ct-button
        [configuration]="finalizeButton"></ct-button>

    </ng-container>

    <ng-template #generateHandlingsPreview>

      <ct-button [configuration]="previewButton"></ct-button>

    </ng-template>

  </div>

</div>
