import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContactEditComponent} from './contact-edit.component';
import {CtModelModule} from "@ctsolution/ct-framework";
import {CompanyContactModule} from "../../company-contact/company-contact.module";

@NgModule({
  declarations: [
    ContactEditComponent
  ],
  imports: [
    CommonModule,
    CtModelModule,
    CompanyContactModule
  ],
  exports: [
    ContactEditComponent
  ]
})
export class ContactEditModule {
}
