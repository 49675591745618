import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { WarehouseQrComponent } from "./warehouse-qr.component";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { FlexLayoutModule } from "@angular/flex-layout";
import {CtFrameworkModule} from "@ctsolution/ct-framework";

@NgModule({
  declarations: [
    WarehouseQrComponent
  ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        FlexLayoutModule,
        CtFrameworkModule
    ]
})
export class WarehouseQrModule {
}
