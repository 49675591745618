import {Component, Inject, OnInit, Optional, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {
  UserWorkingHoursNotesControlComponent
} from "../../user-working-hours-wizard-form/user-working-hours-notes-control/user-working-hours-notes-control.component";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from "@angular/material/legacy-dialog";
import {UserWorkingHours} from "../../../../../_core/classes/user-working-hours";
import {
  CtButtonConfiguration,
  CtControlValidator,
  CtModelService,
  MAT_RAISED_PRIMARY,
  MAT_RAISED_WARN
} from "@ctsolution/ct-framework";
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {UserWorkingHoursConfiguration} from "../../../../../_core/route-data/user-working-hours.route-data";

@Component({
  selector: 'app-user-working-hours-notes-dialog',
  templateUrl: './user-working-hours-notes-dialog.component.html',
  styleUrls: ['./user-working-hours-notes-dialog.component.scss']
})
export class UserWorkingHoursNotesDialogComponent {

  @ViewChild(UserWorkingHoursNotesControlComponent) userWorkingHoursNotesControlComponent: UserWorkingHoursNotesControlComponent | null = null;

  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UserWorkingHoursNotesDialogComponent>,
    private _model: CtModelService<UserWorkingHours>,
    @Inject(MAT_DIALOG_DATA) public data: UserWorkingHours) {

    this.form = this.fb.group({});

  }

  ngAfterViewInit() {

    setTimeout(() => this.setup()) // attende apertura modale

  }

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.cancel")
    .setAction(() => this.dialogRef.close())
    .setMatherialOptions(MAT_RAISED_WARN)

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setClass('ct-model-submit-button')
    .setAction(() => this.update())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  setup() {

    this.userWorkingHoursNotesControlComponent
      ?.control
      ?.setName('Note')
      ?.setValidators([CtControlValidator.create({name: 'required'} as CtControlValidator)])
      ?.setFormParent(this.form)
      ?.setValue(this.data.Note);

  }

  update() {

    this.form.markAllAsTouched();

    if (this.form.valid) {

      const value = this.form.value;

      this.data
        .setNotes(value.Note);

      const configuration = UserWorkingHoursConfiguration();
      configuration
        .RouteData
        ?.setId(this.data.Oid)

      this._model
        .putInfos(configuration, this.data)
        ?.subscribe(
          () => this.dialogRef.close(true),
          (error) => console.error('An error occurred during execution:', error)
        );

    }

  }

}
