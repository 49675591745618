<div
  class="module-add-item-cntr">

  <div
    mat-dialog-content>

    <app-item-search
      [configuration]="itemSelectionConfiguration"
      (onSelected)="itemSelectionService.updateSelection($event, selectedItemsConfiguration)"
      (onImport)="onImport($event)"></app-item-search>

    <mat-divider></mat-divider>

    <app-selected-items
      *ngIf="selectedItemsConfiguration.itemHandlings.length"
      [configuration]="selectedItemsConfiguration"></app-selected-items>

    <div
      mat-dialog-actions
      fxLayout="row wrap"
      fxLayoutAlign="space-between center">

      <div>

        <ct-button [configuration]="cancelButton"></ct-button>

      </div>

      <div>

        <ct-button
          *ngIf="selectedItemsConfiguration.itemHandlings.length"
          [configuration]="submitButton"></ct-button>

      </div>

    </div>

  </div>

</div>
