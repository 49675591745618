import { CTWorkOrder } from "./ct-work-order";

export class WorkOrderFile {

  Oid: number | null = null;
  Name: string | null = null;
  CreateAt: Date | null = null;

  constructor(
    public File: File | null = null,
    public WorkOrder: CTWorkOrder<any> | null = null
  ) {

    if (File) {

      this.Name = File.name;

    }

  }

}
