export class FormModuleDynamicParameter {

  Oid?: number | null;
  FormTemplate: { Oid: number; } | null = null;
  JSONContent: string | null = null;
  Status : number | null = null;

  public constructor() {
  }

  public static create = () => new FormModuleDynamicParameter();

  setOid(value: number) {

    this.Oid = value;
    return this;

  }

  setJSONContent(value: string) {

    this.JSONContent = value;
    return this;

  }

  setFormGeneratorOid(value: number) {

    this.FormTemplate = {
      Oid: value
    };
    return this;

  }

  setStatus(value: number | null) {

    this.Status = value;
    return this;

  }

}
