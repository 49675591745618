import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompanyListComponent} from './company-list.component';
import {CtModelModule} from "@ctsolution/ct-framework";

@NgModule({
  declarations: [
    CompanyListComponent
  ],
  imports: [
    CommonModule,
    CtModelModule
  ],
  exports: [
    CompanyListComponent
  ]
})
export class CompanyListModule {
}
