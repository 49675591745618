export class WorkOrderFileCreateParameter {

  File: File | null = null;
  Note: string | null = null;
  WorkOrder: { Oid: number } | any | null = null;
  Type: { Oid: number } | any | null = null;
  Oid : number | null = null;

  public static create = () => new WorkOrderFileCreateParameter();

  setFile(value: File | null): WorkOrderFileCreateParameter {

    this.File = value;
    return this;

  }

  setNote(value: string | null): WorkOrderFileCreateParameter {

    this.Note = value;
    return this;

  }

  setWorkOrderOid(value: number | null): WorkOrderFileCreateParameter {

    this.WorkOrder = {Oid: value};
    return this;

  }

  setType(value: number | null): WorkOrderFileCreateParameter {

    this.Type = {Oid: value};
    return this;

  }

  setOid(value: number | null): WorkOrderFileCreateParameter {

    this.Oid = value;
    return this;

  }
}
