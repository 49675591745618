export class ReportDownloadParameter {

  templateLanguage: string | null = null;
  locationOid: number | null = null;
  handlingOid: number | null = null;
  packagingOid : number | null = null;
  workOrderOid : number | null = null;
  leadOid: number | null = null;
  action : string | null = null;
  labelDownload : string | null = null;
  directDownload = true;
  openInBrowser: boolean = false;

  private constructor() {
  }

  public static create = () => new ReportDownloadParameter();

  setTemplateLanguage(value: string | null): ReportDownloadParameter {

    this.templateLanguage = value;
    return this;

  }

  setLocationOid(value: number | null): ReportDownloadParameter {

    this.locationOid = value;
    return this;

  }

  setHandlingOid(value: number | null): ReportDownloadParameter {

    this.handlingOid = value;
    return this;

  }

  setDirectDownload(value: boolean): ReportDownloadParameter {

    this.directDownload = value;
    return this;

  }

  setOpenInBrowser(value: boolean): ReportDownloadParameter {

    this.openInBrowser = value;
    return this;

  }

  setPackagingOid(value: number | null): ReportDownloadParameter {

    this.packagingOid = value;
    return this;

  }

  setAction(value: string | null): ReportDownloadParameter {

    this.action = value;
    return this;

  }

  setLabelDownload(value: string | null): ReportDownloadParameter {

    this.labelDownload = value;
    return this;

  }

  setWorkOrderOid(value: number | null): ReportDownloadParameter {

    this.workOrderOid = value;
    return this;

  }

  setLeadOid(value: number | null): ReportDownloadParameter {

    this.leadOid = value;
    return this;

  }

}
