<div>

  <ng-container *ngIf="formConfigurations.length > 0">

    <h5> {{ (formConfigurations.length > 1 ? 'Questionari' : 'Questionario') | translate }} </h5>

    <ng-container *ngFor="let configuration of formConfigurations">

      <ct-dynamic-form-generator #formGenerator [configuration]="configuration"></ct-dynamic-form-generator>

    </ng-container>

  </ng-container>

</div>
