import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {CTDynamicFormGeneratorModule} from "@ctsolution/ct-framework";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule
      .forChild([
        {
          path: '',
          children: [
            {
              path: 'dynamic-form',
              loadChildren: () => CTDynamicFormGeneratorModule
            }
          ]
        }
      ])
  ]
})
export class ApiModule {
}
