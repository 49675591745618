import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CTManagerLocation} from "../../../../../_core/classes/ct-manager-location";
import {environment} from "../../../../../../environments/environment";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {TemporaryAreaChartDetailComponent} from "./temporary-area-chart-detail/temporary-area-chart-detail.component";

@Component({
  selector: 'app-temporary-area-list-box',
  templateUrl: './temporary-area-list-box.component.html',
  styleUrls: ['./temporary-area-list-box.component.scss']
})
export class TemporaryAreaListBoxComponent {

  @Input() item: CTManagerLocation | null = null;

  @Output() onEdit: EventEmitter<CTManagerLocation | null> = new EventEmitter<CTManagerLocation | null>();
  @Output() onDetail: EventEmitter<CTManagerLocation | null> = new EventEmitter<CTManagerLocation | null>();
  @Output() onDelete: EventEmitter<CTManagerLocation | null> = new EventEmitter<CTManagerLocation | null>();

  results: any[] = [];

  scheme = {
    domain: ['#133060', '#3CAC5E', '#C7B42C', '#AAAAAA']
  };

  constructor(private dialog: MatDialog) {
  }

  ngOnInit() {

    this.chartSetup()

  }

  chartSetup() {

    const numeri = [Math.floor(Math.random() * 100), Math.floor(Math.random() * 100), Math.floor(Math.random() * 100)];

// Calcola la somma dei tre numeri
    const somma = numeri.reduce((acc, curr) => acc + curr, 0);

// Dividi la somma per 3 per ottenere il 100% per ogni numero
    let percentuali = numeri.map(num => (num / somma) * 100);

    // Calcola la somma delle percentuali
    let totale = percentuali.reduce((acc, curr) => acc + curr, 0);

    // Aggiungi un offset al totale per renderlo maggiore di 85
    const offset = Math.floor(Math.random() * 15);


    totale += offset;
    percentuali = percentuali.map(perc => (perc / totale) * 100);

    this.results = [{
      name: "Fresco",
      value: percentuali[0]
    },
      {
        name: "Gelo",
        value: percentuali[1]
      },
      {
        name: "Secco",
        value: percentuali[2]
      }]

  }

  chartDetail() {

    this.dialog
      .open(TemporaryAreaChartDetailComponent, {data: this.item})

  }

  detail() {

    this.onDetail.emit(this.item)

  }

  edit() {

    this.onEdit.emit(this.item)

  }

  delete() {

    this.onDelete.emit(this.item)

  }

  protected readonly environment = environment;
}
