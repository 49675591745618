import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CtButtonModule, CtControlModule, CtDatatableModule, CtFrameworkModule} from "@ctsolution/ct-framework";
import {OfferSearchComponent} from "./offer-search/offer-search.component";
import {OfferSelectionComponent} from "./offer-selection/offer-selection.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {MatLegacyDialogModule as MatDialogModule} from "@angular/material/legacy-dialog";
import {FlexLayoutModule} from "@angular/flex-layout";
import { MatIconModule } from "@angular/material/icon";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import { OfferSelectionService } from "./offer-selection.service";

@NgModule({
  declarations: [OfferSelectionComponent, OfferSearchComponent],
  imports: [
    CommonModule,
    CtDatatableModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    CtControlModule,
    MatCardModule,
    CtButtonModule,
    MatDialogModule,
    CtFrameworkModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [OfferSearchComponent],
  providers: [OfferSelectionService]
})
export class OfferSelectionModule {
}
