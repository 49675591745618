import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MovementManagerAddOfferComponent } from './movement-manager-add-offer/movement-manager-add-offer.component';
import {MatLegacyDialogModule} from "@angular/material/legacy-dialog";
import {CtButtonModule, CtFormModule} from "@ctsolution/ct-framework";
import {TranslateModule} from "@ngx-translate/core";
import {FlexModule} from "@angular/flex-layout";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import { MovementManagerSearchOfferComponent } from './movement-manager-search-offer/movement-manager-search-offer.component';
import { MovementManagerAddOfferService } from './movement-manager-add-offer.service';
import { OfferSelectionModule } from 'projects/ct-manager/src/app/components/offer/offer-selection/offer-selection.module';



@NgModule({
  declarations: [MovementManagerAddOfferComponent,MovementManagerSearchOfferComponent],
  imports: [
    CommonModule,
    CtFormModule,
    MatLegacyDialogModule,
    CtButtonModule,
    TranslateModule,
    OfferSelectionModule,
    FlexModule,
    MatButtonModule,
    MatIconModule
  ],
  providers : [MovementManagerAddOfferService]
})
export class MovementManagerAddOfferModule { }
