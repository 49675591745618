import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {CommonModule} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FlexLayoutModule} from "@angular/flex-layout";

import {AuthenticationRoutes} from "./authentication.routing";
import {LoginComponent} from "./login/login.component";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {MatLegacyCheckboxModule as MatCheckboxModule} from "@angular/material/legacy-checkbox";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {CtFrameworkModule, CtTogglePasswordVisibilityButtonModule} from "@ctsolution/ct-framework";
import {ForgotComponent} from "./forgot/forgot.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(AuthenticationRoutes),
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    CtTogglePasswordVisibilityButtonModule,
    CtFrameworkModule
  ],
  declarations: [
    LoginComponent,
    ForgotComponent
  ]
})
export class AuthenticationModule {
}
