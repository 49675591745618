import {Component, EventEmitter, Input, Output} from '@angular/core';
import {
  CtButtonConfiguration,
  CtModelConfiguration, DeleteConfirmService,
  MAT_RAISED_PRIMARY,
  MAT_RAISED_WARN
} from "@ctsolution/ct-framework";
import { CompanyService } from '../../company.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-company-place',
  template: `
    <div class="company-place-container" *ngIf="configuration">

      <mat-card>

        <mat-card-content>

          <mat-card-title>

            <h5 style="margin: 0px"> {{ 'CustomerLocation' | translate }} {{ idx ?? -1 }}</h5>

          </mat-card-title>

          <div class="ct-model-container">

            <ct-model [configuration]="configuration"></ct-model>

          </div>

          <mat-card-actions *ngIf="configuration?.form">

            <ct-button [configuration]="cancelButton"></ct-button>

            <ct-button [configuration]="submitButton"></ct-button>

          </mat-card-actions>

        </mat-card-content>

      </mat-card>

    </div>
  `,
  styles: [`
    ::ng-deep .company-place-container {

      .mat-card-actions {

        display: flex;
        justify-content: space-between;

      }

      .ct-model-container {

        .mat-card {

          margin: 0px;
          box-shadow: none !important;

          .mat-card-content {

            padding: 0px !important;

          }

        }

      }

    }
  `]
})
export class CompanyPlaceComponent {

  @Input() configuration: CtModelConfiguration<any> | null = null;
  @Input() idx: number | null = null;
  leadOid: string | null = null;

  constructor(private deleteConfirm: DeleteConfirmService,private companyService: CompanyService,private route : ActivatedRoute) {

    this.leadOid = this.route.snapshot.queryParamMap.get("LeadOid");

  }

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setClass('ct-model-submit-button')
    .setAction(() => this.submit())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("delete")
    .setAction(() => {

      this.deleteConfirm
        .open()
        .afterClosed()
        .subscribe((result?: boolean) => {

          if (result) {

            this.configuration?.onDelete!({index: (this.idx ?? 1) - 1});
            return;

          }

        })


    })
    .setMatherialOptions(MAT_RAISED_WARN)


    submit(){

      this.configuration
        ?.form
        ?.markAllAsTouched();

      if (this.configuration
        ?.form
        ?.valid) {

        this.configuration
          ?.onCreate!(this.configuration.form.value);


          
          if(this.leadOid){

            this.companyService.navigateToLead(this.leadOid);
            return;

          }



      }
      

    }
}
