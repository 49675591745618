import {CtSerializerHelperService, CtWebapiService, DataRequest, MethodEnum} from "@ctsolution/ct-webapi";
import {Injectable} from "@angular/core";

const CONTROLLER: string[] = ["file"];
const CONTROLLER_REQUEST = (): DataRequest => new DataRequest()
  .setController(CONTROLLER);

@Injectable()
export class FileController {

  constructor(
    private _webapi: CtWebapiService,
    private serializerHelperService: CtSerializerHelperService) {
  }

  itemImport(File: File) {

    const request: DataRequest = CONTROLLER_REQUEST()
      .setAction("ItemImport")
      .setBody(this.serializerHelperService.objectToFormData({File}))
      .setMethod(MethodEnum.POST);

    return this._webapi
      .request(request);

  }

  handlingItemQuantityImport(File: File) {

    const request: DataRequest = CONTROLLER_REQUEST()
      .setAction("HandlingItemQuantityImport")
      .setBody(this.serializerHelperService.objectToFormData({File}))
      .setMethod(MethodEnum.POST);

    return this._webapi
      .request(request);

  }

}
