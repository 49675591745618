import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { OfferEditComponent } from './offer-edit/offer-edit.component';
import { OfferListComponent } from './offer-list/offer-list.component';
import { OfferListModule } from './offer-list/offer-list.module';
import { OfferEditModule } from './offer-edit/offer-edit.module';

@NgModule({
  imports: [
    CommonModule,
    OfferListModule,
    OfferEditModule,
    RouterModule.forChild([
      {
        path: "",
        children: [
          {
            path: "",
            redirectTo: "list",
            pathMatch: "full"
          },
          {
            path: "create",
            component: OfferEditComponent
          },
          {
            path: "edit/:id",
            component: OfferEditComponent
          },
          {
            path: "list",
            component: OfferListComponent
          }
        ]
      }
    ])
  ],
  declarations: []
})
export class OfferModule { }
