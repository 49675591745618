import {Injectable} from "@angular/core";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {ModuleCreateComponent} from "./module-create.component";
import {ModuleCreationRequestParameter} from "../../../../_core/classes/module-creation-request-parameter";
import {TransferItemsRequestParameter} from "../../../../_core/classes/transfer-items-request-parameter";

@Injectable()
export class ModuleCreateService {

  constructor(private dialog: MatDialog) {
  }

  open(parameter: ModuleCreationRequestParameter | TransferItemsRequestParameter) {

    return this.dialog
      .open(ModuleCreateComponent, {
        data: parameter,
        maxWidth: 650,
        panelClass: "module-create-container"
      });

  }

}
