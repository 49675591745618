import {Injectable} from "@angular/core";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {ModuleEditItemComponent} from "./module-edit-item.component";
import {ItemHandling} from "../../../../../_core/classes/item-handling";

@Injectable()
export class ModuleEditItemService {
  constructor(private dialog: MatDialog) {
  }

  open = (data: ItemHandling) => this.dialog
    .open(ModuleEditItemComponent, {
      data,
      panelClass: "module-edit-item-container"
    });

}
