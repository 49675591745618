import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserWorkingHoursListModule} from "./user-working-hours-list/user-working-hours-list.module";
import {RouterModule} from "@angular/router";
import {UserWorkingHoursListComponent} from "./user-working-hours-list/user-working-hours-list.component";
import {UserWorkingHoursWizardModule} from "./user-working-hours-wizard/user-working-hours-wizard.module";

@NgModule({
  declarations: [],
  imports: [
    UserWorkingHoursListModule,
    UserWorkingHoursWizardModule,
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        children: [
          {
            path: "",
            redirectTo: "list",
            pathMatch: "full"
          },
          {
            path: "list",
            component: UserWorkingHoursListComponent
          }
        ]
      }

    ])
  ]
})
export class UserWorkingHoursModule {
}
