import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccessDeniedDialogComponent} from "./access-denied-dialog.component";
import {AccessDeniedDialogService} from "./access-denied-dialog.service";
import {CtButtonModule} from "@ctsolution/ct-framework";
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
  declarations: [
    AccessDeniedDialogComponent
  ],
  imports: [
    CommonModule,
    CtButtonModule,
    TranslateModule
  ],
  exports: [
    AccessDeniedDialogComponent
  ],
  providers: [
    AccessDeniedDialogService
  ]
})
export class AccessDeniedDialogModule {
}
