export class WorkOrderFileImportParameter {

  File: File | null = null;
  WorkOrderOid: number | null = null;
  ImportConfigurationCode : string | null = null;
  OverwriteSameCodeItems : boolean = false;

  private constructor() {
  }

  public static create = (): WorkOrderFileImportParameter => new WorkOrderFileImportParameter();

  setFile(value: File | null): WorkOrderFileImportParameter {

    this.File = value;
    return this;

  }

  setWorkOrderOid(value: number | null): WorkOrderFileImportParameter {

    this.WorkOrderOid = value;
    return this;

  }

  setCode(value: string | null): WorkOrderFileImportParameter {

    this.ImportConfigurationCode  = value;
    return this;

  }

  setOverwriteSameCodeItems (value: boolean): WorkOrderFileImportParameter {

    this.OverwriteSameCodeItems = value;
    return this;

  }

}
