import {Injectable} from '@angular/core';
import {MatLegacyDialog} from "@angular/material/legacy-dialog";
import {ComponentType} from "@angular/cdk/overlay";
import {CTManagerItem} from "../../../../../../_core/classes/ct-manager-item";
import {CTWorkOrderRowUnionType} from "../../../../../../_core/classes/ct-work-order.row-union";
import {MovementManagerConfiguration} from "../movement-manager.configuration";
import { CtWorkOrderRowOffer } from 'projects/ct-manager/src/app/_core/classes/ct-work-order.row-offer';
import { MovementManagerAddOfferConfiguration } from './movement-manager-add-offer/movement-manager-add-offer.configuration';
import { MovementManagerAddOfferComponent } from './movement-manager-add-offer/movement-manager-add-offer.component';
import { MovementManagerSearchOfferComponent } from './movement-manager-search-offer/movement-manager-search-offer.component';
import { CTManagerOffer } from 'projects/ct-manager/src/app/_core/classes/ct-manager-offer';

@Injectable()
export class MovementManagerAddOfferService {

  constructor(private dialog: MatLegacyDialog) {
  }

  openDialogAndAddOffer<TResult>(component: ComponentType<unknown>, config = {}): Promise<TResult | null | undefined> {
    const dialogRef = this.dialog.open(component, config);

    return new Promise<TResult | null | undefined>((resolve, reject) => {
      dialogRef.afterClosed().subscribe(result => resolve(result), error => reject(error));
    });
  }

  async searchOffer(configuration: MovementManagerConfiguration | null) {

    const offer = await this.openDialogAndAddOffer<CTManagerOffer>(MovementManagerSearchOfferComponent, {minWidth: 600});

    if (!offer) return;

    return this.manageRow(configuration, <CtWorkOrderRowOffer>{Offer : offer})

  }

  private openDialogAndModifyOffer<T>(configuration: MovementManagerAddOfferConfiguration) {
    return this.openDialogAndAddOffer<T>(MovementManagerAddOfferComponent, {
      minWidth: 500,
      data: configuration,
      disableClose: true
    });
  }

  manageRow(managerConfig: MovementManagerConfiguration | null, value: CTWorkOrderRowUnionType | null = null) {

    const configuration: MovementManagerAddOfferConfiguration = MovementManagerAddOfferConfiguration
      .create()
      .setValues(value)
      .setWorkOrderOid(managerConfig?.WorkOrder?.Oid ?? -1);

    return this.openDialogAndModifyOffer<CTWorkOrderRowUnionType>(configuration);

  }
}
