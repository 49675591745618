<h1 mat-dialog-title> {{ "exportWorkingHours" | translate}}</h1>
<form mat-dialog-content [formGroup]="form" style="min-height: 190px">

  <bs-month-control></bs-month-control>

  <bs-year-control></bs-year-control>

</form>
<div mat-dialog-actions style="display: flex; justify-content: space-between">

  <ct-button [configuration]="cancelButton"></ct-button>

  <ct-button [configuration]="submitButton"></ct-button>

</div>
