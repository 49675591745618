import {Component, Inject, OnInit} from "@angular/core";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from "@angular/material/legacy-dialog";
import {DomSanitizer} from "@angular/platform-browser";
import {ReportController} from "../../../../_core/controllers/report.controller";
import {FileService} from "../../../../_core/lib/file.service";

@Component({
  selector: "app-warehouse-qr",
  templateUrl: "./warehouse-qr.component.html",
  styleUrls: ["./warehouse-qr.component.scss"]
})
export class WarehouseQrComponent implements OnInit {

  private originalBlob: Blob | null = null;
  QRCode: any | null = null;
  locationName: string | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { locationOid: number, locationName?: string },
    private reportController: ReportController,
    private sanitizer: DomSanitizer,
    private fileService: FileService
  ) {

    if (this.data.locationName) {

      this.locationName = this.data.locationName;

    }

  }

  ngOnInit() {

    this.reportController
      .getLocationQR(this.data.locationOid)
      .subscribe(
        result => {

          this.originalBlob = result;

          const objectURL = URL.createObjectURL(this.originalBlob);
          this.QRCode = this.sanitizer.bypassSecurityTrustUrl(objectURL);

        });

  }

  download() {

    if (!this.originalBlob) return;

    this.fileService
      .blobDownload(this.originalBlob);

  }

}
