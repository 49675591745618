<mat-card class="p-20">
  
    <mat-card-content>

      <h3 style="padding-left: 10px;">Aggiungi le ore lavorative per i giorni della settimana.</h3>
  
      <ct-form [configuration]="formConfiguration"></ct-form>
  
      <mat-card-actions fxLayout="row wrap" fxLayoutAlign="space-between">
  
        <ct-button [configuration]="cancelButton"></ct-button>
  
        <ct-button [configuration]="submitButton"></ct-button>
  
      </mat-card-actions>
  
    </mat-card-content>
  
  </mat-card>
  