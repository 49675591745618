import {Component, Input, OnInit} from '@angular/core';
import {StateLeadBulletConfiguration} from './state-lead-bullet.configuration';

@Component({
  selector: 'app-state-lead-bullet',
  templateUrl: './state-lead-bullet.component.html',
  styleUrls: ['./state-lead-bullet.component.scss']
})
export class StateLeadBulletComponent implements OnInit {

  @Input() configuration: StateLeadBulletConfiguration | null = null;

  constructor() {
  }

  ngOnInit() {
  }

}
