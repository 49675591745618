import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CtButtonConfiguration, CTGeneralService, CtControlConfiguration, CtControlService, CtControlTypes, CtFormConfiguration, CtModelConfiguration, CtModelRouteData, CtModelType, CtSelectControlOptions, CtSelectControlValue, CtThemeTypes, MAT_RAISED_WARN, MAT_RAISED_ACCENT, MAT_RAISED_PRIMARY } from '@ctsolution/ct-framework';
import { UserInfoWorkDataRouteData } from 'projects/ct-manager/src/app/_core/route-data/user-work-hour.route-data';
import { UserInfoWorkDataEditConfiguration } from './user-info-work-data-edit.configuration';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import { CTMGeneralService } from 'projects/ct-manager/src/app/_core/lib/general.service';
import { FormBuilder } from '@angular/forms';
import { FormGeneratorDataStatusVO } from '../../../form-generator-data/form-generator-data-edit/form-generator-data-edit.component';
import { NoteDialogComponent } from 'projects/ct-manager/src/app/_core/services/note-services/note-dialog/note-dialog.component';

@Component({
  selector: 'app-user-info-work-data-edit',
  templateUrl: './user-info-work-data-edit.component.html',
  styleUrls: ['./user-info-work-data-edit.component.scss']
})
export class UserInfoWorkDataEditComponent {

  configuration: CtModelConfiguration<UserInfoWorkDataEditComponent> | null = null;
  formConfiguration: CtFormConfiguration | null = null;

  private DailyWorkingHoursControl = this.ctControlService.getDefaultConfiguration({
    name: 'DailyWorkingHours',
    label: 'DailyWorkingHours',
    type: CtControlTypes.NUMBER
  })
    .setTheme(CtThemeTypes.MATERIAL)
    .setFlexSpacePercentage(50);

  private WeeklyWorkingHoursControl = this.ctControlService.getDefaultConfiguration({
    name: 'WeeklyWorkingHours',
    label: 'WeeklyWorkingHours',
    type: CtControlTypes.NUMBER
  })
    .setTheme(CtThemeTypes.MATERIAL)
    .setFlexSpacePercentage(50);

  private MondayWorkingHoursControl = this.ctControlService.getDefaultConfiguration({
    name: 'MondayWorkingHours',
    label: 'MondayWorkingHours',
    type: CtControlTypes.NUMBER
  })
    .setTheme(CtThemeTypes.MATERIAL)
    .setFlexSpacePercentage(25);

  private TuesdayWorkingHoursControl = this.ctControlService.getDefaultConfiguration({
    name: 'TuesdayWorkingHours',
    label: 'TuesdayWorkingHours',
    type: CtControlTypes.NUMBER
  })
    .setTheme(CtThemeTypes.MATERIAL)
    .setFlexSpacePercentage(25);

  private WednesdayWorkingHoursControl = this.ctControlService.getDefaultConfiguration({
    name: 'WednesdayWorkingHours',
    label: 'WednesdayWorkingHours',
    type: CtControlTypes.NUMBER
  })
    .setTheme(CtThemeTypes.MATERIAL)
    .setFlexSpacePercentage(25);

  private ThursdayWorkingHoursControl = this.ctControlService.getDefaultConfiguration({
    name: 'ThursdayWorkingHours',
    label: 'ThursdayWorkingHours',
    type: CtControlTypes.NUMBER
  })
    .setTheme(CtThemeTypes.MATERIAL)
    .setFlexSpacePercentage(25);

  private FridayWorkingHoursControl = this.ctControlService.getDefaultConfiguration({
    name: 'FridayWorkingHours',
    label: 'FridayWorkingHours',
    type: CtControlTypes.NUMBER
  })
    .setTheme(CtThemeTypes.MATERIAL)
    .setFlexSpacePercentage(25);

  private SaturdayWorkingHoursControl = this.ctControlService.getDefaultConfiguration({
    name: 'SaturdayWorkingHours',
    label: 'SaturdayWorkingHours',
    type: CtControlTypes.NUMBER
  })
    .setTheme(CtThemeTypes.MATERIAL)
    .setFlexSpacePercentage(25);

  private SundayWorkingHoursControl = this.ctControlService.getDefaultConfiguration({
    name: 'SundayWorkingHours',
    label: 'SundayWorkingHours',
    type: CtControlTypes.NUMBER
  })
    .setTheme(CtThemeTypes.MATERIAL)
    .setFlexSpacePercentage(25);

  private StartDateControl = this.ctControlService.getDefaultConfiguration({
    name: 'StartDate',
    label: 'StartDate',
    type: CtControlTypes.DATE
  })
    .setTheme(CtThemeTypes.MATERIAL)
    .setFlexSpacePercentage(50);

  private EndDateControl = this.ctControlService.getDefaultConfiguration({
    name: 'EndDate',
    label: 'EndDate',
    type: CtControlTypes.DATE
  })
    .setTheme(CtThemeTypes.MATERIAL)
    .setFlexSpacePercentage(50);

  private TypeControl = this.ctControlService.getDefaultConfiguration({
    name: 'Type',
    label: 'Type',
    type: CtControlTypes.ENUMERABLE
  })
    .setTheme(CtThemeTypes.MATERIAL)
    .setFlexSpacePercentage(100)
    .configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        .setNullValueEnabled(false)
    })
    .setValueOptions([
      CtSelectControlValue.create().setLabel('Standard').setValue(1),
      CtSelectControlValue.create().setLabel('Personalizzato').setValue(2)
    ]
    )

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.cancel")
    .setAction(() => this.dialogRef.close())
    .setMatherialOptions(MAT_RAISED_WARN);

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setClass('ct-model-submit-button')
    .setAction(() => this.submit())
    .setMatherialOptions(MAT_RAISED_PRIMARY);


  private controls = [
    this.TypeControl,
    this.DailyWorkingHoursControl,
    this.WeeklyWorkingHoursControl,
    this.MondayWorkingHoursControl,
    this.TuesdayWorkingHoursControl,
    this.WednesdayWorkingHoursControl,
    this.ThursdayWorkingHoursControl,
    this.FridayWorkingHoursControl,
    this.SaturdayWorkingHoursControl,
    this.SundayWorkingHoursControl,
    this.StartDateControl,
    this.EndDateControl
  ]

  constructor(private dialogRef: MatDialogRef<UserInfoWorkDataEditComponent>, private route: ActivatedRoute, private CTGeneralService: CTGeneralService, @Inject(MAT_DIALOG_DATA) public parameter: UserInfoWorkDataEditConfiguration | null, private generalService: CTMGeneralService, private ctControlService: CtControlService, private formBuilder: FormBuilder) {

    this.formConfiguration = CtFormConfiguration
      .create(this.formBuilder.group({}))
      .setTheme(CtThemeTypes.MATERIAL)
      .setControls(this.controls);

  }

  ngAfterViewInit() {


    this.TypeControl
      .control
      ?.valueChanges
      .subscribe(result => {

        if (result == 1) {

          this.StartDateControl.setHidden(true)
          this.EndDateControl.setHidden(true)
          this.StartDateControl.setValue(null)
          this.EndDateControl.setValue(null)
          return

        }

        this.StartDateControl.setHidden(false)
        this.EndDateControl.setHidden(false)

      });

    const pId = this.route.snapshot.paramMap.get("id");
    const id = pId ?? this.parameter?.Oid;

    if (id == null) return;

    this.generalService.getInfos(
      {
        controller: 'UserInfoWorkData',
        oid: +id
      }
    ).subscribe(result => {

      if (result.Result)
        this.setup(result.Result)

    })

  }

  setup(value: any) {

    this.formConfiguration?.form?.patchValue(value.DataSource);

  }



  async submit() {

    this.formConfiguration?.form?.markAllAsTouched();

    if (this.formConfiguration?.form?.valid) {

      const data: any = this.formConfiguration.form.value;
      data.UserInfo = { Oid: this.parameter?.UserOid }

      const model: CtModelConfiguration<any> = CtModelConfiguration.create().setRouteData(UserInfoWorkDataRouteData());

      if (this.parameter?.Oid)
        model.RouteData?.setId(this.parameter.Oid)

      this.generalService.putData(model, data)?.then(() => {
        this.dialogRef.close();
        window.location.reload();
      });

    }

  }

}
