import {Injectable} from '@angular/core';
import {AccessDeniedDialogComponent} from "./access-denied-dialog.component";
import {MatLegacyDialog} from "@angular/material/legacy-dialog";
import {RoleService} from "../../../../../_core/lib/role.service";

@Injectable({
  providedIn: 'root'
})
export class AccessDeniedDialogService {

  constructor(private dialog: MatLegacyDialog, private role: RoleService) {
  }

  async handleAdminAccess() {

    const isAdmin = await this.role.is.administrator();

    if (!isAdmin) {

      this.dialog.open(AccessDeniedDialogComponent, {maxWidth: '500px'});

    }

    return isAdmin; // Restituisce true se l'utente è un amministratore, altrimenti false

  }

}
