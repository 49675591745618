import {Component, Input} from '@angular/core';
import {
  CtBinaryOperator,
  CtModelConfiguration,
  CTModelDatatableFilter, CtModelOnFlyCreateService,
  CtModelRouteData,
  CtModelType
} from "@ctsolution/ct-framework";
import {UserFeeManagerConfiguration} from "./user-fee-manager.configuration";
import {FeeRouteData} from "../../../_core/route-data/fee.route-data";
import {FeeTimeRepetitionAmountType} from "../../../_core/enum/fee-time-repetition-amount-type";

@Component({
  selector: 'app-user-fee-manager',
  template: `
    <div>

      <h3> {{ 'commissions' | translate }} </h3>

      <ct-model *ngIf="modelConfiguration" [configuration]="modelConfiguration"></ct-model>

    </div>`
})
export class UserFeeManagerComponent {

  @Input() configuration: UserFeeManagerConfiguration | null = null;
  modelConfiguration: CtModelConfiguration<UserFeeManagerComponent> | null = null;

  constructor(private onFlyCreateHelper: CtModelOnFlyCreateService) {
  }

  ngAfterViewInit() {

    this.setup();

  }

  async setup() {

    const routeData: CtModelRouteData = FeeRouteData()
      .setModelType(CtModelType.DATATABLE);

    const configuration = CtModelConfiguration
      .create<any>()
      .setRouteData(routeData)
      .setOnCreate(() => this.openFeeForm())
      .setOnEdit((userFee) => this.openFeeForm(userFee.Oid))

    configuration
      .filterValues
      .addConstantFilter(
        CTModelDatatableFilter
          .create()
          .setField('UserInfo.Oid')
          .setValue(this.configuration?.User?.Oid)
          .setOperatorType(CtBinaryOperator.Equal)
      )

    this.modelConfiguration = configuration;

  }

  openFeeForm(Oid: number | null = null) {

    const routeData: CtModelRouteData = FeeRouteData()
      .setModelType(CtModelType.FORM);

    const configuration: CtModelConfiguration<any> | null = CtModelConfiguration.create()

    if (Oid) {

      routeData
        .setId(Oid);

    }

    if (this.configuration?.User) {

      configuration
        .filterValues
        .addConstantFilter(
          CTModelDatatableFilter
            .create()
            .setField('UserInfo')
            .setValue(this.configuration.User)
            .setOperatorType(CtBinaryOperator.Equal)
        );

    }

    configuration
      .setRouteData(routeData);

    configuration
      .initialized()
      .subscribe(() => {

        setTimeout(() => {

          const form = configuration?.form;
          const amountTypeControl = form?.get('AmountType');
          const quantityControl = form?.get('Quantity');

          quantityControl?.disable();

          amountTypeControl
            ?.valueChanges
            .subscribe(value => {

              if (value === FeeTimeRepetitionAmountType.FixedForQuantity) {

                quantityControl?.enable()

              } else {

                quantityControl?.setValue(null);
                quantityControl?.disable();

              }

            })

        })

      })

    this.onFlyCreateHelper
      .openOnFlyCreate(configuration)
      .afterClosed()
      .subscribe((reload: any) => {

        if (reload) window.location.reload(); // dovrei usare la navigazione sul router, però non sta funzionando correttamente ... per ora facciamo refresh forzato

      });


  }

}
