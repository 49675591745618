import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyLeadComponent } from './company-lead.component';
import { LeadListModule } from '../../../lead/lead-list/lead-list.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    LeadListModule,
    TranslateModule
  ],
  declarations: [CompanyLeadComponent],
  exports : [CompanyLeadComponent]
})
export class CompanyLeadModule { }
