import {Component} from '@angular/core';
import {
  CtBinaryOperator,
  CtModelConfiguration,
  CTModelDatatableFilter,
} from "@ctsolution/ct-framework";
import {CTMGeneralService} from "../../../_core/lib/general.service";
import {ActivatedRoute} from "@angular/router";
import {CompanyRouteData} from "../../../_core/route-data/company.route-data";
import {DEFAULT_DATATABLE_CONFIGURATION} from "../../../_core/constants";
import {CompanyService} from "../company.service";
import {CustomerType} from "../../../_core/enum/customer-type";

@Component({
  selector: 'app-company-list',
  template: `
    <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>`,
})
export class CompanyListComponent {

  configuration: CtModelConfiguration<CompanyListComponent> | null = null;

  constructor(
    private general: CTMGeneralService,
    private route: ActivatedRoute,
    private companyService: CompanyService) {

    const configuration = CtModelConfiguration
      .create<CompanyListComponent>()
      .setRouteData(CompanyRouteData())
      .setCTDatatableConfiguration(DEFAULT_DATATABLE_CONFIGURATION());

    const typePathValue: string | null = this.route.snapshot.paramMap.get("type");

    if (!typePathValue) return;

    const isSupplier = this.companyService.getCustomerTypeByRouteValue(typePathValue) === CustomerType.Supplier;

    configuration
      .filterValues
      .addConstantFilter(
        CTModelDatatableFilter
          .create()
          .setField(isSupplier ? 'IsSupplier' : 'IsCustomer')
          .setValue(true)
          .setOperatorType(CtBinaryOperator.Equal)
      )

    if (isSupplier) {

      this
        .route
        .data
        .subscribe(data => {
          data["title"] = "CT_MENU.supplier";
        });

    }

    configuration
      .setOnCreate(() => this.general.navigateOnCTModelRouter(["customer", typePathValue], "create", {qpHandling: 'preserve'}))
      .setOnEdit(event => this.general.navigateOnCTModelRouter(["customer", typePathValue], "edit", {
        value: event,
        configuration: this.configuration!,
        qpHandling: 'preserve'

      }));

    this.configuration = configuration;

  }

}
