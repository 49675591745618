import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";
import {CtFileUploaderModule, CtFrameworkModule} from "@ctsolution/ct-framework";
import {MatIconModule} from "@angular/material/icon";
import {MatLegacyProgressBarModule as MatProgressBarModule} from "@angular/material/legacy-progress-bar";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {FlexLayoutModule} from "@angular/flex-layout";
import {CTManagerImportComponent} from "./ct-manager-import.component";

@NgModule({
  declarations: [
    CTManagerImportComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatListModule,
    CtFileUploaderModule,
    MatIconModule,
    MatProgressBarModule,
    MatButtonModule,
    FlexLayoutModule,
    CtFrameworkModule
  ],
  exports: [
    CTManagerImportComponent
  ]
})
export class CTManagerImportModule {
}
