export class ModuleCreationRequestParameter {

  ItemModuleOid: number | null = null;
  Qty: number | null = null;
  LocationToOid: number | null = null;
  Finalize: boolean | null = null;

  private constructor() {
  }

  public static create = (): ModuleCreationRequestParameter => new ModuleCreationRequestParameter();

  setItemModuleOid(value: number): ModuleCreationRequestParameter {

    this.ItemModuleOid = value;
    return this;

  }

  setQty(value: number): ModuleCreationRequestParameter {

    this.Qty = value;
    return this;

  }

  setLocationToOid(value: number) {

    this.LocationToOid = value;
    return this;

  }

  setFinalize(value: boolean) {

    this.Finalize = value;
    return this;

  }



}
