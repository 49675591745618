export class WorkOrderImportParameter {

  File: File | null = null;
  WorkOrderOid: number | null = null;

  private constructor() {
  }

  public static create = (): WorkOrderImportParameter => new WorkOrderImportParameter();

  setFile(value: File | null): WorkOrderImportParameter {

    this.File = value;
    return this;

  }

  setWorkOrderOid(value: number | null): WorkOrderImportParameter {

    this.WorkOrderOid = value;
    return this;

  }

}
