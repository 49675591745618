<h1 mat-dialog-title>{{ locationName | translate }}</h1>
<div mat-dialog-content class="qr-cntr">
  <img [src]="QRCode">
</div>
<div
  mat-dialog-actions
  fxLayout="row wrap"
  fxLayoutAlign="space-between center">
  <button mat-button mat-dialog-close> {{ "close" | translate }}</button>
  <button mat-button (click)="download()" color="primary" cdkFocusInitial> {{ "download" | translate }}</button>
</div>
