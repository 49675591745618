import {ChangeDetectorRef, Component, TemplateRef, ViewChild} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {WarehouseService} from "../warehouse.service";
import {LangDownloadFileConfiguration} from "../../lang-download-file/lang-download-file.configuration";
import {ReportDownloadParameter} from "../../../_core/classes/report-download.parameter";
import {ReportController} from "../../../_core/controllers/report.controller";

@Component({
  selector: "app-warehouse-detail",
  templateUrl: "./warehouse-detail.component.html",
  styleUrls: ["./warehouse-detail.component.scss"]
})
export class WarehouseDetailComponent {

  @ViewChild("headerActions") headerActions: TemplateRef<any> | null = null;

  pId: string | null = null;
  boxLocation = false;

  langDownloadFileConfiguration: LangDownloadFileConfiguration = LangDownloadFileConfiguration
    .create()
    .setIcon('picture_as_pdf')
    .setColor('accent')
    .setButtonType('mat-mini-fab');

  detailName: string | null = null;

  constructor(
    private route: ActivatedRoute,
    private whService: WarehouseService,
    private cdr: ChangeDetectorRef,
    private reportController: ReportController) {

    this.pId = this.route.snapshot.paramMap.get("id");

    this.boxLocation = window.location.pathname.includes('box');
  }

  ngAfterViewInit() {

    this.route
      .queryParams
      .subscribe(qp => {

        this.detailName = qp['Name'] ?? null;

        this.route
          .data
          .subscribe(data => {

            data['title'] = 'CT_PAGE.BREADCRUMBS.detail';

            if (this.detailName) data['extraString'] = this.detailName;

            data['headerActions'] = this.headerActions;
            data['urls'] = [{title: ''}];

            this.cdr.detectChanges();

          })

      });

  }

  reportQR() {

    if (!this.pId) return;

    this.whService
      .openQRCodeFromLocation({locationOid: +this.pId, locationName: this.detailName ?? 'Location'});

  }

  reportPDF(lang = 'it') {

    if (!this.pId) return;

    const parameter: ReportDownloadParameter = ReportDownloadParameter
      .create()
      .setLocationOid(+this.pId)
      .setTemplateLanguage(lang)
      .setDirectDownload(false)
      .setOpenInBrowser(true);

    this.whService
      .downloadLocationPDF(parameter);

  }

  getPackagingLabelPDF() {

    if (!this.pId) return;

    const param = ReportDownloadParameter.create()
      .setPackagingOid(+this.pId)
      .setAction('PackagingLabelPdf')
      .setDirectDownload(false)
      .setOpenInBrowser(true)
      .setLabelDownload(`packaging${+this.pId}`);

    this.reportController.getAnyPDF(param).subscribe();

  }

}
